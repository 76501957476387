import { createAction, handleActions } from 'redux-actions'
import {combineReducers} from "redux";
import {filters, list} from "../calendar";

//#region Actions
export enum MenuActions {
    setActiveMenu = 'SET_ACTIVE_MENU',
    setIsPrincipalPage = 'SET_IS_PRINCIPAL_PAGE',
    setSubMenu = 'SET_SUB_MENU'
}

export const setActiveMenu = createAction(MenuActions.setActiveMenu)

export const setIsPrincipalPage = createAction(MenuActions.setIsPrincipalPage)

export const setSubMenu = createAction(MenuActions.setSubMenu)

//#endregion

//#region Reducer
const activeMenu = handleActions({
    [MenuActions.setActiveMenu]: (state, action) => action.payload,
}, "doctors")

export const isPrincipalPage = handleActions({
    [MenuActions.setIsPrincipalPage]: (state, action) => action.payload,
}, true)

export const subMenu = handleActions({
    [MenuActions.setSubMenu]: (state, action) => action.payload,
}, '')

export const menuReducers = combineReducers({
    activeMenu,
    isPrincipalPage,
    subMenu
})
//#endregion
