import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from '../Paper'

const PaperStyled = styled(Paper)`
  padding: ${props => (props.blank ? 0 : '24px')};

  & > p {
    opacity: 0.8;
    margin: 0;
  }
`

export const AdditionalInformation = ({
  additional_information,
  changeButtonPath,
}) => (
  <PaperStyled blank={!additional_information} heading='profile.additional' changeButtonPath={changeButtonPath}>
    <p>{additional_information}</p>
  </PaperStyled>
)

AdditionalInformation.defaultProps = {
  changeButtonPath: '',
  additional_information: '',
}

AdditionalInformation.propTypes = {
  additional_information: PropTypes.string,
  changeButtonPath: PropTypes.string,
}
