/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { css } from '@emotion/react'

const styles = css`
  width: calc(100vw - 380px);
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`

export const Preloader = () => (
  <div css={styles}>
    <CircularProgress size={64} />
  </div>
)
