import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { transformGDPR, transformGDPRDeletion } from 'helpers/gdpr'
import { GDPRItemPayload } from 'interfaces/gdpr'

//#region Actions
export enum GdprActions {
  fetchGdprRequest = 'FETCH_GDPR_REQUEST',
  fetchGdprSuccess = 'FETCH_GDPR_SUCCESS',
  fetchGdprFailure = 'FETCH_GDPR_FAILURE',
}

export enum GdprDeletingActions {
  fetchGdprDeletingRequest = 'FETCH_GDPR_DELETING_REQUEST',
  fetchGdprDeletingSuccess = 'FETCH_GDPR_DELETING_SUCCESS',
  fetchGdprDeletingFailure = 'FETCH_GDPR_DELETING_FAILURE',
}

export const fetchGdprRequest = createAction(GdprActions.fetchGdprRequest)
export const fetchGdprSuccess = createAction(GdprActions.fetchGdprSuccess)
export const fetchGdprFailure = createAction(GdprActions.fetchGdprFailure)

export const fetchGdprDeletingRequest = createAction(GdprDeletingActions.fetchGdprDeletingRequest)
export const fetchGdprDeletingSuccess = createAction(GdprDeletingActions.fetchGdprDeletingSuccess)
export const fetchGdprDeletingFailure = createAction(GdprDeletingActions.fetchGdprDeletingFailure)
//#endregion

//#region Reducer
const list = handleActions({
  [GdprActions.fetchGdprSuccess]: (state, action: AnyAction) => action.payload.data,
}, [])

const listDeleting = handleActions({
  [GdprDeletingActions.fetchGdprDeletingSuccess]: (state, action: AnyAction) => action.payload.data,
}, [])

export const gdprReducer = combineReducers({
  list,
  listDeleting,
})
//#endregion

//#region Thunks

export const fetchGdprDeleting = (params?: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchGdprDeletingRequest())

  return axios('/admin/support/gdpr/remove', { params })
    .then(response => {
      dispatch(fetchGdprDeletingSuccess({
        data: response.data.data
          .map((item: GDPRItemPayload) => transformGDPRDeletion(item)),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      dispatch(fetchGdprDeletingFailure(err))
    })
}

export const fetchGdpr = (params?: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchGdprRequest())

  return axios('/admin/support/gdpr/restrict', { params })
    .then(response => {
      dispatch(fetchGdprSuccess({
        data: response.data.data
          .map((item: GDPRItemPayload) => transformGDPR(item)),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      dispatch(fetchGdprFailure(err))
    })
}
//#endregion
