/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { Button } from 'components/common/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { confirmDoctor } from 'ducks/modules/doctors'
import { css } from '@emotion/react'
import { State } from 'interfaces'
import {theme} from "../../../App";
import { DoctorStatuses } from 'constants/doctors'
import {Doctor} from "../../../../interfaces/doctors";
import {isCompleteAdresse} from "../../../../helpers/doctors";

//#region Styles
const wrapperStyles = css`
  position: absolute;
  top: 6px;
  right: 24px;
  
  button {
    margin-left: 12px;
  }
`
//#endregion

interface DoctorButtonsProps {
  doctorStatus?: string,
  id: number,
}

export const DoctorButtons: React.FC<DoctorButtonsProps> = ({ doctorStatus, id }) => {
  const dispatch = useDispatch()
  const loading = useSelector<State, boolean>(state => state.doctors.loading.confirm)
  const doctor : Doctor = useSelector<State, any>(state => state.doctors.profiles[id])
  const idValidateur = useSelector(store => store.connected.idUser);

  const onConfirm = () => dispatch(confirmDoctor(true, id,idValidateur))
  const onRefuse = () => dispatch(confirmDoctor(false, id,null))

  function displayButton(doctorStatus?: string): React.ReactNode {
    switch (doctorStatus) {
      case (DoctorStatuses.verified):
        return (
          <Button isFetching={loading} onClick={onRefuse} color='error' >
            <FormattedMessage id='doctors.refuse' />
          </Button>
        )
      case (DoctorStatuses.notVerified):
        return (
          <Button isFetching={loading} onClick={onConfirm} color='success' disabled={disabledButton()} >
            <FormattedMessage id='doctors.confirm' />
          </Button>
        )
      case (DoctorStatuses.preRegistered):
        return null
      default:
        return (
            <Button isFetching={loading} onClick={onConfirm} color='success' disabled={disabledButton()}>
              <FormattedMessage id='doctors.confirm' />
            </Button>
        )
    }
  }

  function disabledButton(): boolean{
    return !doctor?.attributes?.first_name || !doctor?.attributes?.last_name || !(doctor?.attributes?.credentials?.adeli ||
        doctor?.attributes?.credentials?.rpps || doctor?.attributes?.credentials?.orderNumber) ||
        !isCompleteAdresse(doctor?.relationships?.workPlace?.data[0]?.attributes);
  }

  return (

    <div css={wrapperStyles}>
      <ThemeProvider theme={theme}>
        {displayButton(doctorStatus)}
      </ThemeProvider>
    </div>

  )
}