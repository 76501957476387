export const removeFilter = (filters: { [key: string]: any }, key: string) => {
  const newFilters = { ...filters }

  if (newFilters[key]) {
    delete newFilters[key]
  }

  return newFilters
}

export const setFilter = (
  filters: { [key: string]: any },
  key: string,
  value?: string | number,
) => {
  if (!value) {
    return removeFilter(filters, key)
  }

  return {
    ...filters,
    [key]: value,
  }
}
