/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  mapProps,
  withStateHandlers,
  withProps,
} from 'recompose'
import styled from 'styled-components'
import { Colors } from '../../../../constants/colors.ts'
import { DialogWindow } from './DialogWindow'
import { Paper } from '../Paper'
import { Photo } from './Photo'
import { Upload } from './Upload'
import { FilenameForm } from './FilenameForm'

const Wrapper = styled.div`
  width: 158px;
  height: ${({ disableEdit }) => (disableEdit ? '158px' : '194px')};
  border: 1px solid ${Colors.grey};
  border-radius: 4px;
  margin: 8px calc((100% / 4 - 160px) / 2 );
`

const PaperStyled = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  padding: 12px 8px;
  ${({ disableEdit }) => disableEdit && 'cursor: pointer;'}
`

const DiplomasComponent = ({
  images,
  onDialogClose,
  formatError,
  sizeError,
  disableShadow,
  disableEdit,
  onPaperClick,
  onDrop,
  acceptedExtensions,
  droppedFile,
  onFilenameFormClose,
  onFilenameFormSubmit,
  withFilenames,
  onEditClick,
}) => (
  <PaperStyled disableEdit={disableEdit} onClick={onPaperClick} disableShadow={disableShadow}>
    {
      images.map(({
        image, deleteDiploma, extension, name, 
      }) => {
        if (!image) return null
        return (
          <Wrapper disableEdit={disableEdit} key={image}>
            <Photo
              file={typeof image === 'string' ? image : URL.createObjectURL(image)}
              onDelete={() => deleteDiploma()}
              disableEdit={disableEdit}
              extension={extension}
              withFilenames={withFilenames}
              name={name}
              onEditClick={onEditClick}
            />
          </Wrapper>
        )
      })
    }
    {!disableEdit && (
      <Wrapper>
        <Upload acceptedExtensions={acceptedExtensions} onDrop={onDrop} />
      </Wrapper>
    )}
    <DialogWindow
      formatError={formatError}
      sizeError={sizeError}
      onDialogClose={onDialogClose}
    />
    {
      !!droppedFile && (
        <FilenameForm 
          file={droppedFile}
          onSubmit={onFilenameFormSubmit}
          onClose={onFilenameFormClose}
        />
      )
    }
  </PaperStyled>
)

const enhance = compose(
  mapProps(props => ({
    ...props,
    images: props.diplomas.map(item => ({
      image: item.file,
      extension: item.extension,
      deleteDiploma: () => props.deleteDiploma(item.id),
      name: item.custom_name,
    })),
  })),
  withStateHandlers({
    formatError: false,
    sizeError: false,
    droppedFile: null,
  }, {
    onDrop: (state, { createDiplomas, withFilenames }) => (acceptedFiles, rejectedFiles) => {
      const files = acceptedFiles.filter(file => file.size <= 10485760)
      const errors = {
        formatError: !!rejectedFiles.length,
        sizeError: !rejectedFiles.length && acceptedFiles.length !== files.length,
      }
      
      if (!withFilenames && !errors.formatError && !errors.sizeError) {
        createDiplomas({ file: files })
        return errors
      }
      
      return {
        ...errors,
        droppedFile: (!errors.formatError && !errors.sizeError && !!files.length) ? files[0] : null,
      }
    },
    onDialogClose: () => () => ({ formatError: false, sizeError: false }),
    onFilenameFormClose: () => () => ({ droppedFile: null }),
    onEditClick: () => file => ({ droppedFile: file }),
  }),
  withProps(props => ({
    onFilenameFormSubmit: values => props.createDiplomas({
      ...values,
      file: [props.droppedFile],
    }).then(() => props.onFilenameFormClose()),
  })),
)

DiplomasComponent.defaultProps = {
  onPaperClick: () => null,
}

DiplomasComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  formatError: PropTypes.bool.isRequired,
  sizeError: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  disableShadow: PropTypes.bool.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  onPaperClick: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  //acceptedExtensions: PropTypes.string,
  //droppedFile: PropTypes.object,
  onFilenameFormClose: PropTypes.func.isRequired,
  onFilenameFormSubmit: PropTypes.func.isRequired,
  withFilenames: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
}

export const Diplomas = enhance(DiplomasComponent)
/* eslint-enable react/destructuring-assignment */
