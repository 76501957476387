import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../../../constants/colors.ts'

const Wrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  ${props => (props.disableShadow ? '' : 'box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);')}
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: ${Colors.black};
`

const Content = styled.div`
  padding: 32px 24px 24px;
`

const Heading = styled.header`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 24px;
  font-weight: 500;
  color: ${Colors.black};
  border-bottom: 1px solid ${Colors.cloudyBlue};

  button {
    font-weight: 500;
    font-size: 16px;
    margin-right: -16px;
  }
`

const HeadingTransparent = styled.header`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0 24px 10px;
  font-weight: 500;
  color: ${Colors.black};
  opacity: 0.9;
`

const Warning = styled.h1`
  margin: 0;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.yellowOchre};
  background-color: ${Colors.offWhite};
  border-radius: 4px;
`

const AddButton = styled(Button)`
  && {
    font-size: 14px;
    text-transform: uppercase;
    margin-right: -20px;
  }
`

const ChangeButton = styled(IconButton)`
  && {
    margin-right: -18px;
  }

  && svg {
    font-size: 18px;
  }
`

const Optional = styled.span`
  opacity: 0.6;
`

export const Paper = ({
  children,
  heading,
  headingTransparent,
  optional,
  warning,
  changeButtonPath,
  addButtonPath,
  disableShadow,
  onDeleteButtonClick,
  onCheckCompabilityButtonClick,
  additionalButton,
  setUserWantingToModifyPersonalData,
  setUserWantingToModifyAboutDoctorData,
  isPersonalData,
  isDoctorAbout,
  ...rest
}) => (
  <div>
    {headingTransparent && (
      <HeadingTransparent>
        <FormattedMessage id={headingTransparent} defaultMessage={headingTransparent} />
      </HeadingTransparent>
    )}
    <Wrapper disableShadow={disableShadow}>
      {warning
        && <Warning>{warning}</Warning>}
      {heading
        && (
          <>
            <Heading>
              <div>
                <FormattedMessage id={heading} defaultMessage={""} />
                &nbsp;
                {optional && <Optional><FormattedMessage id='global.optional' /></Optional>}
              </div>
              {
                changeButtonPath
                  && (
                    <Link to={changeButtonPath}>
                      <ChangeButton color='primary'>
                        <Create fontSize='inherit' />
                      </ChangeButton>
                    </Link>
                  )
              }
              {
                addButtonPath
                  && (
                    <Link to={addButtonPath}>
                      <AddButton color='primary'>
                        <FormattedMessage id='global.add' />
                      </AddButton>
                    </Link>
                  )
              }
              {
                onDeleteButtonClick
                  && (
                    <ChangeButton color='primary' onClick={onDeleteButtonClick}>
                      <Delete fontSize='inherit' />
                    </ChangeButton>
                  )
              }
              {
                onCheckCompabilityButtonClick
                  && (
                    <Button color='primary' onClick={onCheckCompabilityButtonClick}>
                      <FormattedMessage id='global.check.compatibility' />
                    </Button>
                  )
              }
              {
                (!!isPersonalData && isPersonalData)
                && (
                  <Button color='primary' onClick={() => setUserWantingToModifyPersonalData(true)}>
                    <FormattedMessage id='doctors.manage' />
                  </Button>
                )
              }
              {
                (!!isDoctorAbout && isDoctorAbout)
                && (
                  <Button color='primary' onClick={() => setUserWantingToModifyAboutDoctorData(true)}>
                    <FormattedMessage id='doctors.manage' />
                  </Button>
                )
              }
              {additionalButton || null}
            </Heading>
          </>
        )}
      <Content {...rest}>
        {children}
      </Content>
    </Wrapper>
  </div>
)

Paper.defaultProps = {
  heading: '',
  headingTransparent: '',
  optional: false,
  warning: '',
  changeButtonPath: '',
  addButtonPath: '',
  disableShadow: false,
  onDeleteButtonClick: false,
  onCheckCompabilityButtonClick: false,
}

Paper.propTypes = {
  heading: PropTypes.any,
  headingTransparent: PropTypes.string,
  optional: PropTypes.bool,
  disableShadow: PropTypes.bool,
  warning: PropTypes.string,
  changeButtonPath: PropTypes.string,
  addButtonPath: PropTypes.string,
  onDeleteButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onCheckCompabilityButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}
