export const azure = 'rgba(6, 173, 255, 0.1)';
export const blueGrey = '#949eb8';
export const clearBlue = '#209dff';
export const cloudyBlue = 'rgba(191, 197, 212)';
export const cloudyBlue30 = 'rgba(191, 197, 212, 0.3)';
export const dark = '#21293e';
export const grey = '#e2e5ed';
export const paleGrey = 'rgba(245, 247, 251)';
export const paleGrey60 = 'rgba(245, 247, 251, 0.6)';
export const red = '#ee3c5b';
export const waterBlue = 'rgba(12, 112, 209, 0.5)';

export enum Colors {
  blue = '#0097ff',
  green = '#21c5a9',
  red = '#c54950',
  white = '#ffffff',
  blueDark = '#244c83',
  menu = 'rgb(39,83,142)',
  menuHover = '#244c83',
  menuDisabled = '#949eb8',
  menuActive = '#22436c',
  background = '#f5f7fb',
  grey = '#949eb8',
  azure = 'rgba(6, 173, 255, 0.1)',
  blueGrey = '#949eb8',
  clearBlue = '#209dff',
  cloudyBlue = 'rgba(191, 197, 212)',
  cloudyBlue30 = 'rgba(191, 197, 212, 0.3)',
  dark = '#21293e',
  dark60 = 'rgba(33, 41, 62, 0.6)',
  dark70 = 'rgba(33, 41, 62, 0.7)',
  dark80 = 'rgba(33, 41, 62, 0.8)',
  duskBlue = '#264f82',
  paleGrey = 'rgba(245, 247, 251)',
  paleGrey60 = 'rgba(245, 247, 251, 0.6)',
  waterBlue = 'rgba(12, 112, 209, 0.5)',
  white15 = 'rgba(255, 255, 255, 0.15)',
  white10 = 'rgba(255, 255, 255, 0.1)',
  black = '#000000',
  black10 = 'rgba(0, 0, 0, 0.1)',
  blackBg = 'rgba(0, 12, 29, 0.4)',
  link = '#018cff',
  navyBlue = '#2c578c',
  lightGrey = '#f3f6f9',
  textMuted = '#9ba3bb',
  disabled = '#b7bed2',
  disabledText = '#bfc5d4',
  divider = '#e2e5ed',
  steel = '#757f99',
  yellowOchre = '#d0a403',
  offWhite = '#fff9e6',
  tealish = '#21c5a9',
  seafomBlue = '#5bdac4',
  watermelon = '#fa4464',
  strawberry = '#f1353a',
  redDelete = '#E83E25',
  greenValidate = '#2AB527',
  greenAvailable = '#00B428',
  darkGreen = '#1b5e20',
  redUnavailable = '#FE4967',
  greyNotChecked = '#C4C4C4',
}
/* eslint-disable no-shadow */
