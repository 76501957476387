import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '@material-ui/core/Select';
import axios from 'axios';
import get from 'lodash/get';
import MenuItem from '@material-ui/core/MenuItem';
import { Paper } from '../Paper'
import {useSelector} from "react-redux";
import {fetchSpecialization} from "../../../../ducks/modules/specialization";


const PaperStyled = styled(Paper)`
  padding: 10px 24px;
  
  a {
    font-weight: 400;
  }
`

const Line = styled.div`
  padding: 8.5px 0;
  display: flex;
  // opacity: 0.87;
`

const LanguagesLine = styled(Line)`
  text-transform: capitalize;
`

const Name = styled.span`
  font-weight: bold;
  // width: 172px;
  margin-right: 8px;
`

const StyledSelect = styled(Select)`
  width: 200px;
`

const normalizeLanguage = languages => languages.map((l) => {
  if (l === 'ru') return 'Russian'
  if (l === 'de') return 'German'
  if (l === 'fr') return 'Français'
  if (l === 'es') return 'Español'
  if (l === 'ar') return 'Arabe'
  return 'English'
})

export const AboutDoctor = ({
  work_place,
  changeButtonPath,
  credentials,
  specialization_id,
  setUserWantingToModifyAboutDoctorData,
  gender,
  structures,
    teams
}) => {
  const [specializationName, setSpecializationName] = useState([]);

  useEffect(() => {
    axios('/general/specialization')
      .then(result => {
        setSpecializationName(result.data.data.filter(el => el.id == specialization_id)[0].attributes.name)
      })
      .catch(err => console.warn(err))
  }, [])


  return (
    <PaperStyled
      heading={'profile.about'}
      isDoctorAbout={true}
      setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData}
      changeButtonPath={changeButtonPath}
    >
      <Line style={{ alignItems: 'center' }}>
        <Name>
          <FormattedMessage id='profile.specialization' /> {":"}
        </Name>
           {specializationName}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.title' /> {":"}
        </Name>
        <FormattedMessage id={gender === 'unknown' ? 'hr.unknown' : `profile.${gender}`} />
      </Line>
      {
          credentials && (
              <>
                <Line>
                  <Name>
                    <FormattedMessage id='profile.rpps' /> {":"}
                  </Name>
                  {
                    (credentials.rpps !== null && credentials.rpps !== '') ? (
                            credentials.rpps
                    ) :
                    (
                        'Non renseigné'
                    )
                  }
                </Line>
                <Line>
                  <Name>
                    <FormattedMessage id='profile.adeli' /> {":"}
                  </Name>
                  {
                    (credentials.adeli !== null && credentials.adeli !== '')  ? (
                        credentials.adeli
                    ) :
                    (
                        'Non renseigné'
                    )
                  }
                </Line>
                <Line>
                  <Name>
                    <FormattedMessage id='profile.finess' /> {":"}
                  </Name>
                  {
                    (credentials.orderNumber !== null && credentials.orderNumber !== '') ? (
                        credentials.orderNumber
                    ) :
                    (
                        'Non renseigné'
                    )

                  }
                </Line>
              </>
          )
      }
      <Line>
        <Name>
          <FormattedMessage id='profile.workplace' /> {":"}
        </Name>
        {idx(work_place, _ => _.name)}

        ,&nbsp;
        {idx(work_place, _ => _.street)}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.postcode' /> {":"}
        </Name>
        {idx(work_place, _ => _.postcode)}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.city' /> {":"}
        </Name>
        {idx(work_place, _ => _.city)}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.country' /> {":"}
        </Name>
        {idx(work_place, _ => _.country)}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.structures' /> {":"}
        </Name>
        {structures.map( (structure, index) =>
          <span>
            {index !== 0 && "; "}
            <a href={'/structure/' + structure.id}> {structure.attributes.name} </a>
          </span>)}
      </Line>
      <Line>
        <Name>
          <FormattedMessage id='profile.teams' /> {":"}
        </Name>
        {teams.map( (team, index) =>
            <span>
            {index !== 0 && "; "}
              <a href={'/team/' + team.id}> {team.attributes.name} </a>
          </span>)}
      </Line>
    </PaperStyled>
  )
}

AboutDoctor.defaultProps = {
  changeButtonPath: '',
  country: null,
  team_name: null,
  //medical_degree: [],

  //credentials: null,
}

AboutDoctor.propTypes = {
  //medical_degree: PropTypes.arrayOf(PropTypes.string),
  experience: PropTypes.number.isRequired,
  country: PropTypes.string,
  team_name: PropTypes.string,
  addition_email: PropTypes.any.isRequired,
  language: PropTypes.arrayOf(PropTypes.string).isRequired,
  //work_place: PropTypes.object.isRequired,
  changeButtonPath: PropTypes.string,
  //credentials: PropTypes.object,
}
