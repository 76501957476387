import axios from 'axios';
import { stringify } from 'querystring';
import {Structure, StructurePayload, StructureRow} from "../interfaces/structures";
import {Doctor, DoctorPayload} from "../interfaces/doctors";

export const getListStructures = (filter: any) => axios.get(`/admin/doctor/structure/list?all=true&${stringify(filter)}`)

export const getStructureRow : (data: Structure) => StructureRow = (
    {
        type,
        id,
        attributes,
    }
) => {
    return {
        id: id,
        name : attributes.name,
        type: attributes.type.type,
        finess: attributes.finessGeographic || attributes.finessJuridic,
        members: attributes.members,
    }
}


export const normalizeStructure: (structure: StructurePayload) => Structure = structure => ({
    id: structure.id,
    address : structure.attributes.address,
    city : structure.attributes.city,
    description : structure.attributes.description,
    email : structure.attributes.email,
    finessGeographic : structure.attributes.finessGeographic,
    finessJuridic : structure.attributes.finessJuridic,
    isActive: structure.attributes.isActive,
    isPhysical : structure.attributes.isPhysical,
    members : structure.attributes.members,
    name : structure.attributes.name,
    phone : structure.attributes.phone,
    siren : structure.attributes.siren,
    typeId : structure.attributes.type.id,
    type : structure.attributes.type.type,
    zipcode : structure.attributes.zipcode
})