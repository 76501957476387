/** @jsxImportSource @emotion/react */
// import { jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {Link, useHistory} from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Search } from 'components/common/Search'
import { Content } from 'components/common/Content'
import { Table } from 'components/common/Table'
import { STRUCTURES_TABLE_HEADERS } from 'constants/tables'
import { Button } from 'components/common/Button'
import {getListStructures, getStructureRow} from 'helpers/structures';
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {useDispatch} from "react-redux";
import {Structure, StructureFilters} from "../../../interfaces/structures";
// import EditIcon from '@mui/icons-material/Edit';
// import { css } from '@emotion/react'
import {Colors} from "../../../constants/colors";
import {TypeOfStructure} from "./selectStructureType";
import GroupIcon from '@mui/icons-material/Group';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {Routes} from "../../../constants/routes";

//#region Styles
const HeaderStructures = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledButtonMembers = styled(Button)`
  background-color: ${Colors.darkGreen};
  color: ${Colors.white};
  border-radius: 50px;
`

const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
`

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b5e20'
        }
    }
});
//#endregion

export const StructuresList = () => {
  const [filters, setFilter] = useState<StructureFilters>({ search: '', limit: 10, page: 1,alluser:'true' })
  const [structures, setStructures] = useState<Structure[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1, total: 0, limit: 10, totalPage: 0, 
  });
  const dispatch = useDispatch()
  const history = useHistory();
  useEffect(() => {
    dispatch(setActiveMenu('global.menu.structures'))
    dispatch(setIsPrincipalPage(true))
  }, )

    useEffect(() => {
    }, [structures])

  useEffect(() => {
    getListStructures(filters)
      .then(resp => {
        setStructures(resp.data.data)
        setPagination(resp.data.paginator)
      })
      .catch(err => console.warn(err))
  }, [filters])

  const onSearch = (search: string) => dispatch(setFilter({ ...filters, search }))
  const onPageChange = (page: number) => {
    setFilter({ ...filters, page })
    setPagination({ ...pagination, currentPage: page })
  }
  const onPerPageChange = (limit: number) => setFilter({ ...filters, limit, page: 1 })

  const changeFilter = (filterValue: StructureFilters) => {
    if (filterValue.page) {
        setFilter({ ...filters, ...filterValue });
    } else {
        setFilter({ ...filters, ...filterValue, page: 1 });
    }
  }

  return (
    <Content>
      <HeaderStructures>
        <Search
          onSearch={onSearch}
          label='structures.searchplaceholder'
        />

        <Link to="/structures/new">
          <StyledButton color="primary" type="submit">
            <FormattedMessage id="structures.create" />
          </StyledButton>
        </Link>
      </HeaderStructures>
      <TypeOfStructure changeFilter={changeFilter} />
      <Table
        headers={STRUCTURES_TABLE_HEADERS}
        name='structures'
        data={structures
          .filter(el => !filters || !filters.name || el.attributes.name === filters.name)
          .map(el => ({
            ...getStructureRow(el)
          }))}
        pagination={pagination}
        onRowClick={(structure) => history.push(Routes.structure + '/' + structure.id) }
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </Content>
  )
}
