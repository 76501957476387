/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Content } from 'components/common/Content'
import { Search } from 'components/common/Search'
import { Filter } from 'components/common/Filter'
import { RATING_FILTERS } from 'constants/doctors'
import { Table } from 'components/common/Table'
import { CONSULTATION_TABLE_HEADERS } from 'constants/tables'
import { Pagination, State } from 'interfaces'
import { ConsultationFilters, ConsultationRow } from 'interfaces/doctors'
import { setFilter } from 'helpers/filters'
import { css } from '@emotion/react'
import { fetchConsultations } from 'ducks/modules/consultations'
import { consultationsSelector } from 'selectors/doctor'
import {fetchAllConsultations} from "../../../../ducks/modules/AllConsultations";
import Button from "@mui/material/Button";
import {Colors} from "../../../../constants/colors";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {FormattedMessage} from "react-intl";

//#region Styles
const filterStyles = css`
  flex-grow: 1;
  text-align: right;
`

const headerStyles = css`
  display: flex;
`
//#endregion

interface ConsultationsProps {
  id: number | string,
}

export const Consultations: React.FC<ConsultationsProps> = ({ id }) => {
  const [filters, setFilters] = useState<ConsultationFilters>({ page: 1, limit: 10, doctor_id: id })
  const dispatch = useDispatch()
  const consultations = useSelector<State, ConsultationRow[]>(consultationsSelector(id))
  const pagination = useSelector<State, Pagination>(state => state.pagination.consultations)
  useEffect(() => {
    dispatch(fetchAllConsultations(filters))
  }, [dispatch, id, filters])
  const onRatingSelect = (rating?: number | string) => setFilters(
    setFilter(filters, 'rating', rating),
  )

  const onSearch = (name?: string) => setFilters(
    setFilter(filters, 'patient_name', name),
  )

  const urlGrafana = 'https://grafana.conex.cloud/d/f11e16f3-ee31-4902-b614-bbf975a44d5f/kpi-prod-utilisateur-ps?orgId=1&from=now-5y&to=now&var-doctor_id=' + id;

  return (
    <Content>
      <div css={headerStyles}>
        <Search
          onSearch={onSearch}
          label='doctors.consultations.search'
        />
        <div css={filterStyles}>
          <Button style={{backgroundColor: Colors.white, color: Colors.clearBlue, border: '2px solid', borderColor: Colors.clearBlue, height: '38px', width: '150px', marginLeft: 'auto'}} onClick={() => { window.open(urlGrafana, "_blank") }}>
            <QueryStatsIcon style={{marginRight: '10px'}}/>
            <FormattedMessage id="global.dashboard.button" />
          </Button>
          <Filter
            options={RATING_FILTERS}
            onSelect={onRatingSelect}
            label='doctors.rating'
            value={filters.rating || null}
          />
        </div>
      </div>
      <Table
        headers={CONSULTATION_TABLE_HEADERS}
        name='consultations'
        data={consultations}
        pagination={pagination}
        onPageChange={page => setFilters(setFilter(filters, 'page', page))}
        onPerPageChange={limit => setFilters(setFilter(filters, 'limit', limit))}
      />
    </Content>
  )
}
