import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import axios from 'axios'
import { Pagination, ThunkResult } from 'interfaces';
import { SkillItem } from 'interfaces/skills';

//#region Actions
export enum SkillsActions {
  fetchSkillsRequest = 'SKILLS_LIST_REQUEST',
  fetchSkillsSuccess = 'SKILLS_LIST_SUCCESS',
  fetchSkillsFailure = 'SKILLS_LIST_FAILURE',
}

export const fetchSkillsRequest = createAction(SkillsActions.fetchSkillsRequest)
export const fetchSkillsSuccess = createAction(SkillsActions.fetchSkillsSuccess)
export const fetchSkillsFailure = createAction(SkillsActions.fetchSkillsFailure)
//#endregion

//#region Reducer
export const list = handleActions({
  [SkillsActions.fetchSkillsSuccess]: (state, action: AnyAction) => action.payload.skills,
}, [])

export const skillsReducer = combineReducers({
  list,
})
//#endregion

//#region Thunks
export const fetchSkills = ({ page, name, limit }: {page: number, name: string, limit: number})
: ThunkResult<Promise<void | Pagination>> => (
  async (dispatch) => {
    dispatch(fetchSkillsRequest())
    try {
      const { data } = await axios(`/admin/doctor/team/skill/list?page=${page}&limit=${limit}&search=${name}`)
      const skills: SkillItem[] = data.data;
      const pagination: Pagination = data.paginator;
      dispatch(fetchSkillsSuccess({
        skills,
        pagination,
      }))
    } catch (err) {
      dispatch(fetchSkillsFailure(err))
    }

    return undefined
  })
//#endregion
