import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@material-ui/core/Select';
import { FormattedMessage } from 'react-intl';
import {StructureFilters} from "../../../interfaces/structures";

export const TypeOfStructure = ({changeFilter}) => {
    const [type, setType] = useState('undefined');
    const handleChange = (event) => {
        changeFilter({ type: event.target.value });
        setType(event.target.value);
    };

    return (
            <FormControl>
                <StyledSelectInput
                    displayEmpty
                    id='structures.type.search'
                    value={type}
                    size={'large'}
                    onChange={handleChange}
                >
                    <MenuItem value='undefined' disabled>
                        <FormattedMessage id='structures.type.search' />
                    </MenuItem>
                    <MenuItem value='Toutes les structures'><FormattedMessage id='structures.type.all' /></MenuItem>
                    <MenuItem value='Cabinet Médical'><FormattedMessage id='structures.type.cabinet_medical' /></MenuItem>
                    <MenuItem value='MSP'><FormattedMessage id='structures.type.msp' /></MenuItem>
                    <MenuItem value='CH/CHU'><FormattedMessage id='structures.type.ch_chu' /></MenuItem>
                    <MenuItem value='ESPIC'><FormattedMessage id='structures.type.espic' /></MenuItem>
                    <MenuItem value='EHPAD'><FormattedMessage id='structures.type.ehpad' /></MenuItem>
                    <MenuItem value='Clinique'><FormattedMessage id='structures.type.clinique' /></MenuItem>
                    <MenuItem value='CPTS'><FormattedMessage id='structures.type.cpts' /></MenuItem>
                    <MenuItem value='GHT'><FormattedMessage id='structures.type.ght' /></MenuItem>
                    <MenuItem value='Laboratoire de biologie médicale'><FormattedMessage id='structures.type.ldbm' /></MenuItem>
                    <MenuItem value='Laboratoire d`anatomie et cytologie pathologique'><FormattedMessage id='structures.type.ldecp' /></MenuItem>
                    <MenuItem value='CHU'><FormattedMessage id='structures.type.CHU' /></MenuItem>
                    <MenuItem value='CHR'><FormattedMessage id='structures.type.CHR' /></MenuItem>
                    <MenuItem value='CH'><FormattedMessage id='structures.type.CH' /></MenuItem>
                    <MenuItem value='Centre de soins de SSR'><FormattedMessage id='structures.type.CDSDSSR' /></MenuItem>
                    <MenuItem value='Clinique psychiatrique'><FormattedMessage id='structures.type.CP' /></MenuItem>
                    <MenuItem value='Centre de lutte contre le cancer'><FormattedMessage id='structures.type.CDLCLC' /></MenuItem>
                    <MenuItem value='Pharmacie d`officine'><FormattedMessage id='structures.type.PD' /></MenuItem>
                    <MenuItem value='Cabinet de kinésithérapie'><FormattedMessage id='structures.type.CDK' /></MenuItem>
                    <MenuItem value='Cabinet infirmier'><FormattedMessage id='structures.type.CI' /></MenuItem>

                </StyledSelectInput>
            </FormControl>
    );
}

const StyledSelectInput = styled(Select)`
  margin-top: 20px;

  &>div {
    padding: 5px 10px !important;
    padding-right: 24px !important;
  }
`
