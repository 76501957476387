import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { Pagination, ThunkResult } from 'interfaces'
import { AnyAction, combineReducers } from 'redux'
import {MenuActions} from "../menu";
import {StructureFilters, StructurePayload} from "../../../interfaces/structures";
import {normalizeStructure} from "../../../helpers/structures";
import {StructureMembersFilters, StructureMembersPayload} from "../../../interfaces/structuresmembers";
import {normalizeStructureMembers} from "../../../helpers/structureMembers";

//#region Actions
export enum StructuresActions {
    fetchStructuresRequest = 'FETCH_STRUCTURES_REQUEST',
    fetchStructuresSuccess = 'FETCH_STRUCTURES_SUCCESS',
    fetchStructuresFailure = 'FETCH_STRUCTURES_FAILURE',

    setStructuresFilters = 'SET_STRUCTURE_FILTER',

}

export const fetchStructuresRequest = createAction(StructuresActions.fetchStructuresRequest)
export const fetchStructuresSuccess = createAction(StructuresActions.fetchStructuresSuccess)
export const fetchStructuresFailure = createAction(StructuresActions.fetchStructuresFailure)


export const setStructuresFilters = createAction(StructuresActions.setStructuresFilters)

//#endregion

//#region Reducer
const list = handleActions({
    [StructuresActions.fetchStructuresSuccess]: (state, action: AnyAction) => action.payload.structures,
}, [])


const filters = handleActions({
    [StructuresActions.setStructuresFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "name", sortOrder: "DESC",all:"true" })


export const structuresReducer = combineReducers({
    list,
    filters,
})
//#endregion

//#region Thunks

export const fetchStructures = (
    params: StructureFilters,
): ThunkResult<Promise<void | Pagination>> => (
    dispatch,
) => {
    dispatch(fetchStructuresRequest())

    type Response = { paginator: Pagination, data: StructurePayload[] }

    return axios.request<Response>({
        url: 'admin/doctor/structure/list',
        params: {
            ...params,
            language: ['en', 'fr', 'ru', 'de'],
        },
    })
        .then(response => {
            const structures: StructurePayload[] = response.data.data
            dispatch(fetchStructuresSuccess({
                structures: structures.map(structure => normalizeStructure(structure)),
                pagination: response.data.paginator,
            }))
        })
        .catch((err) => {
            dispatch(fetchStructuresFailure(err))
        })
}
//#endregion
