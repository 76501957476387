/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { css } from '@emotion/react'
import Container from '@material-ui/core/Container'

import { Theme } from 'utils/styled'
import { Logo } from 'components/common/Logo'
import {theme} from "../../../App";

const wrapperStyles = (theme: Theme) => css`
  background-color: ${theme.palette.grey[100]};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`

const containerStyles = css`
  && {
    display: flex;
    flex-grow: 1;
    flex-flow: column nowrap;
  }
`

const barStyles = (theme: Theme) => css`
  padding: 16px 0;
  background-color: ${theme.palette.primary.main};
`

const contentStyles = css`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 88px;
`

export const AuthLayout: React.FC = ({ children }) => (
  <div css={wrapperStyles(theme)}>
    <div css={barStyles(theme)}>
      <Container>
        <Logo />
      </Container>
    </div>
    <Container css={containerStyles}>
      <div css={contentStyles}>
        {children}
      </div>
    </Container>
  </div>
)
