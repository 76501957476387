import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { Doctor, DoctorPayload, DoctorsFilters } from 'interfaces/doctors'
import { normalizeDoctor } from 'helpers/doctors'
import { Pagination, ThunkResult } from 'interfaces'
import { AnyAction, combineReducers } from 'redux'
import {MenuActions} from "../menu";
import {StructuresMembersActions} from "../structuresMembers";

//#region Actions
export enum DoctorsActions {
  fetchDoctorsRequest = 'FETCH_DOCTORS_REQUEST',
  fetchDoctorsSuccess = 'FETCH_DOCTORS_SUCCESS',
  fetchDoctorsFailure = 'FETCH_DOCTORS_FAILURE',

  fetchDoctorRequest = 'FETCH_DOCTOR_REQUEST',
  fetchDoctorSuccess = 'FETCH_DOCTOR_SUCCESS',
  fetchDoctorFailure = 'FETCH_DOCTOR_FAILURE',

  fetchDoctorProfileRequest = 'FETCH_DOCTOR_PROFILE_REQUEST',
  fetchDoctorProfileSuccess = 'FETCH_DOCTOR_PROFILE_SUCCESS',
  fetchDoctorProfileFailure = 'FETCH_DOCTOR_PROFILE_FAILURE',

  confirmDoctorRequest = 'CONFIRM_DOCTOR_REQUEST',
  confirmDoctorSuccess = 'CONFIRM_DOCTOR_SUCCESS',
  confirmDoctorFailure = 'CONFIRM_DOCTOR_FAILURE',

  setDoctorsFilters = 'SET_DOCTOR_FILTER',
  setDoctorsFiltersGeoloc = "SET_DOCTOR_FILTER_GEOLOC",

  fetchStructuresMembersSuccess = 'FETCH_STzzzRUCTURES_MEMBERS_SUCCESS',

}

export const fetchDoctorsRequest = createAction(DoctorsActions.fetchDoctorsRequest)
export const fetchDoctorsSuccess = createAction(DoctorsActions.fetchDoctorsSuccess)
export const fetchDoctorsFailure = createAction(DoctorsActions.fetchDoctorsFailure)

export const fetchDoctorRequest = createAction(DoctorsActions.fetchDoctorRequest)
export const fetchDoctorSuccess = createAction(DoctorsActions.fetchDoctorSuccess)
export const fetchDoctorFailure = createAction(DoctorsActions.fetchDoctorFailure)

export const fetchDoctorProfileRequest = createAction(DoctorsActions.fetchDoctorProfileRequest)
export const fetchDoctorProfileSuccess = createAction(DoctorsActions.fetchDoctorProfileSuccess)
export const fetchDoctorProfileFailure = createAction(DoctorsActions.fetchDoctorProfileFailure)

export const confirmDoctorRequest = createAction(DoctorsActions.confirmDoctorRequest)
export const confirmDoctorSuccess = createAction(DoctorsActions.confirmDoctorSuccess)
export const confirmDoctorFailure = createAction(DoctorsActions.confirmDoctorFailure)

export const setDoctorsFilters = createAction(DoctorsActions.setDoctorsFilters)
export const setDoctorsFiltersGeoloc = createAction(DoctorsActions.setDoctorsFiltersGeoloc)

export const fetchStructuresMembersSuccess = createAction(DoctorsActions.fetchStructuresMembersSuccess)

//#endregion

//#region Reducer
const list = handleActions({
  [DoctorsActions.fetchDoctorsSuccess]: (state, action: AnyAction) => action.payload.doctors,
}, [])

const byId = handleActions({
  [DoctorsActions.fetchDoctorSuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
}, {})

const profiles = handleActions<{ [key: number]: DoctorPayload }>({
  [DoctorsActions.fetchDoctorProfileSuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.id]: action.payload.data,
  }),
  [DoctorsActions.confirmDoctorSuccess]: (state, { payload }: AnyAction) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      ...payload.data,
    },
  }),
}, {})

const loading = handleActions({
  [DoctorsActions.confirmDoctorRequest]: (state) => ({ ...state, confirm: true }),
  [DoctorsActions.confirmDoctorSuccess]: (state) => ({ ...state, confirm: false }),
  [DoctorsActions.confirmDoctorFailure]: (state) => ({ ...state, confirm: false }),
}, {
  confirm: false,
})

const filters = handleActions({
  [DoctorsActions.setDoctorsFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "register_date", sortOrder: "DESC" })

const filtersGeoloc = handleActions({
  [DoctorsActions.setDoctorsFiltersGeoloc]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "register_date", sortOrder: "DESC",geoloc:"user" })

export const doctorsReducer = combineReducers({
  list,
  byId,
  profiles,
  loading,
  filters,
  filtersGeoloc,
})
//#endregion

//#region Thunks

export const fetchDoctors = (
  params: DoctorsFilters,
): ThunkResult<Promise<void | Pagination>> => (
  dispatch,
) => {
  dispatch(fetchDoctorsRequest())

  type Response = { paginator: Pagination, data: DoctorPayload[] }

  return axios.request<Response>({
    url: '/admin/doctor/list',
    params: {
      ...params,
      language: ['en', 'fr', 'ru', 'de'],
    },
  })
    .then(response => {
      const doctors: DoctorPayload[] = response.data.data
      dispatch(fetchDoctorsSuccess({
        doctors: doctors.map(doctor => normalizeDoctor(doctor)),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      dispatch(fetchDoctorsFailure(err))
    })
}

export const fetchDoctor = (
  id: number | string,
): ThunkResult<Promise<void | Doctor>> => (
  dispatch,
) => {
  dispatch(fetchDoctorRequest())

  type Response = { data: DoctorPayload }

  return axios.request<Response>({
    url: `/general/doctor/${id}`,
  })
    .then(response => {
      dispatch(fetchDoctorSuccess(normalizeDoctor(response.data.data)))
    })
    .catch((err) => {
      dispatch(fetchDoctorFailure(err))
    })
}

export const fetchDoctorProfile = (
  id: number | string,
): ThunkResult<Promise<void | Doctor>> => (
  dispatch,
) => {
  dispatch(fetchDoctorProfileRequest())

  type Response = { data: DoctorPayload }

  return axios.request<Response>({
    url: `/general/doctor/${id}`,
    params: {
      include: 'workPlace,education,schedule,diploma,qualificationCourses,structures',
    },
  })
    .then(response => {
      dispatch(fetchDoctorProfileSuccess({ id, data: response.data.data }))
    })
    .catch((err) => {
      dispatch(fetchDoctorProfileFailure(err))
    })
}

export const confirmDoctor = (
  value: boolean,
  id: number,
  idValidateur : number|null
): ThunkResult<Promise<void | Doctor>> => (
  dispatch,
) => {
  dispatch(confirmDoctorRequest(value))

  type Response = { data: DoctorPayload }

  const urlPrefix = value ? 'verify' : 'refuse'

  return axios.request<Response>({
    url: `/admin/doctor/${urlPrefix}/${id}`,
    method: 'POST',
    params: {
    ...(idValidateur ? { idvalid : idValidateur} : {})
    }
  })
    .then(response => {
      if(idValidateur){
        axios.request<Response>({
          url: `/admin/user/uservalidator/${idValidateur}`,
          method: 'GET',

        })
            .then(responseUserValidator => {
              response.data.data.attributes.validator = responseUserValidator.data.data.attributes;
              dispatch(confirmDoctorSuccess({ id: +id, data: response.data.data }))
              dispatch(fetchDoctorSuccess(normalizeDoctor(response.data.data)))
            })
            .catch((err) => {
              dispatch(confirmDoctorFailure(err))
            })
      }
      else{
        dispatch(confirmDoctorSuccess({ id: +id, data: response.data.data }))
        dispatch(fetchDoctorSuccess(normalizeDoctor(response.data.data)))
      }
    })
    .catch((err) => {
      dispatch(confirmDoctorFailure(err))
    })
}

export const fetchDoctorAvailableSuccess = createAction('FETCH_DOCTOR_AVAILABLE_SUCCESS')

export const EditStatusDoctorAvailability = (checked: boolean, doctorId: number | null = null) => (dispatch) => {
  dispatch(fetchDoctorAvailableSuccess())
  const data = new FormData()
  const check = checked ? 1 : 0;
  // @ts-ignore
  data.append('status_availability', check)
  if (doctorId) {
    data.append('doctor_id', doctorId);
  }
  return axios.post('/doctor/available', data)
      .then(response => dispatch(fetchDoctorAvailableSuccess(response.data)))
      .catch(e => dispatch(fetchDoctorAvailableSuccess(e)))
}
//#endregion
