/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { TooltipProps } from 'recharts'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import { format, parse } from 'date-fns'
import Divider from '@material-ui/core/Divider'
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { getPercent } from 'components/pages/Dashboard/Chart/Total'

//#region Styles
const wrapperStyles = css`
  && {
    padding: 16px;
    box-sizing: border-box;
    width: 300px;
    font-size: 14px;
    font-weight: 600;
  }
`

const dateStyles = css`
  color: ${Colors.grey};
  font-weight: 600;
`

const dividerStyles = css`
  && {
    margin: 16px 0;
  }
`

const infoStyles = (color: string) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 16px;
  font-size: 12px;
  
  &:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${color};
    left: 0;
  }
`

const titleStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const percentStyles = css`
  color: ${Colors.grey};
`

const firstBlockStyles = css`
  margin-bottom: 32px;
`
//#endregion

export const ChartTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
  if (!active || !payload || !payload.length) {
    return null
  }

  const data = (payload.find(item => item.dataKey === 'scheduled') || { payload: null }).payload

  if (!data) {
    return null
  }

  const sheduledPercent = getPercent(data.scheduled, data.total_consultation)
  const urgentPercent = getPercent(data.urgent, data.total_consultation)
  const tePercent = getPercent(data.telexpertise, data.total_consultation)
  const urgentTePercent = getPercent(data.urgent_telexpertise, data.total_consultation)

  const scheduledCostPercent = getPercent(data.scheduled_cost, data.total_cost)
  const urgentCostPercent = getPercent(data.urgent_cost, data.total_cost)
  const teCostPercent = getPercent(data.telexpertise_cost, data.total_cost)
  const urgentTeCostPercent = getPercent(data.urgent_telexpertise_cost, data.total_cost)

  return (
    <Paper elevation={2} css={wrapperStyles}>
      <div css={dateStyles}>
        {format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'EEEE, dd MMMM yyyy')}
      </div>
      <Divider css={dividerStyles} />
      <div css={firstBlockStyles}>
        <div css={titleStyles}>
          <FormattedMessage id='statistics.total.consultations' tagName='span' />
          <span>
            {data.total_consultation}
            <span css={percentStyles}> (100%)</span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.blue)}>
          <FormattedMessage id='statistics.by.appointment' tagName='span' />
          <span>
            {data.scheduled}
            <span css={percentStyles}>
              {' ('}
              {sheduledPercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.menuActive)}>
          <FormattedMessage id='statistics.urgent' tagName='span' />
          <span>
            {data.urgent}
            <span css={percentStyles}>
              {' ('}
              {urgentPercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.green)}>
          <FormattedMessage id='statistics.telexpertise' tagName='span' />
          <span>
            {data.telexpertise}
            <span css={percentStyles}>
              {' ('}
              {tePercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.red)}>
          <FormattedMessage id='statistics.urgent_telexpertise' tagName='span' />
          <span>
            {data.urgent_telexpertise}
            <span css={percentStyles}>
              {' ('}
              {urgentTePercent}
              %)
            </span>
          </span>
        </div>
      </div>
      <div>
        <div css={titleStyles}>
          <FormattedMessage id='statistics.total.cost' tagName='span' />
          <span>
            {data.total_cost}
            <span css={percentStyles}> (100%)</span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.blue)}>
          <FormattedMessage id='statistics.by.appointment' tagName='span' />
          <span>
            {data.scheduled_cost}
            <span css={percentStyles}>
              {' ('}
              {scheduledCostPercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.menuActive)}>
          <FormattedMessage id='statistics.urgent' tagName='span' />
          <span>
            {data.urgent_cost}
            <span css={percentStyles}>
              {' ('}
              {urgentCostPercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.green)}>
          <FormattedMessage id='statistics.telexpertise' tagName='span' />
          <span>
            {data.telexpertise_cost}
            <span css={percentStyles}>
              {' ('}
              {teCostPercent}
              %)
            </span>
          </span>
        </div>
        <div css={() => infoStyles(Colors.red)}>
          <FormattedMessage id='statistics.urgent_telexpertise' tagName='span' />
          <span>
            {data.urgent_telexpertise_cost}
            <span css={percentStyles}>
              {' ('}
              {urgentTeCostPercent}
              %)
            </span>
          </span>
        </div>
      </div>
    </Paper>
  )
}
