import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import { Button } from 'components/common/Button'
import {Edit as EditIcon, Visibility} from '@mui/icons-material';
import styled from '@emotion/styled'
import axios from 'axios';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {Colors} from "../../../../constants/colors";
import {Structure} from "../dataModel";
import {Dialog, DialogActions, DialogContent, DialogTitle, Link, Paper} from "@mui/material";
import {Tabs} from "../../../common/Tabs";
import {userAppUrl} from "../../../../constants/routes";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import GroupIcon from "@mui/icons-material/Group";
import {setDoctorsFilters} from "../../../../ducks/modules/doctors";
import {Doctors} from "../../Doctors";
import {StructuresMembersList} from "../Members";
import {StructuresMembersTab} from "./StructuresMembersTab";

const Wrapper = styled.div`
    min-width: 720px;
    margin: 24px;
`

const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
  margin-left: 30px;
`

const StyledButtonMembers = styled(Button)`
  background-color: ${Colors.darkGreen};
  color: ${Colors.white};
  border-radius: 50px;
  margin-left: 20px;
`

const PanelTab = styled.div`

`

type StructInfoProps = {
    name: string,
    value: any,
    imgAlt?: string | null,
    style?: any,
}

const StructInfo = ({ name, value, imgAlt = null, style= {} }: StructInfoProps) => {
  if (!value) {
    return (<></>)
  } // else

  return (<div style={{ margin: '5px 0 15px 0', ...style }}>
    <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
      <FormattedMessage id={name} /> :
    </span>
    {
      !!imgAlt && (
        <img style={{ marginLeft: '20px', verticalAlign: 'top', border: '1px solid #EEE' }} width='150px' alt={imgAlt} src={value} />
      ) || (
        <span style={{ marginLeft: '20px' }}>{value}</span>
      )
    }
  </div>)
}
StructInfo.defaultProps = {}

const SameLine = ({ children }: { children: any }) => {
  let filteredChildren = Array.from(children)
  filteredChildren = filteredChildren.slice(0, children.length - 1)
  return (
    <div>
      {
        filteredChildren.map((child, index) => (
          <div key={index} style={{ float: 'left', marginRight: '50px' }}>
            { child }
          </div>
        ))
      }

      <div>
        { children[children.length - 1] }
      </div>
      <div style={{ clear: 'both' }}></div>
    </div>
  )
}

export const ViewStructure = ({ match }: { match: any }) => {
  const history = useHistory();
  const { formatMessage } = useIntl()
  const [structure, setStructure] = useState<Structure>({});
  const id = get(match, 'params.id', false);

    const [activeTab, setTab] = useState(0)
    const [mainTabVisibility, setMainTabVisibility] = useState('visible')
    const [memberTabVisibility, setMemberTabVisibility] = useState('hidden')
    const [mainTabDisplay, setMainTabDisplay] = useState('')
    const [memberTabDisplay, setMemberTabDisplay] = useState('none')
    const onTabChange = (tab: number) => {
        if (tab === 0) {
            setMainTabVisibility('visible');
            setMemberTabVisibility('hidden');
            setMainTabDisplay('');
            setMemberTabDisplay('none');
            setTab(tab);
        } else if (tab === 1) {
            setMainTabVisibility('hidden');
            setMemberTabVisibility('visible');
            setMainTabDisplay('none');
            setMemberTabDisplay('');
            setTab(tab)
        }
    }

    const loadStructure = () => {
    if (id) {
      axios.get(`/admin/doctor/structure/${id}`)
        .then(resp => {
          const respData = resp.data.data;
          const structured = { ...respData, ...respData.attributes }
          setStructure(structured);
        })
        .catch(err => {
          console.warn(err)
        })
    }
  }
  useEffect(() => loadStructure(), [])

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [activationError, setActivationError] = useState<string|null>(null)
  const [activationInProgress, setActivationInProgress] = useState<boolean>(false)

  const onConfirmStructureChangeActivation = () => {
    setActivationInProgress(true)
    axios.post(`/admin/structure/change-activation/${id}/${!structure.isActive ? 1 : 0}`)
      .then(_ => {
        setActivationInProgress(false)
        setConfirmOpen(false)
        loadStructure()
      })
      .catch(err => {
        console.warn(err)
        setActivationInProgress(false)
        setActivationError(formatMessage({id: 'structures.view.activation-error'}))
      })
  }

  const urlGrafana = encodeURI('https://grafana.conex.cloud/d/TWCLjhlnk/kpi-prod-structures?orgId=1&from=now-5y&to=now&var-Structure=' + structure.name);

  const status = formatMessage({ id: 'structures.status.' + (structure.status ? 'public' : 'private') })
  return (
    <>
      <Tabs
        tabs={[structure.name || '-', 'roso.members']}
        active={activeTab}
        onChange={onTabChange}
        actions={(
            <div style={{display: "flex", position: 'absolute', right: '5px', top: '5px' }}>
                <Button
                  onClick={() => setConfirmOpen(true)}
                  color={structure.isActive ? 'error' : 'success'}
                >
                  <FormattedMessage id='structures.view.activation-button' values={{
                    action: formatMessage({ id: 'structures.view.' + (structure.isActive ? 'deactivate' : 'activate') })
                  }} />
                </Button>
            </div>
        )}
      />
      <Dialog open={confirmOpen}>
        <DialogTitle><FormattedMessage id='structures.view.confirm-title' /></DialogTitle>
        <DialogContent>
          <FormattedMessage id='structures.view.confirm-activation' values={{
            action: formatMessage({ id: 'structures.view.' + (structure.isActive ? 'deactivate' : 'activate') })
          }} />
          {
            !!activationError && (
              <div>{activationError}</div>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={() => setConfirmOpen(false)}><FormattedMessage id='global.cancel' /></Button>
          <Button color='success' isFetching={activationInProgress}
                  onClick={onConfirmStructureChangeActivation}><FormattedMessage id='global.yes' /></Button>
        </DialogActions>
      </Dialog>
      <Wrapper>
        <PanelTab style={{ visibility: mainTabVisibility, display: mainTabDisplay }}>
          <Paper style={{ padding: '20px' }}>
            <div style={{ minHeight: '35px', verticalAlign: 'middle' }}>
              <div style={{ float: 'right' }}>
                <Button style={{backgroundColor: Colors.white, color: Colors.clearBlue, border: '2px solid', borderColor: Colors.clearBlue, height: '38px', width: '150px', marginLeft: 'auto'}} onClick={() => { window.open(urlGrafana, "_blank") }}>
                    <QueryStatsIcon style={{marginRight: '10px'}}/>
                    <FormattedMessage id="global.dashboard.button" />
                </Button>
                <StyledButton onClick={() => history.push(`/structures/${structure.id}`)} variant='contained'
                              startIcon={<EditIcon/>}>
                  <FormattedMessage id="structures.modify"/>
                </StyledButton>
              </div>
              <div style={{ fontWeight: 'bold', verticalAlign: 'middle', display: 'inline-block' }}>
                <FormattedMessage id='structures.view.information' />
              </div>
            </div>

              <hr style={{ margin: '20px -20px' }}/>

              <SameLine>
                <StructInfo name='Nom' value={structure.name} />
                {
                  !!structure.code && (
                    <PreviewLink href={userAppUrl(`/public/structure/${structure.code}`)} target='_blank' aria-disabled={!structure.code}>
                      <Visibility style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                      <FormattedMessage id='structures.view.page-preview' />
                    </PreviewLink>
                  )
                }
              </SameLine>
              <StructInfo name='structures.view.field.status' value={status} />
              <StructInfo name='structures.view.field.type' value={structure?.type?.type} />
              <StructInfo name='structures.field.finessGeographic' value={structure.finessGeographic} />
              <StructInfo name='structures.field.description' value={structure.description} />
              <StructInfo name='structures.field.email' value={structure.email} />
              <StructInfo name='structures.view.field.tel' value={structure.phone} />
              <StructInfo name='structures.field.website' value={structure.website} />
              <StructInfo name='structures.field.address-simple' value={structure.address} />
              <SameLine>
                <StructInfo name='structures.field.zipcode' value={structure.zipcode} />
                <StructInfo name='structures.field.city' value={structure.city} />
              </SameLine>
              <StructInfo name='structures.view.field.logo' value={structure.photo} imgAlt={structure.name} />
              <StructInfo name='structures.view.field.code' value={structure.code} />
                { structure.lastImporter && (
                    <StructInfo name='structures.view.lastImporter' value={structure.lastImporter.name + " " + structure.lastImporter.surname + " (" + structure.lastImporter.id + ")"} />
                )}

            </Paper>
        </PanelTab>
          <PanelTab style={{visibility: memberTabVisibility, display: memberTabDisplay}}>
              { structure.name &&
                <StructuresMembersTab structure={structure}/>
              }
          </PanelTab>
      </Wrapper>
    </>
  )
}

const PreviewLink = styled(Link)`
&& {
  float: right;
}
&& :hover {
  cursor: pointer;
}
`