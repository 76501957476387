/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'components/common/Tabs'
import { Search } from 'components/common/Search'
import { Content } from 'components/common/Content'
import { Pagination, State } from 'interfaces'
import { Doctor, DoctorsFilters } from 'interfaces/doctors'
import {fetchDoctors, setDoctorsFilters} from 'ducks/modules/doctors'
import { fetchSpecializations } from 'ducks/modules/specializations'
import { DoctorsTable } from './DoctorsTable'
import {DOCTORS_TABLE_HEADERS} from 'constants/tables'
import { TABS } from 'constants/doctors'
import { Routes } from 'constants/routes'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";

//#region Styles
const headerStyles = css`
  display: flex;
`

//#endregion

export const Doctors = () => {
  const dispatch = useDispatch()
  const doctors = useSelector<State, Doctor[]>(state => state.doctors.list)
  const filters = useSelector<State, DoctorsFilters>(state => state.doctors.filters)
  const pagination = useSelector<State, Pagination>(state => state.pagination.doctors)
  const history = useHistory()
  const [activeTab, setTab] = useState(0)

  /* Modification de l'affichage des status */
  doctors.forEach(value => {
    switch (value.status) {
       case 'NOT VERIFIED' :
         value.status_formatted = 'Terminée'
         break;
       case 'LIMITED' :
         value.status_formatted = 'En cours'
         break;
      case 'VERIFIED' :
      default :
        break
        ;
      }
  })
  useEffect(() => {
    dispatch(setActiveMenu('global.menu.doctors'))
    dispatch(setIsPrincipalPage(true))
  }, )

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchDoctors(filters))
    if (filters.tab) {
      setTab(filters.tab)
    }
  }, [dispatch, filters])

  const [sortBy, setSortBy] = useState(filters.sortBy)
  const [sortOrder,setSortOrder] = useState(filters.sortOrder)

  const requestSort = (pSortBy: string) => {
    if (pSortBy === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
    } else {
      setSortBy(pSortBy)
      setSortOrder("ASC")
    }
  }

  useEffect(() => {
    dispatch(setDoctorsFilters({...filters, sortBy: sortBy, sortOrder: sortOrder}))
  }, [sortBy, sortOrder])


  const onSearch = (search?: string) => dispatch(setDoctorsFilters({...filters, search}))

  const onPageChange = (page: number) => dispatch(setDoctorsFilters({...filters, page}))

  const onLimitChange = (limit: number) => dispatch(setDoctorsFilters({...filters, limit}))


  const onTabChange = (tab: number) => {
    if (tab === 0) {
      //On redonne tous les arguments, sinon le version 2 reste
      dispatch(setDoctorsFilters({page: 1, limit: 10, tab:0, status: 1, sortBy: "register_date", sortOrder: "DESC" }))
    }
    if (tab === 1) {
      dispatch(setDoctorsFilters({...filters, page: 1, tab: 1, status: ['0','10'],version: 2}))
    }

    if (tab === 2) {
      dispatch(setDoctorsFilters({...filters, page: 1, tab: 2, status: ['4'], sortBy: "register_date", sortOrder: "ASC", version: 2}))
    }

    setTab(tab)
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        active={activeTab}
        onChange={onTabChange}
      />
      <Content>
        <div css={headerStyles}>
          <Search
            onSearch={onSearch}
            label='doctors.search'
            input={filters.search}
          />
        </div>
        <DoctorsTable
          headers={DOCTORS_TABLE_HEADERS}
          data={doctors}
          pagination={pagination}
          onRowClick={(doctor: { id: any }) => history.push(`${Routes.doctors}/${doctor.id}`)}
          onPageChange={onPageChange}
          onPerPageChange={onLimitChange}
          sortBy={sortBy}
          sortOrder={sortOrder}
          requestSort={requestSort}
          activeTab={activeTab}
        />
        {/*<ExportDoctorsModal open={exportModal} onClose={() => toggleExportModal(false)} />*/}
      </Content>
    </>
  )
}
