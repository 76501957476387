export enum StatisticSteps {
  day = 'DAY',
  week = 'WEEK',
  month = 'MONTH',
}

export interface DashboardParams {
  dateFrom: string,
  dateTo: string,
  step: StatisticSteps,
  doctor_id?: number | string,
  specialization_id?: number | string,
}

export interface GeneralStatistics {
  effectiveness: string,
  totalPatients: number,
  totalDoctors: number,
}

export interface DashboardGeneral {
  scheduled: number,
  urgent: number,
  total_consultation: number,
  total_cost: number,
  scheduled_cost: number,
  urgent_cost: number,
  telexpertise: number,
  telexpertise_cost: number,
  urgent_telexpertise: number,
  urgent_telexpertise_cost: number,
}

export interface DashboardPoint {
  date: string,
  scheduled: number,
  urgent: number,
  total_consultation: number,
  total_cost: number,
  scheduled_cost: number,
  urgent_cost: number,
}

export interface DashboardPoints {
  [key: string]: DashboardPoint,
}
