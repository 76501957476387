export enum Routes {
  root = '/',
  auth = '/auth',
  dashboard = '/dashboard',
  doctors = '/doctors',
  urgent = '/urgent',
  effectiveness = '/effectiveness',
  payments = '/payments',
  medicine = '/medicine',
  support = '/support',
  settings = '/settings',
  templates = '/templates',
  content = '/content',
  chat = '/support/chat',
  structures = '/structures',
  structure = '/structure',
  consultations = '/consultations',
  roso = '/roso',
  team = '/team',
  smslogs = '/smslogs',
  globalNotifications = '/global-notifications',
  specializations = '/specializations',
  skills = '/skills',
  geolocation = '/geolocation',
  notification = '/notifications',
  mobile_version = '/mobile_version',
}

export const userAppUrl = (route: string|null) => {
  let url = window.env.API_URL.replace('backend', 'app')
  if (route) {
    url += route
  }
  return url
}