/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchDoctorProfile } from 'ducks/modules/doctors'
import { profileSelector } from 'selectors/doctor'
import { State } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { Preloader } from 'components/common/Preloader'
import { makeStyles } from '@material-ui/styles';
import Input from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { css } from '@emotion/react'
import get from 'lodash/get'
import axios from 'axios'
import { Paper } from '../../../common/UiKit/Paper'

import { Theme } from '@material-ui/core';
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";

declare module '@material-ui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

interface ManageProps {
  id: number | string,
}

const useStyles = makeStyles((theme) => ({
  margin: {
    // margin: theme.spacing(1),
    width: '400px',
  },
}));

const PaperStyle = css`
  margin-top: 15px;
  padding: 15px;
  box-shadow: rgba(6, 173, 255, 0.1) 0px 4px 8px 0px;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 28px auto;
  padding: 30px;
`
const changeButton = css`
height: 40px;
width: 250px;
`

const TextField = css`
  height: 40px;
  width: 500px;
  background-color: ${Colors.background};
`

const Wrapper = styled.div`
  margin-top: 15px;
  width: 70%;
  justify-content: space-around;
  align-items: center;
  margin: 28px auto;
`
const Content = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
`

const PaperStyled = styled(Paper)`
  ${props => (props.small ? 'padding: 18px 16px' : 'padding-top: 24px')}
`

export const Manage: React.FC<ManageProps> = ({ id, setUserWantingToModifyPersonalData }) => {
  const profile = useSelector<State, any>(profileSelector(id))
  const defaultEmail = get(profile, 'attributes.email', '');
  const defaultPhoneNumber = get(profile, 'attributes.phone_number', '');
  const [email, setEmail] = useState(defaultEmail);
  const [number, setNumber] = useState(defaultPhoneNumber);
  const dispatch = useDispatch()

  const changeEmailAndPhoneNumber = () => {
    axios.post(`/admin/doctor/${id}/phone/number`, { phoneNumber: number })
        .then(() => dispatch(fetchDoctorProfile(id)))
        .then(() => setUserWantingToModifyPersonalData(false))
        .catch(err => console.warn(err))
    axios.post(`/admin/doctor/${id}/email`, { email })
      .then(() => dispatch(fetchDoctorProfile(id)))
      .then(() => setUserWantingToModifyPersonalData(false))
      .catch(err => console.warn(err))
  }
  // const changePhoneNumber = async () => {
  //   await axios.post(`/admin/doctor/${id}/phone/number`, { phoneNumber: number })
  //     .then(() => dispatch(fetchDoctorProfile(id)))
  //     .catch(err => console.warn(err))
  // }

  useEffect(() => {
    dispatch(fetchDoctorProfile(id))
  }, [id, dispatch])

  if (!profile) {
    return <Preloader />
  }


  return (
    <>
    <Wrapper>
      <PaperStyled
          small={true}
          heading={"profile.change_email"}
      >
      <Content>

        <FormControl>
          <InputLabel htmlFor="Change email"></InputLabel>
          <Input
            name='email'
            onChange={e => setEmail(e.target.value)}
            defaultValue={defaultEmail}
            id="Change email"
            type='text'
            css={TextField}
          />
        </FormControl>


      </Content>
      </PaperStyled>
    </Wrapper>

    <Wrapper>
      <PaperStyled small={true} heading={"profile.change_phone"}>
        <Content>

          <FormControl>
            <InputLabel htmlFor="Change phone number" ></InputLabel>
            <Input
              name='phone'
              defaultValue={defaultPhoneNumber}
              onChange={e => setNumber(e.target.value)}
              id="Change phone number"
              type='text'
              css={TextField}
            />
          </FormControl>


        </Content>
      </PaperStyled>
          <Button
            css={changeButton}
            onClick={() =>
              changeEmailAndPhoneNumber()
            }
            color='primary'
            variant='contained'
          >
            <FormattedMessage id="global.save" />
          </Button>
    </Wrapper>
    </>
  )
};



