import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'
import { Paper } from '../Paper';
import { Colors } from '../../../../constants/colors.ts'

const PaperStyled = styled(Paper)`
  padding: ${props => (props.blank ? 0 : '24px')};

  & > p {
    opacity: 0.8;
    margin: 0;
  }
`;

const Line = styled.div`
  display: flex;
  margin-bottom: 24px;
  
  ${({ center }) => (center ? 'align-items: center; & > div { margin-right: 36px; }' : '')}

  .prices {
    flex-grow: 1;
    display: flex;
    & > div {
      min-width: 150px;
      margin-left: 15px;
      text-align: center;
    }
  }
  
  .number {
    font-weight: bold;
    font-size: 16px;
    color: ${Colors.dark};
    word-break: break-all;
    margin-top: 8px;
  }
  .requisites {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    
    & > div {
      min-width: 150px;
      margin-right: 15px;
    }
  }
`;

const Label = styled.div`
  width: 172px;
  min-width: 172px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

export const PricesConsultations = ({
  changeButtonPath,
  price,
  requisites,
  methodField,
}) => (
  <PaperStyled
    blank={!price}
    heading={<FormattedMessage id='profile.requisites.prices.title' />}
    changeButtonPath={changeButtonPath}
  >
    <Line>
      <Label>
        <FormattedMessage id='profile.cost.consultation' />
      </Label>
      <div className='prices'>
        <div>
          <FormattedMessage id='profile.urgent.price' />
          <div className='number'>{`${price.daily_price} €`}</div>
        </div>
        <div>
          <FormattedMessage id='profile.weekend' />
          <div className='number'>{`${price.weekend_price} €`}</div>
        </div>
        <div>
          <FormattedMessage id='profile.urgent' />
          <div className='number'>{`${price.urgent_price} €`}</div>
        </div>
      </div>
    </Line>
    {methodField && (
    <Line center>
      <Label>
        <FormattedMessage id='profile.method' />
      </Label>
      <div>
        {methodField}
      </div>
    </Line>
    )}
    <Line>
      <Label>
        <FormattedMessage id='profile.requisites.liberal' />
      </Label>
      <div className='prices'>
        <div>
          <FormattedMessage id='profile.iban' />
          <div className='number'>{requisites.private.iban || '-'}</div>
        </div>
        <div>
          <FormattedMessage id='profile.bic' />
          <div className='number'>{requisites.private.bic || '-'}</div>
        </div>
      </div>
    </Line>
    <Line>
      <Label>
        <FormattedMessage id='profile.requisites.salary' />
      </Label>
      <div className='prices'>
        <div>
          <FormattedMessage id='profile.iban' />
          <div className='number'>{requisites.hospital.iban || '-'}</div>
        </div>
        <div>
          <FormattedMessage id='profile.bic' />
          <div className='number'>{requisites.hospital.bic || '-'}</div>
        </div>
      </div>
    </Line>
  </PaperStyled>
);

PricesConsultations.propTypes = {
  //price: PropTypes.object.isRequired,
  //requisites: PropTypes.object.isRequired,
  changeButtonPath: PropTypes.string.isRequired,
}
