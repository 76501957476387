import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { FormattedMessage } from 'react-intl'
import { Paper } from '../Paper'
import { Colors } from '../../../../constants/colors.ts'

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
`

const NoLinkStyled = styled.div`
  width: 100%;
  cursor: pointer;
`

const Wrapper = styled(Paper)`
  padding: 24px 18px 24px 24px;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`

const Content = styled.div`
  flex-grow: 1;
  font-size: 14px;
  color: ${Colors.dark70};
  line-height: 1.7;
`

const Text = styled.p`
  margin: 6px 0 0;
  line-height: 1;
`

const Label = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.9;
  word-wrap: break-word;
  line-height: 1;
`

export const LinkCard = ({
  label,
  text,
  length,
  className,
  hideArrow,
  ...rest
}) => {
  const Component = rest.to ? LinkStyled : NoLinkStyled

  return (
    <Component {...rest}>
      <Wrapper className={className}>
        <Content>
          <Label>
            <FormattedMessage id={label} defaultMessage={label} />
          </Label>
          {text && <Text><FormattedMessage id={text} defaultMessage={text} /></Text>}
        </Content>
        {length}
        {
          (!hideArrow || !!length)
            ? <KeyboardArrowRight />
            : null
        }
      </Wrapper>
    </Component>
  )
}

LinkCard.defaultProps = {
  length: null,
  text: '',
  hideArrow: false,
  className: '',
}

LinkCard.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  length: PropTypes.number,
  className: PropTypes.string,
  hideArrow: PropTypes.bool,
}
