import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import {ConsultationFilters, ConsultationPayload, Doctor, DoctorPayload} from 'interfaces/doctors'
import { normalizeConsultation } from 'helpers/doctors'
import {Pagination, ThunkResult} from 'interfaces'
import {
  DoctorsActions,
  fetchDoctorProfileFailure,
  fetchDoctorProfileRequest,
  fetchDoctorProfileSuccess
} from "../doctors";

//#region Actions
export enum ConsultationsActions {
  fetchConsultationsRequest = 'FETCH_CONSULTATIONS_REQUEST',
  fetchConsultationsSuccess = 'FETCH_CONSULTATIONS_SUCCESS',
  fetchConsultationsFailure = 'FETCH_CONSULTATIONS_FAILURE',

  sendNotificationConsultationCreatedRequest = 'SEND_NOTIFICATION_CONSULTATION_CREATED_REQUEST',
  sendNotificationConsultationCreatedSuccess = 'SEND_NOTIFICATION_CONSULTATION_CREATED_SUCCESS',
  sendNotificationConsultationCreatedFailure = 'SEND_NOTIFICATION_CONSULTATION_CREATED_FAILURE',

}

export const fetchConsultationsRequest = createAction(
  ConsultationsActions.fetchConsultationsRequest,
)
export const fetchConsultationsSuccess = createAction(
  ConsultationsActions.fetchConsultationsSuccess,
)
export const fetchConsultationsFailure = createAction(
  ConsultationsActions.fetchConsultationsFailure,
)

export const sendNotificationConsultationCreatedRequest  = createAction(ConsultationsActions.sendNotificationConsultationCreatedRequest);
export const sendNotificationConsultationCreatedSuccess  = createAction(ConsultationsActions.sendNotificationConsultationCreatedSuccess);
export const sendNotificationConsultationCreateFailure  = createAction(ConsultationsActions.sendNotificationConsultationCreatedFailure);

//#endregion

//#region Reducer
const byDoctor = handleActions({
  [ConsultationsActions.fetchConsultationsSuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.id]: action.payload.data,
  }),
}, {})

const notification = handleActions({
  [ConsultationsActions.sendNotificationConsultationCreatedSuccess]: (state, action: AnyAction) => action.payload.data,
}, [])

export const consultationsReducer = combineReducers({
  byDoctor,
  notification
})
//#endregion

//#region Thunks

export const fetchConsultations = (
  id: number,
  filters: ConsultationFilters,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchConsultationsRequest())

  type Response = { data: ConsultationPayload[], paginator: Pagination }

  return axios.request<Response>({
    url: '/admin/consultation/search',
    params: {
      include: 'additionalMember,patient,review',
      doctor_id: id,
      ...filters,
    },
    // headers: {
    //   'User-Id': id,
    // },
  })
    .then(response => {
      dispatch(fetchConsultationsSuccess({
        id,
        data: response.data.data.map(item => normalizeConsultation(item)),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      console.warn(err)
      dispatch(fetchConsultationsFailure(err))
    })
}
//#endregion