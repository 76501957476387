/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import { ChartTotal } from 'components/pages/Dashboard/Chart/Total'
import { useDispatch, useSelector } from 'react-redux'
import {
  DashboardGeneral,
  DashboardParams,
  DashboardPoints,
  StatisticSteps,
} from 'interfaces/dashboard'
import { State } from 'interfaces'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { valuesIn } from 'lodash'
import { Colors } from 'constants/colors'
import { format, parse } from 'date-fns'
import { ChartTooltip } from 'components/pages/Dashboard/Chart/Tooltip'
import { ChartFilters } from 'components/pages/Dashboard/Chart/Filters'
import { changeParams, fetchDashboard } from 'ducks/modules/statistics'
import { setFilter } from 'helpers/filters'

//#region Styles
const wrapperStyles = css`
  && {
    padding: 32px;
  }
`
//@endregion

interface DashboardChartProps {
  doctorId?: number | string,
}

export const DashboardChart: React.FC<DashboardChartProps> = ({ doctorId }) => {
  const total = useSelector<State, DashboardGeneral>(state => state.statistics.chart.total)
  const points = useSelector<State, DashboardPoints>(state => state.statistics.chart.points)
  const params = useSelector<State, DashboardParams>(state => state.statistics.params)

  const dispatch = useDispatch()

  useEffect(() => {
    if (doctorId) {
      dispatch(changeParams(setFilter({ ...params, doctor_id: doctorId }, 'specialization_id', undefined)))
    } else {
      dispatch(changeParams(setFilter(params, 'doctor_id', undefined)))
    }
  }, [dispatch, doctorId])

  useEffect(() => {
    if (params.dateTo !== params.dateFrom) {
      dispatch(fetchDashboard(params))
    }
  }, [dispatch, params])

  return (
    <>
      <ChartFilters data={params} />
      <Paper elevation={3} css={wrapperStyles}>
        <ChartTotal data={total} />
        <ResponsiveContainer height={350}>
          <BarChart data={valuesIn(points)}>
            <CartesianGrid />
            <XAxis
              dataKey='date'
              tickFormatter={item => {
                if (item) {
                  return format(parse(item, 'yyyy-MM-dd HH:mm:ss', new Date()), params.step === StatisticSteps.day ? 'dd.MM.yyyy' : 'MMMM yyyy')
                }

                return item
              }}
            />
            <YAxis dataKey='total_consultation' />
            <Tooltip
              content={<ChartTooltip />}
            />
            <Bar dataKey='scheduled' stackId='a' fill={Colors.blue} maxBarSize={50} />
            <Bar dataKey='urgent' stackId='a' fill={Colors.menuActive} maxBarSize={50} />
            <Bar dataKey='telexpertise' stackId='a' fill={Colors.green} maxBarSize={50} />
            <Bar dataKey='urgent_telexpertise' stackId='a' fill={Colors.red} maxBarSize={50} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </>
  )
}
