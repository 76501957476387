import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { ChatI } from 'interfaces/chats'
import { uniqBy } from 'lodash'
import { socket } from 'services/socket'
import get from 'lodash/get';
import update from 'react-addons-update';

//#region Actions
export enum ChatsActions {
  fetchChatsRequest = 'FETCH_CHATS_REQUEST',
  fetchChatsSuccess = 'FETCH_CHATS_SUCCESS',
  fetchChatsFailure = 'FETCH_CHATS_FAILURE',

  uploadFileRequest = 'UPLOAD_FILE_REQUEST',
  uploadFileSuccess = 'UPLOAD_FILE_SUCCESS',
  uploadFileFailure = 'UPLOAD_FILE_FAILURE',

  handleMessage = 'HANDLE_MESSAGE',
  loadLastMessages = 'CHATS_LOAD_LAST_MESSAGES',
}

export const fetchChatsRequest = createAction(ChatsActions.fetchChatsRequest)
export const fetchChatsSuccess = createAction(ChatsActions.fetchChatsSuccess)
export const fetchChatsFailure = createAction(ChatsActions.fetchChatsFailure)

export const uploadFileRequest = createAction(ChatsActions.uploadFileRequest)
export const uploadFileSuccess = createAction(ChatsActions.uploadFileSuccess)
export const uploadFileFailure = createAction(ChatsActions.uploadFileFailure)

export const handleMessage = createAction(ChatsActions.handleMessage)
export const loadLastMessages = createAction(ChatsActions.loadLastMessages)
//#endregion

interface Message {
  attributes: any
  id: any
}

interface ChatL {
  attributes: {
    id: number,
    lastMessages: any[]
  }
}

//#region Reducer
const list = handleActions({
  [ChatsActions.loadLastMessages]: (
    state,
    action: AnyAction,
  ) => {
    const chatId = get(action, 'payload[0].attributes.chat_id', false);

    if (!chatId) return state

    if (action.payload.length) {
      const index = state.findIndex((el: ChatL) => el.attributes.id === chatId);

      if (index !== -1 && Array.isArray(state) && state[index]) {
        const newState: any[] = state || [];
        const updatedChat = { 
          ...newState[index], 
          attributes: { ...newState[index].attributes, lastMessages: action.payload }, 
        };
        return update(state, { 
          [index]: { 
            $set: { 
              ...updatedChat,
            },
          },
        });
      }
    }

    return state
  },  
  [ChatsActions.fetchChatsSuccess]: (state, action: AnyAction) => (action.payload.load ? [
    ...state,
    ...action.payload.data,
  ] : action.payload.data),
  // @ts-ignore
  [ChatsActions.handleMessage]: (state, { payload }: AnyAction) => {
    if (payload.action === 'chatConnect') {
      const chatId = payload.attributes.chat.id

      return state.map((item: ChatI) => {
        if (item.attributes.id === chatId) {
          return {
            ...item,
            attributes: { ...item.attributes, lastMessages: payload.attributes.lastMessages },
          }
        }

        return item
      })
    }

    if (payload.action === 'MessageReaded') {
      const { id, chat_id, read } = get(payload, 'attributes[0].attributes', {});
      if (!id || !(state instanceof Object) || !chat_id || read === 0) return state
      return state.map((el: any) => {
        if (String(el.id) === String(chat_id)) {
          const { attributes } = el;
          const lastMessages = get(attributes, 'lastMessages', [])
            .map((message: Message) => ({ 
              ...message, 
              attributes: { 
                ...message.attributes, 
                read: 1,
              }, 
            }))
          return { ...el, attributes: { ...attributes, lastMessages } };
        }
        return el;
      });
    }

    if (payload.action === 'MessageReceived') {
      const chatId = payload.attributes.chat_id

      return state.map((chat: ChatI) => {
        if (chat.attributes.id === chatId) {
          const newMessages = uniqBy([
            { id: payload.id, attributes: payload.attributes },
            ...(chat.attributes.lastMessages || []),
          ], 'id')
          return {
            ...chat,
            attributes: {
              ...chat.attributes,
              lastMessages: newMessages,
            },
          }
        }

        return chat
      })
    }

    if (payload.action === 'loadMessage' && payload.attributes.length) {
      const chatId = payload.attributes[0].attributes.chat_id

      if (!chatId) return state

      return state.map((chat: ChatI) => {
        if (chat.attributes.id === chatId) {
          const newMessages = uniqBy([
            ...(chat.attributes.lastMessages || []),
            ...payload.attributes,
          ], 'id')
          return {
            ...chat,
            attributes: {
              ...chat.attributes,
              lastMessages: newMessages,
            },
          }
        }

        return chat
      })
    }

    return state
  },
}, [])

export const chatsReducer = combineReducers({
  list,
})
//#endregion

//#region Thunks

export const fetchChats = (
  params?: { page?: number, name?: string },
  load?: boolean,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchChatsRequest())

  return axios('/admin/support/chat', { params })
    .then(response => {
      dispatch(fetchChatsSuccess({
        data: response.data.data,
        pagination: response.data.paginator,
        load,
      }))
    })
    .catch((err) => {
      dispatch(fetchChatsFailure(err))
    })
}

export const uploadFile = (
  data: any,
  chatId: any,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(uploadFileRequest())
  const formData = new FormData()

  formData.append('file', data.file)

  return axios('/api/attachment/upload', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      dispatch(uploadFileSuccess(response.data))
      socket.sendFileMessage(chatId, response.data.data.id)
    })
    .catch((err) => {
      dispatch(uploadFileFailure(err))
    })
}

export const fetchLastMessages = (chatId: number) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  axios.get(`/api/chat/messages?chatId=${chatId}`)
    .then((response) => {
      const messages = get(response, 'data.data', []);
      dispatch(loadLastMessages(messages))
    })
}
//#endregion
