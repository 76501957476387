/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { ChangeEvent, useState } from 'react'
import { TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'
import { Theme } from 'utils/styled'
import {theme} from "../../App";

//#region Styles
const styles = (theme: Theme) => css`
  && {
    display: inline-block;
    background-color: ${Colors.white};
    width: 350px;
    
    input {
      font-size: 0.875rem;
    }
    
    label {
      font-size: 0.875rem;
    }
    
    .Mui-focused svg {
      fill: ${theme.palette.primary.main};
    }
  }
`
//#endregion

interface SearchProps {
  onSearch: (value: string) => void,
  label: string,
  input?: string,
}

export const Search: React.FC<SearchProps> = ({ onSearch, label, input }) => {
  const [value, onChange] = useState<string>(input ? input : '')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onSearch(value)
    }
  }

  return (
    <TextField
      value={value}
      onChange={handleChange}
      css={styles(theme)}
      variant='outlined'
      label={<FormattedMessage id={label} />}
      size='small'
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton size='small' onClick={() => onSearch(value)}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        onKeyDown,
      }}
    />
  )
}
