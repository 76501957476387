/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'

//#region Styles
const styles = css`
  && {
    background-color: ${Colors.white};
    height: 38px;
    min-width: 180px;
    
    & > div {
      padding: 9.5px 14px;
      font-size: 0.875rem;
    }
  }
`

const wrapperStyles = css`
  && {
    margin-left: 16px;
    text-align: left;
    background-color: ${Colors.white};
  
    label:not(.MuiInputLabel-shrink) {
      font-size: 0.875rem;
      top: -8px;
    }
  }
`
//#endregion

interface FilterProps {
  options: { name: string | number, id: any }[],
  onSelect: (value?: string | number) => void,
  label: any,
  value: string | number | null,
}

export const Filter: React.FC<FilterProps> = ({
  options,
  onSelect,
  label,
  value,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string | number
    onSelect(newValue)
  }

  const onClear = () => {
    onSelect()
  }

  return (
    <FormControl variant='outlined' css={wrapperStyles}>
      <InputLabel>
        <FormattedMessage id={label} />
      </InputLabel>
      <Select
        value={value || ''}
        onChange={handleChange}
        variant='outlined'
        label={<FormattedMessage id={label} />}
        css={styles}
      >
        <MenuItem onClick={onClear} value=''>
          <FormattedMessage id='global.none' />
        </MenuItem>
        {
          Array.isArray(options) && options.map(item => (
            <MenuItem value={item.id} key={item.id}>
              {
                typeof item.name === 'number' ? item.name : (
                  <FormattedMessage id={item.name} defaultMessage={item.name} />
                )
              }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
