/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import MuiTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import Container from '@material-ui/core/Container'

const tabStyles = css`
  && {
    font-weight: 500;
    font-size: 14px;
  }
`

const containerStyles = css`
  && {
    position: relative;
  }
`

interface TabsProps {
  tabs: string[],
  active: number,
  onChange: (tab: number) => void,
  actions?: React.ReactNode,
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  active,
  onChange,
  actions,
  setUserWantingToModifyPersonalData,
  setUserWantingToModifyAboutDoctorData,
}) => (
  <Paper elevation={4}>
    <Container css={containerStyles}>
      <MuiTabs
        value={active}
        onChange={(event: React.ChangeEvent<{}>, value: number) => onChange(value)}
        textColor='primary'
        indicatorColor='primary'
      >
        {
          tabs.map((tab: string) => (
            <Tab
              onClick={() => {
                setUserWantingToModifyPersonalData(false)
                setUserWantingToModifyAboutDoctorData(false)
              }}
              css={tabStyles}
              label={<FormattedMessage id={tab} />}
              key={tab}
            />
          ))
        }
      </MuiTabs>
      {actions}
    </Container>
  </Paper>
)
