/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { css } from '@emotion/react'
import { Theme } from 'utils/styled'
import { theme} from "../../App";

const spinnerStyles = (theme: Theme) => css`
  && svg {
    color: ${theme.palette.common.white};
  }
`

const buttonStyles = (theme: Theme) => css`
  &&:disabled {
    color: ${theme.palette.common.white};
  }
`

interface CustomButtonProps extends ButtonProps {
  isFetching?: boolean,
}

export const Button: React.FC<CustomButtonProps> = ({ children, isFetching, ...rest }) => (
  <MuiButton
    color='primary'
    variant='contained'
    css={buttonStyles(theme)}
    disabled={isFetching || rest.disabled}
    {...rest}
  >
    {isFetching ? <CircularProgress css={spinnerStyles(theme)} size={24} /> : children}
  </MuiButton>
)
