import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { State } from 'interfaces';
import styled from '@emotion/styled'
import { TextField } from 'components/common/TextField'
import { stringify } from 'querystring';
import pickBy from 'lodash/pickBy';
import { Button } from 'components/common/Button'
import {FormattedMessage, useIntl} from 'react-intl'
import {setIsPrincipalPage} from "../../../ducks/modules/menu";
import {Colors} from "../../../constants/colors";
import {Version} from "../../../interfaces/version";
import get from 'lodash/get';


const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`

const ButtonCreate = styled(Button)`
    background-color: ${Colors.green};
    margin-top: 5%;
    margin-right:0%
    color: ${Colors.white};
    border-radius: 50px;
    &:hover:not(.active) {
    background-color: ${Colors.green};
    }
`
const DivButton = styled.div`
    display: flex;
    justify-content: right;
`

const required = (value: any) => (value ? undefined : 'Required')

const onSubmit = async (values: any, setRequestError: (a: string) => void, cb: () => void) => {
    try {
        const result = await axios.post(`/versionapp`, stringify(pickBy(values)));
        cb();
        return result.data.data;
    } catch (e) {
        console.warn(e);
        const error = get(e, 'response.data.data.validation.min_version_supported', 'default')
        setRequestError(error)
        return [];
    }
}

export const EditVersion = ({ match }: { match: { params: { software: string } } }) => {
    const history = useHistory();
    const [requestError, setRequestError] = useState<string|null>(null);
    const versions = useSelector<State, Version[]>(state => state.versions.mobileVersions);
    const currentVersion = versions.find(el => el.software === match.params.software) || false;
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const f = (id: string) => formatMessage({ id })

    if (currentVersion === false) {
        history.push('/mobile_version');
        return null;
    }

    useEffect(() => {
        dispatch(setIsPrincipalPage(false))
    }, )

    return (
        <Wrapper>
            <Form
                onSubmit={(values) => onSubmit(values, setRequestError, () => history.push('/mobile_version/'))}
                initialValues={{
                    software: currentVersion.software,
                    min_version_supported: currentVersion.min_version,
                }}
                render={({
                             handleSubmit, form, submitting, values,
                         }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="software" validate={required}>
                            {({ input, meta }) => (
                                <TextField disabled label={f("tables.versions.software")} multiline input={input} meta={meta} type="text" />
                            )}
                        </Field>
                        <Field name="min_version_supported" validate={required}>
                            {({ input, meta }) => (
                                <TextField label={f("tables.versions.min_version")} input={input} meta={meta} type="text" />
                            )}
                        </Field>
                        {requestError ? <Error>{f(`versions.error.${requestError}`)}</Error> : ''}
                        <DivButton className="buttons">
                            <ButtonCreate color="primary" type="submit" disabled={submitting}>
                                <FormattedMessage id="global.save" />
                            </ButtonCreate>
                        </DivButton>
                    </form>
                )}
            />
        </Wrapper>
    )
}