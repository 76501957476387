import { combineReducers } from 'redux'

import { authReducer } from 'ducks/modules/auth'
import { specializationsReducer } from 'ducks/modules/specializations'
import { doctorsReducer } from 'ducks/modules/doctors'
import { paginationReducer } from 'ducks/modules/pagination'
import { reviewsReducer } from 'ducks/modules/reviews'
import { consultationsReducer } from 'ducks/modules/consultations'
import { AllConsultationsReducer } from 'ducks/modules/AllConsultations'
import { calendarReducer } from 'ducks/modules/calendar'
import { chatsReducer } from 'ducks/modules/chats'
import { statisticsReducer } from 'ducks/modules/statistics'
import { gdprReducer } from 'ducks/modules/gdpr'
import { localizationReducer } from 'ducks/modules/localization'
import { profileReducer } from 'ducks/modules/profile'
import { smsLogsReducer } from 'ducks/modules/smslogs'
import { specializationReducer } from 'ducks/modules/specialization'
import { skillsReducer } from 'ducks/modules/skills';
import { menuReducers } from "ducks/modules/menu";
import {TeamsReducer} from "ducks/modules/teams";
import { connectedReducer } from 'ducks/modules/token';
import {structuresReducer} from "./modules/structures";
import {structuresMembersReducer} from './modules/structuresMembers'
import {versionsReducer} from "./modules/versions";

export const reducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  statistics: statisticsReducer,
  specializations: specializationsReducer,
  calendar: calendarReducer,
  doctors: doctorsReducer,
  pagination: paginationReducer,
  reviews: reviewsReducer,
  consultations: consultationsReducer,
  AllConsultations: AllConsultationsReducer,
  chats: chatsReducer,
  gdpr: gdprReducer,
  localization: localizationReducer,
  smslogs: smsLogsReducer,
  specialization: specializationReducer,
  skills: skillsReducer,
  menu: menuReducers,
  connected : connectedReducer,
  structures : structuresReducer,
  structuresMembers : structuresMembersReducer,
  teams: TeamsReducer,
  versions: versionsReducer,
})
