import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import {
  DashboardGeneral,
  DashboardParams,
  DashboardPoints,
  StatisticSteps,
} from 'interfaces/dashboard'

//#region Actions
export enum StatisticsActions {
  fetchStatisticsRequest = 'FETCH_STATISTICS_REQUEST',
  fetchStatisticsSuccess = 'FETCH_STATISTICS_SUCCESS',
  fetchStatisticsFailure = 'FETCH_STATISTICS_FAILURE',

  fetchDashboardRequest = 'FETCH_DASHBOARD_REQUEST',
  fetchDashboardSuccess = 'FETCH_DASHBOARD_SUCCESS',
  fetchDashboardFailure = 'FETCH_DASHBOARD_FAILURE',

  changeParams = 'CHANGE_STATISTIC_PARAMS',
}

export const fetchStatisticsRequest = createAction(StatisticsActions.fetchStatisticsRequest)
export const fetchStatisticsSuccess = createAction(StatisticsActions.fetchStatisticsSuccess)
export const fetchStatisticsFailure = createAction(StatisticsActions.fetchStatisticsFailure)

export const fetchDashboardRequest = createAction(StatisticsActions.fetchDashboardRequest)
export const fetchDashboardSuccess = createAction(StatisticsActions.fetchDashboardSuccess)
export const fetchDashboardFailure = createAction(StatisticsActions.fetchDashboardFailure)

export const changeParams = createAction(StatisticsActions.changeParams)
//#endregion

//#region Reducer
const general = handleActions({
  [StatisticsActions.fetchStatisticsSuccess]: (state, action) => action.payload,
}, {
  effectiveness: '',
  totalPatients: 0,
  totalDoctors: 0,
})

const params = handleActions({
  [StatisticsActions.changeParams]: (state, action) => action.payload,
}, {
  dateFrom: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  dateTo: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  step: StatisticSteps.day,
})

const total = handleActions<DashboardGeneral>({
  [StatisticsActions.fetchDashboardSuccess]: (
    state,
    action: AnyAction,
  ) => action.payload.general_statistics,
}, {
  scheduled: 0,
  urgent: 0,
  total_consultation: 0,
  total_cost: 0,
  scheduled_cost: 0,
  urgent_cost: 0,
  telexpertise: 0,
  telexpertise_cost: 0,
  urgent_telexpertise: 0,
  urgent_telexpertise_cost: 0,
})

const points = handleActions<DashboardPoints>({
  [StatisticsActions.fetchDashboardSuccess]: (
    state,
    action: AnyAction,
  ) => action.payload.dashboard,
}, {})

const chart = combineReducers({
  total,
  points,
})

export const statisticsReducer = combineReducers({
  general,
  params,
  chart,
})
//#endregion

//#region Thunks
type GeneralPayload = {
  effectiveness: string,
  total_patients: number,
  total_doctors: number,
}

export const fetchStatistics = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchStatisticsRequest())

  return axios('/admin/statistic/general')
    .then(response => {
      const generalData: GeneralPayload = response.data.data.attributes
      dispatch(fetchStatisticsSuccess({
        effectiveness: generalData.effectiveness,
        totalPatients: generalData.total_patients,
        totalDoctors: generalData.total_doctors,
      }))
    })
    .catch((err) => {
      dispatch(fetchStatisticsFailure(err))
    })
}

export const fetchDashboard = (
  filters: DashboardParams,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchDashboardRequest())

  return axios('/admin/statistic/dashboard', { params: filters })
    .then(response => {
      dispatch(fetchDashboardSuccess(response.data.data.attributes))
    })
    .catch((err) => {
      dispatch(fetchDashboardFailure(err))
    })
}
//#endregion
