import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom'
import axios from 'axios'

import { BoardLayout } from 'components/pages/Board/Layout'
import { Routes } from 'constants/routes'
import { Doctors } from 'components/pages/Doctors'
import { Doctor } from 'components/pages/Doctor'
import { useDispatch } from 'react-redux'
import { fetchSpecializations } from 'ducks/modules/specializations'
import { socket } from 'services/socket'
import { Dashboard } from 'components/pages/Dashboard'
import { handleMessage } from 'ducks/modules/chats'
import { logout } from 'ducks/modules/auth'
import { fetchProfile } from 'ducks/modules/profile'
import { AllConsultations } from 'components/pages/Consultations'
import { ROSOPageList } from 'components/pages/Roso';
import { NewTeam } from 'components/pages/Roso/AddTeam/NewTeam';
import { ManageTeam } from 'components/pages/Roso/ManageTeam';
import { SmsLogsList } from 'components/pages/SmsLogs/SmsLogsList';
import { DetailedSmsLogs } from 'components/pages/SmsLogs/DetailedSmsLogs';
import { StructuresList } from 'components/pages/Structures';
import { EditStructure } from 'components/pages/Structures/EditStructure';
import { ViewStructure } from 'components/pages/Structures/ViewStructure';
import {
  CreateGlobalNotification,
} from 'components/pages/Content/GlobalNotifications/CreateGlobalNotification';
import { SpecializationsList } from 'components/pages/Specializations/SpecializationsList';
import { SpecializationsDetails } from 'components/pages/Specializations/SpecializationsDetails';
import { SkillsList } from 'components/pages/Skills';
import { EditSkill } from 'components/pages/Skills/EditSkill';
import { CreateSkill} from "../Skills/CreateSkill";
import {GeolocationList} from "../Geolocation";
import {Notifications} from "../Notifications";
import {StructuresMembersList} from "../Structures/Members";
import {MobileVersion} from "../MobileVersion";
import {EditVersion} from "../MobileVersion/EditVersion";

interface BoardProps {
  isAuthenticated: boolean,
}

export const Board: React.FC<BoardProps> = ({ isAuthenticated }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  axios.interceptors.response.use(response => response, err => {
    if (err.response.status === 401 || err.response.statusText === 'Unauthorized') {
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('auth')
      dispatch(logout())
      history.push(Routes.auth)
    }
    return Promise.reject(err)
  })

  useEffect(() => {
    const token = localStorage.getItem('auth')
    socket.onclose = () => socket.reconnect(token)
    socket.onerror = () => socket.reconnect(token)
    socket.onopen = () => socket.authorize(token)
    socket.onmessage = (message: any) => dispatch(handleMessage(JSON.parse(message.data)))
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchProfile())
    dispatch(fetchSpecializations())
  }, [dispatch])

  return (
    <BoardLayout>
      <Switch>
        {!isAuthenticated && <Redirect to={Routes.auth} />}
        <Route path={Routes.doctors} component={Doctors} exact />
        <Route path={Routes.dashboard} component={Dashboard} />
        <Route path={`${Routes.doctors}/:id`} component={Doctor} />
        <Route path={Routes.consultations} component={AllConsultations} />
        <Route path={`${Routes.roso}/:id`} component={ManageTeam} />
        <Route path={Routes.roso} component={ROSOPageList} />
        <Route path={`${Routes.team}/new`} component={NewTeam} />
        <Route path={`${Routes.team}/:id`} component={NewTeam} />
        <Route path={`${Routes.smslogs}/:id`} component={DetailedSmsLogs} />
        <Route path={Routes.smslogs} component={SmsLogsList} />
        <Route path={`${Routes.globalNotifications}/new`} component={CreateGlobalNotification} />
        <Route path={`${Routes.structures}/new`} component={EditStructure} />
        <Route path={`${Routes.structures}/members/:id`} component={StructuresMembersList} />
        <Route path={`${Routes.structures}/:id`} component={EditStructure} />
        <Route path={`${Routes.structure}/:id`} component={ViewStructure} />
        <Route path={`${Routes.specializations}/new`} component={SpecializationsDetails} />
        <Route path={`${Routes.specializations}/:id`} component={SpecializationsDetails} />
        <Route path={`${Routes.skills}/new`} component={CreateSkill} />
        <Route path={`${Routes.skills}/:id`} component={EditSkill} />
        <Route path={Routes.skills} component={SkillsList} />
        <Route path={Routes.structures} component={StructuresList} />
        <Route path={Routes.specializations} component={SpecializationsList} />
        <Route path={Routes.notification} component={Notifications} />
        <Route path={Routes.geolocation} component={GeolocationList} />
        <Route path={Routes.mobile_version} component={MobileVersion} exact />
        <Route path={`${Routes.mobile_version}/:software`} component={EditVersion} />
        <Redirect from='/' to={Routes.dashboard} exact />
      </Switch>
    </BoardLayout>
  )
}
