import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Global } from '@emotion/react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl'

import { globalStyles } from 'constants/globalStyles'
import { Routes } from 'constants/routes'
import { Auth } from 'components/pages/Auth'
import { Colors } from 'constants/colors'
import { Board } from 'components/pages/Board'
import { en } from 'locales/en'
import { fr } from 'locales/fr'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'interfaces'
import {loginSuccess, logout} from 'ducks/modules/auth'
import {getInfoFromToken} from "ducks/modules/token";
import axios from 'axios'
import {parseJwt} from "../../helpers/token";

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.blue,
    },
    secondary: {
      main: Colors.green,
      contrastText: Colors.white,
    },
    success: {
      main: Colors.green,
    },
    error: {
      main: Colors.red,
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      fontWeight: 700,
    },
  },
})

const messages: any = {
  en,
  fr,
}

export const App: React.FC = () => {
  const isAuthenticated = useSelector<State, boolean>(state => !!state.auth)
  const locale = useSelector<State, string>(state => state.localization)
  const token = localStorage.getItem('auth')
  const dispatch = useDispatch()

  if(token){
    dispatch(getInfoFromToken(token));
  }
  useEffect(() => {
    if (token) {
      const roles = parseJwt(token).roles;
      if(roles.includes('ROLE_MODERATOR')){
        dispatch(loginSuccess(token))
      }
      else{
        localStorage.removeItem('auth')
        dispatch(logout())
      }
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }, [token, dispatch])

  if (token && !isAuthenticated) {
    return null
  }


  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider theme={{ palette: theme.palette }}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <BrowserRouter>
            <Switch>
              <Route path={Routes.auth}>
                {isAuthenticated && <Redirect to={Routes.doctors} />}
                <Auth />
              </Route>
              <Route path={Routes.root}>
                {!isAuthenticated && <Redirect to={Routes.auth} />}
                <Board isAuthenticated={isAuthenticated} />
              </Route>
            </Switch>
          </BrowserRouter>
          <Global styles={globalStyles} />
        </IntlProvider>
      </ThemeProvider>
    </ThemeProvider>
  )
}
