import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Content } from 'components/common/Content'
import { doctorSelector } from 'selectors/doctor'
import { State } from 'interfaces'
import { Doctor as DoctorI } from 'interfaces/doctors'
import { fetchDoctor } from 'ducks/modules/doctors'
import { Preloader } from 'components/common/Preloader'
import { Tabs } from 'components/common/Tabs'
import { Consultations } from 'components/pages/Doctor/Consultations'
import { Profile } from 'components/pages/Doctor/Profile'
import { DoctorButtons } from 'components/pages/Doctor/Buttons'
import { Manage } from 'components/pages/Doctor/Manage'
import { ManageProfesionalData } from 'components/pages/Doctor/ManageProfesionalData'
import { setIsPrincipalPage } from "../../../ducks/modules/menu";

const tabs = ['doctors.profile', 'doctors.consultations']

export const Doctor: React.FC = () => {
    const [activeTab, onTabChange] = useState<number>(0)
    const [userWantingToModifyPersonalData, setUserWantingToModifyPersonalData] = useState<boolean>(false)
    const [userWantingToModifyAboutDoctorData, setUserWantingToModifyAboutDoctorData] = useState<boolean>(false)

    // @ts-ignore
    const { id } = useParams()
    const doctor = useSelector<State, DoctorI | undefined>(doctorSelector(id))
    const dispatch = useDispatch()

    useEffect(() => {
    dispatch(setIsPrincipalPage(false))
    }, )

    useEffect(() => {
    if (!doctor && id) {
      dispatch(fetchDoctor(id))
    }
    }, [id, doctor, dispatch])

    if (!doctor || !id) {
    return <Preloader />
    }

  return (
    <>
      <Tabs
        tabs={tabs}
        active={activeTab}
        onChange={onTabChange}
        actions={(
          <DoctorButtons
            doctorStatus={doctor.status}
            id={doctor.id}
          />
          )}
        setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData}
        setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData}
      />
      <Content>
        {(activeTab === 0 && !userWantingToModifyPersonalData && !userWantingToModifyAboutDoctorData) &&
          <Profile id={id}
                   setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData}
                   setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData}
          />}
        {/*{activeTab === 2 && <Reviews doctor={doctor} />}*/}
        {activeTab === 1 && <Consultations id={id} />}
        {/*{activeTab === 1 && <Dashboard doctorId={id} />}*/}
        {(activeTab === 0 && (userWantingToModifyPersonalData )) &&
          <Manage id={id} setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData} />}

        {(activeTab === 0 && userWantingToModifyAboutDoctorData ) &&
          <ManageProfesionalData id={id} setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData} />}
      </Content>
    </>
  )
}
