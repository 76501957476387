/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { AboutDoctor } from './AboutDoctor'
import { Education } from './Education'
// import { Courses } from './Courses'
import { AdditionalInformation } from './AdditionalInformation'
import { Diplomas } from './Diplomas'
import { PricesConsultations } from './PricesConsultations'
import { Signature } from './Signature'
import { DialogWindow } from '../DialogWindow'
import { UserInfo } from '../UserInfo'
import { LinkCard } from '../LinkCard'
import {RIB} from "./RIB";

const Wrapper = styled.div`
  padding-bottom: 30px;

  & > div {
    margin-bottom: 16px;
  }
`

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`

export const ProfileInfo = ({
  attributes: {
    additional_info,
    date_of_birth,
    email,
    team_name,
    addition_email,
    country,
    experience,
    first_name,
    gender,
    language,
    last_name,
    specialization_type,
    photo,
    //rating,
    price,
    requisites,
    credentials,
    //phoneNumber,
    phone_number,
    specialization_id,
    specialization_key,
    psc_registrated,
    validator,
    status_availability,
    importer,
    last_connection
  },
  workPlace,
  setUserWantingToModifyPersonalData,
  setUserWantingToModifyAboutDoctorData,
  education,
  // qualificationCourses,
  diplomas,
  verified,
  dialogWindow,
  changeButtonPaths,
  addButtonPaths,
  onDiplomasClick,
  onAppointmentButtonClick,
  reviewsCount,
  reviewsLink,
  signatures,
  isPatient,
  methodField,
  doctorId,
  structures,
    teams,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper>
      {
        dialogWindow && (
          <DialogWindow
            title='Your Data sent'
            content='Reply will be sent within 72 hours.'
            cacelButtonText='ok'
            open={!verified}
            closeButtonText='fermer'
            onClose={null}
          />
        )
      }
      <UserInfo
        email={email}
        dateOfBirth={date_of_birth}
        firstName={first_name}
        lastName={last_name}
        specializationType={specialization_type}
        photo={photo}
        gender={gender}
        changeButtonPath={verified ? 'changeButtonPaths.personal_data' : ''}
        heading='profile.personal'
        onAppointmentButtonClick={onAppointmentButtonClick}
        phoneNumber={phone_number}
        validator={validator}
        status_availability={status_availability}
        doctorId={doctorId}
        setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData}
        isPscRegistrated={psc_registrated}
        lastConnection={last_connection}
        importer={importer}
      />
{/*      {!isPatient && (
        <LinkCardStyled
          label='profile.reviews'
          to={reviewsLink}
          length={reviewsCount}
          hideArrow
        />
      )}*/}
      <AboutDoctor
        specialization_id={specialization_id}
        specialization_key={specialization_key}
        doctorId={doctorId}
        gender={gender}
        //medical_degree={medical_degree}
        phoneNumber={phone_number}
        setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData}
        experience={experience}
        country={country}
        team_name={team_name}
        addition_email={addition_email}
        language={language}
        work_place={workPlace}
        changeButtonPath={verified ? 'changeButtonPaths.about_doctor' : ''}
        credentials={credentials}
        structures={structures}
        teams={teams}
      />
      <Diplomas
          onPaperClick={onDiplomasClick}
          diplomas={diplomas}
          changeButtonPath={verified ? changeButtonPaths.diplomas : ''}
      />
      <RIB
          bic={requisites.private.bic}
          iban={requisites.private.iban}
      />
     {/* <Education
        education={education}
        addButtonPath={verified ? addButtonPaths.education : ''}
      />*/}
{/*      <Courses
        courses={qualificationCourses}
        addButtonPath={verified ? addButtonPaths.courses : ''}
      />*/}
      {/*<AdditionalInformation
        additional_information={additional_info}
        changeButtonPath={verified ? changeButtonPaths.additional_information : ''}
      />*/}
      {/*{!!signatures && !!signatures.length && (
        <Signature
          signatures={signatures}
          changeButtonPath={verified ? changeButtonPaths.signature : ''}
        />
      )}*/}
      {/*{!!price && (
        <PricesConsultations
          requisites={requisites}
          price={price}
          changeButtonPath={verified ? changeButtonPaths.prices : ''}
          methodField={methodField}
        />
      )}*/}
    </Wrapper>
  )
}

ProfileInfo.defaultProps = {
  changeButtonPaths: {},
  addButtonPaths: {},
  dialogWindow: false,
  verified: false,
  onAppointmentButtonClick: false,
}

ProfileInfo.propTypes = {
  attributes: PropTypes.shape({
    date_of_birth: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    photo: PropTypes.string,
    medical_degree: PropTypes.arrayOf(PropTypes.string),
    experience: PropTypes.number.isRequired,
    language: PropTypes.arrayOf(PropTypes.string),
    country: PropTypes.string,
    team_name: PropTypes.string,
    addition_email: PropTypes.arrayOf(PropTypes.any),
    email: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    validator: PropTypes.objectOf(PropTypes.any),
    status_availability: PropTypes.bool.isRequired
  }).isRequired,
  //workPlace: PropTypes.object.isRequired,
  education: PropTypes.arrayOf(PropTypes.object).isRequired,
  qualificationCourses: PropTypes.arrayOf(PropTypes.object),
  diplomas: PropTypes.arrayOf(PropTypes.object),
  verified: PropTypes.bool,
  //changeButtonPaths: PropTypes.object,
  addButtonPaths: PropTypes.objectOf(PropTypes.string),
  onDiplomasClick: PropTypes.func,
  dialogWindow: PropTypes.bool,
  onAppointmentButtonClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  reviewsLink: PropTypes.string,
  reviewsCount: PropTypes.number,
}
/* eslint-disable */