/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useRef, useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import {
  add,
  differenceInDays,
  format,
  sub,
} from 'date-fns'
import Popover from '@material-ui/core/Popover'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'
import IconButton from '@material-ui/core/IconButton'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { FormattedMessage } from 'react-intl'
import { StatisticSteps } from 'interfaces/dashboard'

//#region Styles
const wrapperStyles = css`
  display: flex;
  align-items: center;
`

const valueStyles = css`
  color: ${Colors.grey};
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  cursor: pointer;
  display: flex;
  align-items: center;
  
  button {
    &:first-of-type {
      margin-right: 8px;
    }
    
    &:last-of-type {
      margin-left: 8px;
    }
  }
  
  span {
    line-height: 1.5;
  }
`

const buttonsStyles = css`
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  display: inline-block;
  margin: 0 12px;
  cursor: pointer;
  color: ${Colors.grey};
  border: none;
  background: none;
  outline: none;
  padding: 0;
  
  &:active, &:focus {
    color: ${Colors.blue};
  }
`
//#endregion

interface DateFilterProps {
  dateFrom: string,
  dateTo: string,
  onChange: (dateFrom: string, dateTo: string, step?: StatisticSteps) => void,
}

export const DateFilter: React.FC<DateFilterProps> = ({
  dateFrom,
  dateTo,
  onChange,
}) => {
  const [isOpened, toggle] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const startDate = new Date(dateFrom)
  const endDate = new Date(dateTo)

  const ranges = [{
    startDate,
    endDate,
    key: 'selection',
  }]

  const handleChange = (data: any) => onChange(
    format(data.selection.startDate, 'yyyy-MM-dd'),
    format(data.selection.endDate, 'yyyy-MM-dd'),
    StatisticSteps.day,
  )

  const navigate = (forward: boolean) => {
    const diff = differenceInDays(endDate, startDate)
    const func = forward ? add : sub

    const newFromDate = func(startDate, { days: diff })
    const newToDate = func(endDate, { days: diff })

    onChange(
      format(newFromDate, 'yyyy-MM-dd'),
      format(newToDate, 'yyyy-MM-dd'),
    )
  }

  const onLastButtonClick = (daysCount: number) => onChange(
    format(sub(new Date(), { days: daysCount }), 'yyyy-MM-dd'),
    format(new Date(), 'yyyy-MM-dd'),
    daysCount > 30 ? StatisticSteps.month : StatisticSteps.day,
  )

  return (
    <div css={wrapperStyles}>
      <div ref={ref} css={valueStyles}>
        <IconButton size='small' onClick={() => navigate(false)}>
          <NavigateBeforeIcon />
        </IconButton>
        <span onClick={() => toggle(!isOpened)}>
          {format(startDate, 'yyyy-MM-dd')}
          {' - '}
          {format(endDate, 'yyyy-MM-dd')}
        </span>
        <IconButton size='small' onClick={() => navigate(true)}>
          <NavigateNextIcon />
        </IconButton>
      </div>
      <button css={buttonsStyles} type='button' onClick={() => onLastButtonClick(6)}>
        <FormattedMessage id='statistics.last.week' />
      </button>
      <button css={buttonsStyles} type='button' onClick={() => onLastButtonClick(30)}>
        <FormattedMessage id='statistics.last.month' />
      </button>
      <button css={buttonsStyles} type='button' onClick={() => onLastButtonClick(364)}>
        <FormattedMessage id='statistics.last.year' />
      </button>
      <Popover
        open={isOpened}
        onClose={() => toggle(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRange
          ranges={ranges}
          onChange={handleChange}
        />
      </Popover>
    </div>
  )
}
