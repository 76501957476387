import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import Add from '@material-ui/icons/Add'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../../../constants/colors.ts'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
`

const AddField = styled.div`
  height: 130px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.clearBlue};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 31px;
    padding: 60px 0 4px;
  }
`

const HelperText = styled.p`
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  color: ${Colors.blueGrey};
`

export const Upload = ({ onDrop, acceptedExtensions }) => (
  <Dropzone onDrop={onDrop} accept={acceptedExtensions}>
    {
      ({ getRootProps, getInputProps }) => (
        <Wrapper
          {...getRootProps()}
        >
          <AddField>
            <Add />
            <br />
            <FormattedMessage id='global.add' />
          </AddField>
          <HelperText>
            <FormattedMessage id='global.format' />
            {' '}
            (
            {acceptedExtensions.split(',').join(' ')}
            )
            <br />
            <FormattedMessage id='global.size' />
            {' '}
            (max 10 mb)
          </HelperText>
          <input {...getInputProps()} />
        </Wrapper>
      )
    }
  </Dropzone>
)

Upload.defaultProps = {
  acceptedExtensions: '.jpg,.png',
}

Upload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedExtensions: PropTypes.string,
}
