import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { Specialization, SpecializationPayload } from 'interfaces/doctors'
//import { normalizeSpecialization } from 'helpers/doctors'
import { Pagination, ThunkResult } from 'interfaces'
import { AnyAction, combineReducers } from 'redux'
import { SpecializationFilters } from 'interfaces/specialization';
import {DoctorsActions} from "../doctors";

//#region Actions
export enum SpecActions {
  fetchSpecRequest = 'FETCH_SPECIALIZATIONS_LIST_REQUEST',
  fetchSpecSuccess = 'FETCH_SPECIALIZATIONS_LIST_SUCCESS',
  fetchSpecFailure = 'FETCH_SPECIALIZATIONS_LIST_FAILURE',

  fetchSpecializationRequest = 'FETCH_SPECIALIZATION_REQUEST',
  fetchSpecializationSuccess = 'FETCH_SPECIALIZATION_SUCCESS',
  fetchSpecializationFailure = 'FETCH_SPECIALIZATION_FAILURE',

  setSpecializationFilters = 'SET_SPECIALIZATION_FILTER',
}

export const fetchSpecRequest = createAction(SpecActions.fetchSpecRequest)
export const fetchSpecSuccess = createAction(SpecActions.fetchSpecSuccess)
export const fetchSpecFailure = createAction(SpecActions.fetchSpecFailure)

export const fetchSpecializationRequest = createAction(SpecActions.fetchSpecializationRequest)
export const fetchSpecializationSuccess = createAction(SpecActions.fetchSpecializationSuccess)
export const fetchSpecializationFailure = createAction(SpecActions.fetchSpecializationFailure)

export const setSpecializationFilters = createAction(SpecActions.setSpecializationFilters)
//#endregion

//#region Reducer
const list = handleActions({
  [SpecActions.fetchSpecSuccess]: 
  (state, action: AnyAction) => action.payload.specializations,
}, [])

const byId = handleActions({
  [SpecActions.fetchSpecializationSuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
}, {})

const filters = handleActions({
  [SpecActions.setSpecializationFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "key", sortOrder: "ASC" })

export const specializationReducer = combineReducers({
  list,
  byId,
  filters
})
//#endregion

//#region Thunks

export const fetchSpec = (
  params: SpecializationFilters,
): ThunkResult<Promise<void | Pagination>> => (
  dispatch,
) => {
  dispatch(fetchSpecRequest())

  type Response = { paginator: Pagination, data: SpecializationPayload[] }

  return axios.request<Response>({
    url: '/admin/doctor/specialization',
    params: {
      ...params,
    }
  })
    .then(response => {
      const specializations: SpecializationPayload[] = response.data.data
      dispatch(fetchSpecSuccess({
        specializations: specializations.map(specialization => specialization),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      dispatch(fetchSpecFailure(err))
    })
}

export const fetchSpecialization = (
  id: number | string,
): ThunkResult<Promise<void | Specialization>> => (
  dispatch,
) => {
  dispatch(fetchSpecializationRequest())

  type Response = { data: SpecializationPayload }

  return axios.request<Response>({
    url: `/general/specialization/${id}`,
  })
    .then(response => {
      dispatch(fetchSpecializationSuccess(response.data.data))
    })
    .catch((err) => {
      dispatch(fetchSpecializationFailure(err))
    })
}

//#endregion
