/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { css } from '@emotion/react'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import PeopleAltIcon from '@material-ui/icons/People'
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { FormattedMessage } from 'react-intl'
import { getStatus, approveTeam, rejectTeam } from '../../../../ducks/modules/teams';

import CheckIcon from '@material-ui/icons/Check';

const PaperStyle = css`
  padding: 15px;
  .MuiButton-root {
    padding: 6px 10px;
    font-size: 0.8rem;
    min-width: 64px;
    
}
`
const RejectedIcon = css`
  font-size: 12px !important;
`

const StatusStyles = css`
  text-alight: left;
`

const StatusHolder = css` 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 40px;
`

const StatusText = css`
  font-size: 14px;
`
const ModerationIcon = css`
  font-size: 12px !important;
`


const JoinButton = css`
  && {
    border-radius: 30px;
    margin-left: 10px;
  }
`

const EqualHeightGrid = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Members = css`
  margin-top: 10px;
  color: #959fb8;
  font-size: 13px;
  font-weight: 100;
`

interface ColorStatuses {
  [key: string]: string
}

const colorStatuses: ColorStatuses = {
  verified: 'green',
  moderated: '#f5a623',
  rejected: 'red',
}

interface ButtonProps {
  teamId: number,
  membersCount: number,
  teamStatus: string,
  refreshMyTeams: () => void
}

const getIconStatus = (textStatus: string) => {
  if (textStatus === 'moderated') {
    return <WatchLaterRoundedIcon css={ModerationIcon} />
  }
  if (textStatus === 'rejected') {
    return <HighlightOffRoundedIcon css={RejectedIcon} />
  }
  return <CheckIcon />
}

export const ButtonBlock = (
  { 
    teamId, membersCount, refreshMyTeams, teamStatus, 
  }: ButtonProps,
) => {
  const textStatus = getStatus(teamStatus);
  return (
    <div css={PaperStyle}>
      <Grid container>
        <Grid item xs={4}>
          <div css={Members}>
            <PeopleAltIcon /> 
            {' '}
            <br />
            <FormattedMessage id="roso.members" /> 
            {' '}
            {membersCount}
          </div>
        </Grid>
        <Grid css={{ ...EqualHeightGrid, ...StatusStyles }} item xs={8}>
          <div css={StatusHolder} style={{ color: colorStatuses[textStatus] }}>
            {getIconStatus(textStatus)}
            <div css={StatusText}><FormattedMessage id={`roso.status.${textStatus}`} /></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button css={JoinButton} onClick={() => approveTeam({ teamId }).then(() => refreshMyTeams())} color='primary' variant='contained'>
              <FormattedMessage id="roso.status.verify" />
            </Button>
            <Button css={JoinButton} onClick={() => rejectTeam({ teamId }).then(() => refreshMyTeams())} color='default' variant='contained'>
              <FormattedMessage id="roso.status.reject" />
            </Button>
          </div>
         
        </Grid>
      </Grid>
    </div>
  )
}
