/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

//#region Styles
const wrapperStyles = css`
  && {
    padding: 22px;
    width: 32%;
    box-sizing: border-box;
    height: 160px;
    display: flex;
    align-items: center;
    position: relative;
  }
`

const valueStyles = css`
  color: ${Colors.blue};
  font-size: 36px;
  font-weight: 600;
`
const labelStyles = css`
  color: ${Colors.grey};
  font-size: 24px;
  font-weight: 600;
`

const imgStyles = css`
  position: absolute;
  bottom: 0;
  right: 0;
`
//#endregion

interface CardProps {
  value: number | string,
  label: string,
  image: string,
}

export const GeneralStatisticsCard: React.FC<CardProps> = ({
  value,
  label,
  image,
}) => (
  <Paper css={wrapperStyles} elevation={4}>
    <div>
      <div css={valueStyles}>
        {value}
      </div>
      <div css={labelStyles}>
        <FormattedMessage id={label} />
      </div>
    </div>
    <img css={imgStyles} src={image} alt='' />
  </Paper>
)
