export const RATING_FILTERS = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 3,
    name: 3,
  },
  {
    id: 4,
    name: 4,
  },
  {
    id: 5,
    name: 5,
  },
]

export enum ConsultationTypes {
  scheduled = 'scheduled',
  urgent = 'urgent',
}
export enum AllConsultationTypes {
  TE = 'TE',
  TC = 'TC',
}

export enum ConsultationStatuses {
  created = 'created',
  confirmed = 'confirmed',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum DoctorStatuses {
  verified = 'VERIFIED',
  notVerified = 'NOT_VERIFIED',
  limited = 'LIMITED',
  notVerified_without_space = 'NOT VERIFIED',
  preRegistered = 'PRE_REGISTRATED'
}

export const TABS = ['doctors.verified', 'doctors.applications', 'doctors.preregistered']

export const TABSGEOLOC = ['geoloc.user', 'geoloc.structure']

