/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useState } from 'react';
import styled from '@emotion/styled'
import {Colors} from "../../../../constants/colors";
import Add from '@mui/icons-material/Add'
import { FormattedMessage } from 'react-intl'
import {Upload} from "./Upload";

const Wrapper = styled.div`
  width: 25%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.20);
  margin-bottom: 10px;
`

const AddField = styled.div`
  min-height: 130px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 31px;
    padding: 60px 0 4px;
  }
`

const HelperText = styled.p`
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
`

const PhotoWrapper = styled.div`
  background-size: 100% 100%;
  width: 126px;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.lightGrey};
  border-radius: 50%;
  border: 7px solid white;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  cursor: pointer;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Photo = styled.div`
  background: url(${props => props.photo})  center no-repeat;
  background-color: #FFFFFF;
  border-radius: 50%;
  background-size: cover;
  width: ${props => (props.empty ? '128px' : '100%')};
  height: ${props => (props.empty ? '128px' : '100%')};
`

const getPreview = (value) => {
    if (value && typeof value === 'string') {
        return value
    }
    if (value) {
        return URL.createObjectURL(value)
    }
    return null
}

export const UploadPhoto = ({ input, label }) => {
    const [showDialog, setShowDialog] = useState(false)
    return(
        <>
            <Wrapper onClick={() => setShowDialog(true)}>
                {input.value ?
                <PhotoWrapper>
                    <Photo photo={ getPreview(input.value)} empty={!input.value} />
                </PhotoWrapper>
                :
                    <>
                <AddField>
                    <Add />
                    <br />
                    <FormattedMessage id={label || 'Ajouter logo'} />
                </AddField>
                <HelperText>
                    <br />
                    <FormattedMessage id='Taille max 500MB' />
                </HelperText>
                    </>
                }
            </Wrapper>
            <Upload close={() => setShowDialog(false)} isOpen={showDialog} input={input}/>
        </>
    )
}