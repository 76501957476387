import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDoctorProfile } from 'ducks/modules/doctors'
import { profileSelector } from 'selectors/doctor'
import { State } from 'interfaces'
import { Preloader } from 'components/common/Preloader'
import axios from "axios";
//import { ProfileInfo } from '../../../common/UiKit/ProfileInfo'
const ProfileInfo:any = require('../../../common/UiKit/ProfileInfo')

interface ProfileProps {
  id: number | string,
}

export const Profile: React.FC<ProfileProps> = ({ id, setUserWantingToModifyPersonalData, setUserWantingToModifyAboutDoctorData}) => {
  const dispatch = useDispatch()
  const profile = useSelector<State, any>(profileSelector(id))
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    dispatch(fetchDoctorProfile(id))

    const fetchDoctorTeams = async () => {
      const token = localStorage.getItem('auth')
      await axios.get(
          `/api/doctor/team/list/${id}`,
          { headers: { Authorization: "Bearer " + token }}
      )
          .then(res => {
            const result = res.data.data;
              setTeams(result);
          })
         .catch(err => console.error(err))
    }

    fetchDoctorTeams().catch(console.error);
  }, [id, dispatch])

  if (!profile) {
    return <Preloader />
  }

  const workplace = profile.relationships.workPlace.data.length
    ? profile.relationships.workPlace.data[0].attributes
    : null

  return (
    <div>
      <ProfileInfo.ProfileInfo
        doctorId={id}
        setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData}
        setUserWantingToModifyAboutDoctorData={setUserWantingToModifyAboutDoctorData}
        attributes={profile.attributes}
        workPlace={workplace}
        education={[]}
        diplomas={profile.relationships.diploma.data}
        structures={profile.relationships.structures.data}
        teams={teams}
        reviewsCount={0}
      />
    </div>
  )
}
