import { createAction, handleActions } from 'redux-actions'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'

import { LoginValues } from 'interfaces/auth'
import {parseJwt} from "../../../helpers/token";

//#region Actions
export enum AuthActions {
  loginRequest = 'AUTH > LOGIN_REQUEST',
  loginSuccess = 'AUTH > LOGIN_SUCCESS',
  loginFailure = 'AUTH > LOGIN_FAILURE',

  logout = 'AUTH_LOGOUT',
}

export const loginRequest = createAction(AuthActions.loginRequest)
export const loginSuccess = createAction(AuthActions.loginSuccess)
export const loginFailure = createAction(AuthActions.loginFailure)

export const logout = createAction(AuthActions.logout)
//#endregion

//#region Reducer
export const authReducer = handleActions({
  [AuthActions.loginSuccess]: (state, action) => action.payload,
  [AuthActions.logout]: () => '',
}, '')
//#endregion

//#region Thunks
export const login = (
  values: LoginValues,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(loginRequest(values))

  try {
    const { data } = await axios('/moderator/authorization', { method: 'POST', data: values })
    const { token } = data.data.attributes
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('auth', token)
    const roles = parseJwt(token).roles;
    if(roles.includes("ROLE_MODERATOR")){
      dispatch(loginSuccess(token))
    }
    else{
      dispatch(loginFailure("Non modérateur"))
      return {
        email: 'Moderator access required',
      }
    }

  } catch (err) {
    dispatch(loginFailure(err))

    return {
      email: 'Invalid email or password',
    }
  }

  return undefined
}
//#endregion
