import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import axios from 'axios'

import { configureStore } from 'ducks/configureStore'
import { App } from 'components/App'
import * as serviceWorker from 'serviceWorker'

const store = configureStore()
axios.defaults.baseURL = window.env.API_URL

ReactDOM.render((
  <Provider store={store}>
    <App />
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
