/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Logo } from 'components/common/Logo'
import { Colors } from 'constants/colors'
import { menuItems } from 'constants/menuItems'

const wrapperStyles = css`
  width: 380px;
  height: 100vh;

  && > div {
    width: 380px;
    border-right: none;
    background-color: ${Colors.menu};
  }
`

const headerStyles = css`
  background-color: ${Colors.blueDark};
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;
`

const itemStyles = css`
  width: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: ${Colors.menuDisabled};
  font-size: 14px;
  padding: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  
  &.active {
    color: ${Colors.white};
    font-weight: 600;
    background-color: ${Colors.menuActive};
  }
  
  svg {
    margin-right: 12px;
  }
  
  &:hover:not(.active) {
    background-color: ${Colors.menuHover};
  }
`

const menuStyles = css`
  margin-top: 16px;
  padding-left: 24px;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
`

export const Menu = () => (
  <Drawer variant='permanent' css={wrapperStyles}>
    <div key='Main logo' css={headerStyles}>
      <Logo key='logo' small />
    </div>
    <div key='Main menu' css={menuStyles}>
      {
        menuItems.map(({ to, label, Icon }) => (
          <NavLink
            key={`${label}_navlink`}
            css={itemStyles}
            to={to}
            activeClassName='active'
          >
            <Icon key={`${label}_icon`} />
            <FormattedMessage key={`${label}_message`} id={label} />
          </NavLink>
        ))
      }
    </div>
  </Drawer>
)
