import React from 'react'
import { withState } from 'recompose'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { Colors } from '../../../../constants/colors.ts'

const TextFieldStyled = styled(({ InputProps, ...rest }) => (
  <TextField
    {...rest}
    InputProps={{
      ...InputProps,
      classes: {
        root: 'root',
        multiline: 'multiline',
        underline: 'underline',
      },
    }}
    InputLabelProps={{
      classes: {
        error: 'labelError',
        root: 'labelRoot',
        focused: 'labelFocused',
      },
    }}
    FormHelperTextProps={{ classes: { root: 'helperRoot' } }}
    SelectProps={{
      classes: { select: 'select' },
    }}
  />
))`
  && {
    border-radius: 4px;
    background-color: ${Colors.paleGrey60};
    width: 100%;
    margin-bottom: 24px;
  }
  
  & .root {
    height: 40px;
    padding: 6px 12px 7px;
    box-sizing: border-box;
    
    input {
      caret-color: ${Colors.clearBlue};
    }
    
    input[aria-invalid="true"] {
      caret-color: ${Colors.red};
    }
  }

  & .multiline {
    align-items: flex-start;
    line-height: 1.5;
  }

  & .labelRoot {
    color: ${Colors.blueGrey};
    margin: 0 12px;
  }

  & .labelFocused:first-child:not(.labelError) {
    color: ${Colors.clearBlue};
  }

  & .underline:after {
    border-color: ${Colors.clearBlue};
  }

  & .underline:before {
    border-color: ${Colors.grey};
  }

  & .helperRoot {
    width: 100%;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 0;
    background-color: white;
    ${props => props.maxLength
        && `
          text-align: right;
          color: ${Colors.steel};
        `}
  }

  .select:focus {
    background-color: transparent;
  }
`

const IconButtonStyled = styled(IconButton)`
  && {
    margin-bottom: 14px;
  }
`

const InputComponent = ({
  error,
  errorMessage,
  handleClickShowPassword,
  maxLength,
  options,
  password,
  showPassword,
  value,
  type,
  onClick,
  helperText,
  ...rest
}) => (
  <TextFieldStyled
    {...rest}
    error={error || !!errorMessage}
    helperText={maxLength ? `${value.length}/${maxLength}` : errorMessage || helperText}
    select={!!options}
    type={type || (showPassword || !password ? 'text' : 'password')}
    value={value}
    onClick={onClick}
    // Prop for styled-components
    maxLength={maxLength}
    // Prop for password visibility icon
    InputProps={
      !password || !value
        ? {}
        : {
          endAdornment: (
            <InputAdornment position='end'>
              <IconButtonStyled
                aria-label='Toggle password visibility'
                onClick={() => handleClickShowPassword(n => !n)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButtonStyled>
            </InputAdornment>
          ),
        }
    }
  >
    {
      !options
        ? null
        : options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))
    }
  </TextFieldStyled>
);

const enhance = withState('showPassword', 'handleClickShowPassword', false);

export const Input = enhance(InputComponent);

Input.defaultProps = {
  helperText: '',
}

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClickShowPassword: PropTypes.func,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      //value: PropTypes.any.isRequired,
    }),
  ),
  password: PropTypes.bool,
  showPassword: PropTypes.bool,
  //value: PropTypes.any.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  helperText: PropTypes.string,
}
