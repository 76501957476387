import global from './global.json'
import doctors from './doctors.json'
import tables from './tables.json'
import profile from './profile.json'
import medicines from './medicines.json'
import support from './support.json'
import statistics from './statistics.json'
import effectiveness from './effectiveness.json'
import gdpr from './gdpr.json'
import roso from './roso.json'
import content from './content.json'
import sms from './sms.json'
import structures from './structures.json'
import specialization from './specialization.json'
import skills from './skills.json'
import geoloc from './geoloc.json'
import notifications from './notifications.json'
import structureMembers from './structureMembers.json'
import versions from './versions.json'

export const fr = {
  ...global,
  ...doctors,
  ...tables,
  ...profile,
  ...medicines,
  ...support,
  ...statistics,
  ...effectiveness,
  ...gdpr,
  ...roso,
  ...content,
  ...structures,
  ...sms,
  ...specialization,
  ...skills,
  ...notifications,
  ...geoloc,
  ...structureMembers,
  ...versions,
}