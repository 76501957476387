import axios from 'axios';
import {createAction, handleActions} from "redux-actions";
import {Pagination, ThunkResult} from "../../../interfaces";
import {TeamPayload} from "../../../interfaces/team";
import { ThunkDispatch } from 'redux-thunk'
import {AnyAction, combineReducers} from 'redux'
import {SkillsActions} from "../skills";

interface StringArray {
    [index: string]: string;
}

const statuses: StringArray = {
  '-1': 'rejected',
  0: 'moderated',
  1: 'verified',
}

export enum TeamsActions {
  fetchTeamsSuccess = 'FETCH_TEAMS_SUCCESS',
  fetchTeamsFailure = 'FETCH_TEAMS_FAILURE',
  fetchTeamsRequest = 'FETCH_TEAMS_REQUEST',
  setTeamsFilters = 'SET_TEAMS_FILTERS',
}


export const fetchTeamsSuccess = createAction(TeamsActions.fetchTeamsSuccess)
export const fetchTeamsFailure = createAction(TeamsActions.fetchTeamsFailure)
export const fetchTeamsRequest = createAction(TeamsActions.fetchTeamsRequest)
export const setTeamsFilters = createAction(TeamsActions.setTeamsFilters)

const list = handleActions({
  [TeamsActions.fetchTeamsSuccess]: (state, action: AnyAction) => action.payload.teams,
}, [])

const filters = handleActions({
  [TeamsActions.setTeamsFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10 })


export interface Team {
  name: string;
  type: string;
  member_count: number;
}

export const normalizeTeam: (team: TeamPayload) => Team = team => ({
  type: team.attributes.type,
  name: team.attributes.name,
  member_count: team.attributes.member_count,
  specialization : team.relationships.specializations.formatted,
  id:team.id,
  status : team.attributes.status,
  creation_date:team.attributes.creation_date
})

export interface TeamFilter {
  page: number;
  name?: string;
  status?: number | string;
}

export const getAllDoctorTeams = (params: TeamFilter): ThunkResult<Promise<void | Pagination>> => ( dispatch) => {
  dispatch(fetchTeamsRequest())

  type Response = { paginator: Pagination, data: TeamPayload[] }

  return axios.request<Response>({
    url: '/admin/doctor/team',
    params: {
      ...params,
    },
  })
    .then(response => {
      const teams: TeamPayload[] = response.data.data
      teams.forEach(value => {
        if(value.relationships.specializations.data){
          value.relationships.specializations.formatted = '';
          value.relationships.specializations.data.forEach( specialization => {
            value.relationships.specializations.formatted += specialization.attributes.name +", "
              }
          )
          value.relationships.specializations.formatted = value.relationships.specializations.formatted.slice(0, value.relationships.specializations.formatted.length - 2);
        }
      })

      const paginator: Pagination = response.data.paginator
      dispatch(fetchTeamsSuccess({
        teams: teams.map(team => normalizeTeam(team)),
        pagination: paginator,
      }))
    })
    .catch((err) => {
      fetchTeamsFailure(err)
    })
}

export const getStatus = (statusCode: string | number) => {
  const statusName = statuses[(statusCode as string)]
  return statusName
}

export const getTeamById = async (id: number) => {
  const result = await axios(`/api/doctor/team/members?team_id=${id}`);
  return result.data.data;
}

export const sendInviteAdmin = async ({ team_id, member_id,id_doctor }: { team_id: number; member_id: string,id_doctor:number}) => {
  const result = await axios.post('/admin/doctor/team/invite', { team_id, member_id,id_doctor });
  return result.data.data;
}

export const approveTeam = async ({ teamId }: {teamId: number}) => {
  const result = await axios.post(`/admin/doctor/team/${teamId}/approve`);
  return result.data.data;
}

export const rejectTeam = async ({ teamId }: {teamId: number}) => {
  const result = await axios.post(`/admin/doctor/team/${teamId}/reject`);
  return result.data.data;
}

export const deleteMember = async ({ memberId }: {memberId: number}) => {
  const result = await axios.delete(`/admin/doctor/team/member/${memberId}`);
  return result.data.data;
}

export const getTeamByIds = async (id: number) => {
  const result = await axios(`/admin/doctor/team/${id}`);
  return result.data.data;
}

export const getTeamDiscussion = async (teamId) => {
  const result = await axios(`/admin/doctor/team/${teamId}/discussion`)
  return result.data.data;
}

export const createTeamDiscussion = async (teamId) => {
  const result = await axios.post(`/admin/doctor/team/discussion/create`, {
    teamId: teamId,
  })
  return result.data.data;
}
/*
export const getTeamById = async (id) => {
  const result = await axios(`/api/doctor/team/view/${id}`);
  return result.data.data;
}*/
export const TeamsReducer = combineReducers({
  list,
  filters
})
