export const HandleProfile = (profileDoctorAdminInfo, workPlace, isDROrPRGender) => {
    const profileAboutDoctor = {
        first_name: profileDoctorAdminInfo.first_name,
        last_name: profileDoctorAdminInfo.last_name,
        rpps: profileDoctorAdminInfo.credentials.rpps,
        orderNumber:profileDoctorAdminInfo.credentials.orderNumber,
        adeli:profileDoctorAdminInfo.credentials.adeli,
        worplaceName:workPlace.name,
        postcode: workPlace.postcode,
        street: workPlace.street,
        city: workPlace.city,
        country: workPlace.country !== '' ? workPlace.country : 'France',
        gender: isDROrPRGender ? (profileDoctorAdminInfo.gender == 'doctor' ? 'Dr' : 'Pr') : (profileDoctorAdminInfo.gender == 'male' ? 'Mr' : 'Mme')
    }

    return profileAboutDoctor;
}