import React, {useState, useCallback} from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import {Point} from "react-easy-crop/types";
import "./style.css"

const Crop = ({image, croppedAreaPixels, setCroppedAreaPixels}) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    return (
        <div className="Crop">
            <div className="crop-container">
                <Cropper
                    minZoom={0.1}
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    restrictPosition={false}
                />
            </div>
            <div className="controls">
                <Slider
                    value={zoom}
                    defaultValue={zoom}
                    min={0.1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Number(zoom))}
                    classes={{ root: "slider" }}
                />
            </div>
        </div>
    );
};

export default Crop;