import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'

const ProgressStyled = styled(CircularProgress)`
  && {
    color: white;
  }
`

export const TextButton = ({
  children,
  isFetching,
  disabled,
  ...rest
}) => (
  <Fab
    {...rest}
    disabled={isFetching || disabled}
    variant='extended'
  >
    {isFetching ? <ProgressStyled size={20} /> : children}
  </Fab>
)

TextButton.defaultProps = {
  isFetching: null,
}

TextButton.propTypes = {
  isFetching: PropTypes.bool,
}
