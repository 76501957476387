/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { css } from '@emotion/react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import MuiTextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from 'utils/styled'
import {theme} from "../../App";
import {useIntl} from "react-intl";

const styles = (theme: Theme) => css`
  && {
    margin-bottom: 12px;
    label.Mui-focused {
      color: ${theme.palette.primary.main};
    }
    
    input {
      font-size: 0.875rem;
      font-weight: 400;
    }
    
    .Mui-error fieldset {
      border-color: ${theme.palette.error.main};
    }
    
    label.Mui-focused.Mui-error  {
      color: ${theme.palette.error.main};
    }
    
    .Mui-error input {
      caret-color: ${theme.palette.error.main};
    }
    
    & *:focus {
      background-color: transparent;
    }
  }
`

const errorIconStyles = (theme: Theme) => css`
  && {
    color: ${theme.palette.error.main};
    cursor: pointer;
    padding-left: 8px;
  }
`

const errorTitleStyles = css`
  font-size: 0.875rem;
  padding: 6px;
`

const iconStyles = css`
  cursor: pointer;
  && {
    padding: 0;
  }
`

export const TextField: React.FC<FieldRenderProps<string>> = ({
  input: {
    name,
    onChange,
    value,
    type,
    ...restInput
  },
  meta,
  ...rest
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const showPassword = passwordVisibility ? 'text' : 'password'

  const onClick = () => setPasswordVisibility(state => !state)

  const showIcon = passwordVisibility ? (
    <IconButton
      css={iconStyles}
      onClick={onClick}
    >
      <VisibilityIcon />
    </IconButton>
  ) : (
    <IconButton
      css={iconStyles}
      onClick={onClick}
    >
      <VisibilityOffIcon />
    </IconButton>
  )

  const showError = (
    (meta.submitError && !meta.dirtySinceLastSubmit) || meta.error
  ) && meta.touched

  const defaultEndAdornment = type === 'password' ? showIcon : null

  const { formatMessage } = useIntl()

  return (
    <MuiTextField
      name={name}
      error={showError}
      onChange={onChange}
      value={value}
      fullWidth
      css={styles(theme)}
      color='secondary'
      variant='outlined'
      InputProps={{
        ...restInput,
        type: type === 'password' ? showPassword : type,
        color: 'primary',
        endAdornment: showError ? (
          <InputAdornment position='end'>
            <Tooltip
              title={(
                  <div css={errorTitleStyles}>{meta.error ? formatMessage({ id: meta.error }) : meta.submitError }</div>
              )}
            >
              <ErrorOutlineIcon css={errorIconStyles(theme)} />
            </Tooltip>
          </InputAdornment>
        ) : defaultEndAdornment,
      }}
      {...rest}
    />
  )
}
