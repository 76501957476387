/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { GeneralStatistics } from 'interfaces/dashboard'
import { GeneralStatisticsCard } from 'components/pages/Dashboard/General/Card'

import effectiveness from 'assets/images/effectiveness.png'
import doctors from 'assets/images/doctors.png'
import patients from 'assets/images/patients.png'
import { css } from '@emotion/react'

//#region Styles
const wrapperStyles = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
//#endregion

interface GeneralProps {
  data: GeneralStatistics,
}

export const General: React.FC<GeneralProps> = ({ data }) => (
  <div css={wrapperStyles}>
    <GeneralStatisticsCard
      value={data.totalDoctors}
      label='statistics.total.doctors'
      image={doctors}
    />
    <GeneralStatisticsCard
      value={data.totalPatients}
      label='statistics.total.patients'
      image={patients}
    />
    <GeneralStatisticsCard
      value={data.effectiveness}
      label='statistics.effectiveness'
      image={effectiveness}
    />
  </div>
)
