import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { useIntl } from 'react-intl'
import { Paper } from '../Paper'
import { DiplomasForm } from '../DiplomasForm'

const PaperStyled = styled(Paper)`
  padding: 0;
`

export const Diplomas = ({
  diplomas,
  changeButtonPath,
  onPaperClick,
}) => {
  return (
    <PaperStyled
      changeButtonPath={changeButtonPath}
      heading={'profile.diplomas'}
    >
      <DiplomasForm
        diplomas={diplomas.map(item => (item.attributes
          ? {
            id: item.id,
            file: item.attributes.url,
            extension: idx(item, _ => _.attributes.extension),
          }
          : { id: '0', file: '' }
        ))}
        id='profile_diplomas'
        afterSubmit={() => null}
        onSubmit={() => null}
        disableEdit
        onPaperClick={onPaperClick}
      />
    </PaperStyled>
  )
}

Diplomas.defaultProps = {
  changeButtonPath: '',
}

Diplomas.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeButtonPath: PropTypes.string,
  onPaperClick: PropTypes.func,
}
