/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect, useState, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { getTeamByIds, getTeamById } from '../../../../ducks/modules/teams';
import { Colors } from 'constants/colors';
import { MembersList } from 'components/pages/Roso/ManageTeam/MembersList';
import { ButtonBlock } from 'components/pages/Roso/ManageTeam/ButtonBlock';
import EsspLogo from '../images/Logo_ESSP.png';
import EssLogo from '../images/Logo_ESS.png';
import {setIsPrincipalPage} from "../../../../ducks/modules/menu";
import {useDispatch} from "react-redux";


interface WorkPlaceItem {
 attributes: {
  city: string;
  street: string;
  name: string;
 }
}
interface SpecializationItem {
  attributes: {
    name: string;
    id: number;
  }
}
interface Structure {
  attributes: {
    address: string;
    description: string;
    city: string;
    id: number;
    email: string;
    name: string;
    phone: string;
    finessGeographic: string;
    finessJuridic: string;
  }
}
const Wrapper = css`
  max-width: 900px;
  min-width: 720px;
  margin: 24px auto;
  word-break: break-word;
`
const LeftTeamSide = css`
  padding-right: 8px;
`

const TeamInfoHeading = css`
  font-size: 18px;
  text-align: center;
  color: #21293e;
  margin-bottom: 8px;
  font-weight: 600;
`

const TeamInfoSubHeading = css`
    font-size: 18px;
    color: #21293e;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 600;
`

const TeamInfoStaticText = css`
    font-size: 18px;
    text-align: center;
    padding: 2px;
    font-weight: 600;
`
const TeamInfoStaticTextLeft = css`
    font-size: 18px;
    text-align: center;
    padding: 2px;
    text-align: left;
`
const TeamInfoStaticTextDesc = css`
    margin: 10px 0px 5px 0px;
    font-size: 14px;
    text-align: center;
    color: ${Colors.grey};
    font-weight: 600;
`
const AdministratorImage = css`
    width: 36px;
    height: 36px;
    margin: 0px auto;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 158, 255, 0.12);
    backgound-position: center;
    margin-bottom: 6px;
`

const AdministratorName = css`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #21293e;
    margin-bottom: 4px;
`
const AdministratorText = css`
    font-size: 16px;
    text-align: center;
    color: ${Colors.grey};
    padding-bottom: 8px;
`

const TeamDirectManagement = css`
    padding: 24px;
`

const RightSideContainerText = css`
    padding: 10px;
    text-align: center;
    margin: 0px auto;
`

const LeftSideHeading = css`
    font-size: 18px;
    font-weight: 500;
    color: #21293e;
    margin-bottom: 16px;
`

const Members = css`
    margin-top: 36px;
`

const teamAdminContacts = css`
  text-align: center;
  font-size: 16px;
  color: #21293e;
`

export const ManageTeam = ({ match }: { match: any }) => {
  const dispatch = useDispatch()
  const [oldApi, setOldApi] = useState([]);
  const oldApiId = get(match, 'params.id', null);
  const refreshOldApi = useCallback(
    () => getTeamById(oldApiId).then(result => setOldApi(result)), [oldApiId],
  );
  const id = get(match, 'params.id', null);
  const [team, setTeam] = useState([]);
  const refreshTeam = useCallback(() => getTeamByIds(id).then(result => setTeam(result)), [id]);
  const refreshAll = () => {
    refreshOldApi();
    refreshTeam();
  }
  useEffect(() => {
    dispatch(setIsPrincipalPage(false))
  }, [])
  useEffect(() => { refreshAll(); }, [id, oldApiId]);
  const adminItem = oldApi.find((el: any) => get(el, 'attributes.role', false) === 'creator');
  const admin = get(adminItem, 'relationships.doctor.data.attributes', {});
  const adminPhoto = get(admin, 'photo', '');
  const adminEmail = get(admin, 'email', '');
  const adminPhone = get(admin, 'phone_number', '');
  const teamName = get(team, 'attributes.name', '');
  const teamType = get(team, 'attributes.type', '');
  const specializations = get(team, 'relationships.specializations.data', []);
  const structures = get(team, 'relationships.structures.data', []);
  const fullName = get(team, 'attributes.creator', '');
  const description = get(team, 'attributes.description', '');
  const workPlaces = get(team, 'relationships.workPlace.data', []);
  const teamStatus = get(team, 'attributes.status', '');
  return (
    <div>
      <div css={Wrapper}>
        <Grid container>
          <Grid css={LeftTeamSide} item xs={5}>
            <Paper>
              <div style={{ paddingTop: '15px' }}>
                <div>
                  <ButtonBlock 
                    teamStatus={teamStatus}
                    teamId={id} 
                    refreshMyTeams={refreshTeam} 
                    membersCount={oldApi.length}
                  />
                </div>
                <div css={TeamInfoHeading}>
                  <img 
                    style={{ width: '150px' }}
                    src={teamType === 'ESS' ? EssLogo : EsspLogo} 
                    alt='EsspLogo' 
                  />
                </div>
                <div css={AdministratorImage} style={{ background: `url(${adminPhoto}) center no-repeat` }} />
                <div css={AdministratorName}>{fullName}</div>
                <div css={AdministratorText}><FormattedMessage id='roso.create.administrator' /></div>
                <div css={teamAdminContacts}>
                  <div>{adminEmail}</div>
                  <div style={{ padding: '5px' }}>{adminPhone}</div>
                </div>
                <h1 css={TeamInfoHeading}>
                  <div css={TeamInfoStaticTextDesc}>Team name:&nbsp;</div>
                  {teamName}
                </h1>
              </div>
              <div css={TeamInfoStaticText}>
                <div css={TeamInfoStaticTextDesc}>Description:&nbsp;</div>
                {description}
              </div>
              <div css={RightSideContainerText}>
                <div css={TeamInfoSubHeading}>
                  {specializations.map((el: SpecializationItem) => (
                    <span key={el.attributes.id}>
                      <div css={TeamInfoStaticTextDesc}>Specialization:&nbsp;</div>
                      {get(el, 'attributes.name', '')}
                    </span>

                  ))}
                </div>
                <div>
                  <div css={TeamInfoStaticTextDesc}>Workplaces:&nbsp;</div>
                  {workPlaces.map((el: WorkPlaceItem) => (
                    <span key={`${el.attributes.city}-${el.attributes.street}-${el.attributes.name}`}>
                      <div css={TeamInfoStaticTextLeft}>
                        &nbsp;
                        {el.attributes.name}
                      </div>
                      <div css={TeamInfoStaticTextLeft}>
                        &nbsp;
                        {el.attributes.city}
                      </div>
                      <div css={TeamInfoStaticTextLeft}>
                        &nbsp;
                        {el.attributes.street}
                      </div>
                    </span>
                  ))}
                </div>
                
                <div css={TeamInfoStaticTextDesc}>Structure:&nbsp;</div>
                <div css={TeamInfoStaticTextLeft}>  
                  {structures.map((el: Structure) => (
                    <div 
                      style={{ padding: '2px' }}
                      key={el.attributes.id}
                    >
                      {`${el.attributes.name},
                        ${el.attributes.address},
                        ${el.attributes.city},
                        ${el.attributes.phone}, 
                        ${el.attributes.email},
                        ${el.attributes.description}`}
                    </div>
                  ))}
                 
                </div>

              </div>
            </Paper>
          </Grid>
          <Grid item xs={7}> 
            <Paper css={TeamDirectManagement}>
              <div css={LeftSideHeading}>
                <FormattedMessage id='roso.manage.membersOfTeam' />
              </div>
              <div css={Members}>
                <MembersList refreshTeam={refreshAll} members={oldApi} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  ) 
};
