/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'interfaces'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setLocale } from 'ducks/modules/localization'
import { css } from '@emotion/react'

//#region Styles
const wrapperStyles = css`
  && {
    color: white;
    margin-right: 16px;
    
    * {
      color: white;
    }
    
    fieldset {
      border: none;
    }
  }
`
//#endregion

export const LanguageSwitcher = () => {
  const dispatch = useDispatch()
  const locale = useSelector<State, string>(state => state.localization)

  const onChange = (e: any) => {
    localStorage.setItem('language', e.target.value)
    dispatch(setLocale(e.target.value))
  }

  return (
    <div>
      <FormControl css={wrapperStyles} variant='outlined' size='small'>
        <Select
          value={locale}
          onChange={onChange}
        >
          <MenuItem value='en'>
            EN
          </MenuItem>
          <MenuItem value='fr'>
            FR
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
