import { handleActions } from 'redux-actions'
import { combineReducers, AnyAction } from 'redux'

import { DoctorsActions } from 'ducks/modules/doctors'
import { Pagination } from 'interfaces'
import { ConsultationsActions } from 'ducks/modules/consultations'
import { GdprActions, GdprDeletingActions } from 'ducks/modules/gdpr'
import { ChatsActions } from 'ducks/modules/chats'
import { SpecActions } from 'ducks/modules/specialization'
import { SkillsActions } from 'ducks/modules/skills';
import { AllConsultationsActions } from 'ducks/modules/AllConsultations';
import {StructuresActions} from "../structures";
import {TeamsActions} from "ducks/modules/teams";
import {StructuresMembersActions} from "ducks/modules/structuresMembers";

//#region Reducer
const initialState: Pagination = {
  total: 0,
  limit: 0,
  totalPage: 1,
  currentPage: 1,
}

const doctors = handleActions({
  [DoctorsActions.fetchDoctorsSuccess]: (state, action: AnyAction) => action.payload.pagination,
}, initialState)


const teams = handleActions({
  [TeamsActions.fetchTeamsSuccess]: (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const consultations = handleActions({
  [ConsultationsActions.fetchConsultationsSuccess]: (
    state,
    action: AnyAction,
  ) => action.payload.pagination,
}, initialState)

const consultationsAll = handleActions({
  [AllConsultationsActions.fetchAllConsultationsSuccess]: (
    state,
    action: AnyAction,
  ) => action.payload.pagination,
}, initialState)

const gdpr = handleActions({
  [GdprActions.fetchGdprSuccess]:
    (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const gdprDeletion = handleActions({
  [GdprDeletingActions.fetchGdprDeletingSuccess]:
    (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const chats = handleActions({
  [ChatsActions.fetchChatsSuccess]:
    (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const specialization = handleActions({
  [SpecActions.fetchSpecSuccess]:
    (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const skills = handleActions({
  [SkillsActions.fetchSkillsSuccess]:
    (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const structures =  handleActions({
  [StructuresActions.fetchStructuresSuccess]: (state, action: AnyAction) => action.payload.pagination,
}, initialState)

const structuresMembers =  handleActions({
  [StructuresMembersActions.fetchStructuresMembersSuccess]: (state, action: AnyAction) => action.payload.pagination,
}, initialState)

export const paginationReducer = combineReducers({
  doctors,
  teams,
  consultations,
  gdpr,
  gdprDeletion,
  chats,
  specialization,
  skills,
  consultationsAll,
  structures,
  structuresMembers
})
//#endregion
