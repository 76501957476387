export const DOCTORS_TABLE_HEADERS = [
  'last_name',
  'first_name',
  'specialization',
  'email',
  'phone_number',
  'postcode',
  'city',
  'register_date',
  'status_formatted',
  'approve_date',
]

export const CONSULTATION_TABLE_HEADERS = [
  'creation_date',
  'proposed_date',
  'role',
  'correspondant',
  'correspondant_specialization',
  'type',
  'status',
]
export const CONSULTATIONS_TABLE_HEADERS = [
  'creation_date',
  'proposed_date',
  'type',
  'status',
  'invited',
  'invited_specialization',
  'creator',
  'creator_specialization',
  'patient',
]

export const MEDICINES_TABLE_HEADERS = [
  'name',
  'positive',
  'negative',
]

export const EFFECTIVENESS_TABLE_HEADERS = [
  'doctor_name',
  'name',
  'status_label',
]

export const GDPR_TABLE_HEADERS = [
  'id',
  'createTime',
  'name',
  'role',
  'type',
  'status',
]

export const GDPR_DELETION_TABLE_HEADERS = [
  'id',
  'createTime',
  'name',
  'role',
  'type',
  'status',
  'reason',
  'comment',
]

export const STRUCTURES_TABLE_HEADERS = [
  'name',
  'type',
  'finess',
  'members'
]

export const STRUCTURES_MEMBERS_TABLE_HEADERS = [
  'last_name',
  'first_name',
  'specialization',
  'email',
  'phone_number',
  'register_date',
  'status',
  'send_email',
  'delete',
]

export const STRUCTURES_MEMBERS_ADD_TABLE_HEADERS = [
    'first_name',
    'last_name',
    'add'
]

export const GLOBAL_NOTIFICATIONS_TABLE_HEADERS = [
  'id',
  'cropped',
  'creationDate',
  'view',
]

export const TEAMS_TABLE_HEADERS = [
  'name',
  'type',
  'specialization',
  'member_count',
  'creation_date',
    'validate',
  'modify'
]

export const SPECIALIZATIONS_TABLE_HEADERS = [
  'id',
  'attributes.name',
  'attributes.key',
  'view',
  'delete',
]

export const SKILLS_TABLE_HEADERS = [
  'id',
  'name',
  'attributes.creator_full_name',
  'attributes.creation_date',
  'modify',
  'validate',
  'delete',
]

export const GEOLOC_TABLE_HEADERS = [
    'last_name',
    'first_name',
    'specialization',
    'place',
    'postcode',
    'city'
]

export const GEOLOC_STRUCTURE_TABLE_HEADERS = [
  'name',
  'type',
  'finessGeographic',
  'members',
  'modify'
]

export const STRUCTURES_MEMBERS_ADD_STRUCTURE = [
  'name',
  'type',
  'add'
]

export const SKILLS_TEAM_ADD = [
    'label',
    'add'
]

export const VERSION_TABLE_HEADERS = [
    'software',
    'min_version',
    'view',
]