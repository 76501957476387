/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {useEffect, useRef, useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MuiMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'

import { Menu } from 'components/pages/Board/Layout/Menu'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { LanguageSwitcher } from 'components/common/LanguageSwitcher'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import { logout } from 'ducks/modules/auth'
import { Routes } from 'constants/routes'
import {State} from "../../../../interfaces";
import ArrowBack from '@mui/icons-material/ArrowBack'
import styled from 'utils/styled'
import {setActiveMenu, setIsPrincipalPage, setSubMenu} from "../../../../ducks/modules/menu";

const growStyles = css`
  flex-grow: 1;
`

const wrapperStyles = css`
  display: flex;
`

const mainStyles = css`
  flex-grow: 1;
  background-color: ${Colors.background};
`

const IconButtonStyled = styled(IconButton)`
  && { margin-left: -20px; }
`

const subMenuStyles = css`
  margin-right:5px
`

export const BoardLayout: React.FC = ({ children }) => {
  const [isMenuOpened, toggleMenu] = useState(false)
  const menuRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedMenu = useSelector<State, string>(state => state.menu.activeMenu)
  const onBackIcon = !useSelector<State, boolean>(state => state.menu.isPrincipalPage)
  const selectedSubMenu = useSelector<State, string>(state => state.menu.subMenu)
  const onBackIconClick = () => history.goBack();
  const [subMenuName, setSubMenuName] = useState('')

  const onLogoutClick = () => {
    delete axios.defaults.headers.common.Authorization
    localStorage.removeItem('auth')
    dispatch(logout())
    history.push(Routes.auth)
  }

  useEffect(() => {
    if(selectedMenu == "global.menu.structures.membres"){
      setSubMenuName(selectedSubMenu)
    }
    else{
      setSubMenuName('')
    }
  },[selectedMenu])


  return (
    <div css={wrapperStyles}>
      <Menu />
      <main css={mainStyles}>
        <AppBar position='static'>
          <Toolbar>
            {onBackIcon && <IconButtonStyled color='inherit' onClick={onBackIconClick}><ArrowBack /></IconButtonStyled>}
            {subMenuName != '' && <h5 css={subMenuStyles}>{subMenuName} </h5>}
            {selectedMenu != '' && <FormattedMessage id={selectedMenu} defaultMessage={selectedMenu}/>}
            <div css={growStyles} />
            <LanguageSwitcher />
            <IconButton color='inherit' ref={menuRef} onClick={() => toggleMenu(!isMenuOpened)}>
              <AccountCircle />
            </IconButton>
            <MuiMenu
              open={isMenuOpened}
              onClose={() => toggleMenu(false)}
              anchorEl={menuRef.current}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={onLogoutClick}>
                <FormattedMessage id='global.logout' />
              </MenuItem>
            </MuiMenu>
          </Toolbar>
        </AppBar>
        <div css={growStyles}>
          {children}
        </div>
      </main>
    </div>
  )
}
