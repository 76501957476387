import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'

import { Content } from 'components/common/Content'
import { Button } from 'components/common/Button'
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {Colors} from "../../../constants/colors";
import {SpecializationTable} from "../Specializations/SpecializationsTable";
import {SPECIALIZATIONS_TABLE_HEADERS, VERSION_TABLE_HEADERS} from "../../../constants/tables";
import {VersionsTable} from "./VersionsTable";
import {fetchMobileVersions} from "../../../ducks/modules/versions";
import {State} from "../../../interfaces";
import {Specialization} from "../../../interfaces/specialization";
import {Version} from "../../../interfaces/version";



const RestrictedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 113px);
`

export const MobileVersion = () => {

    const [restricted, setRestricted] = useState('');
    const versions = useSelector<State, Version[]>(state => state.versions.mobileVersions)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setActiveMenu('global.menu.mobile_version'))
        dispatch(setIsPrincipalPage(true))
    }, [])

    useEffect(() => {
        dispatch(fetchMobileVersions())
    }, [])

    useEffect(() => {
        if (restricted === 'OUkjasd1299') {
            localStorage.setItem('lastRestricted', moment().add(15, 'minutes').format());
        }
    }, [restricted])

    const access = restricted === 'OUkjasd1299'
        || (!moment().isAfter(moment(localStorage.getItem('lastRestricted'))) && localStorage.getItem('lastRestricted'));

    return (
        <Content>
            {!access ? (
                    <RestrictedContainer>
                        <FormattedMessage id="global.restricted.acess" />
                        &nbsp;&nbsp;&nbsp;
                        <TextField
                            id="outlined-basic"
                            onChange={(e) => setRestricted(e.target.value)}
                            label="Password"
                            type="password"
                            variant="outlined"
                        />
                    </RestrictedContainer>
                )
                : (
                    <>
                        <VersionsTable
                            headers={VERSION_TABLE_HEADERS}
                            data={versions}
                        />
                    </>
                )}
        </Content>
    )
}
