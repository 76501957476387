import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'

import { Content } from 'components/common/Content'
import { Pagination, State } from 'interfaces'
import {fetchSpec, setSpecializationFilters} from 'ducks/modules/specialization'
import { Table } from 'components/common/Table'
import { SPECIALIZATIONS_TABLE_HEADERS } from 'constants/tables'
import { setFilter } from 'helpers/filters'
import {Specialization, SpecializationFilters} from 'interfaces/specialization';
import { Button } from 'components/common/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import EditIcon from "@mui/icons-material/Edit";
import {Colors} from "../../../constants/colors";
import { Search } from 'components/common/Search'
import {DeleteSpecializationDialog} from "./DeleteSpecializationsDialog";
import {SpecializationTable} from "./SpecializationsTable";


const HeaderStructures = styled.div`
  display: flex;
  justify-content: space-between;
`

const RestrictedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 113px);
`

const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
`
const StyledButtonDelete = styled(Button)`
  background-color: ${Colors.redDelete};
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.watermelon};
  }
`



export const SpecializationsList = () => {

  const [restricted, setRestricted] = useState('');
  const specializations = useSelector<State, Specialization[]>(state => state.specialization.list)
  const filters = useSelector<State, SpecializationFilters>(state => state.specialization.filters)
  const dispatch = useDispatch()
  const pagination = useSelector<State, Pagination>(state => state.pagination.specialization)
  const history = useHistory();
  const [openDelete, setOpenDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0)

  const deleteSpecialization = (id: number) => axios.delete(`/admin/doctor/specialization/${id}`)
    .then(() => dispatch(fetchSpec(filters)))
    .catch(err => console.warn(err))

  useEffect(() => {
    dispatch(setActiveMenu('global.menu.specializations'))
    dispatch(setIsPrincipalPage(true))
  }, [])

  useEffect(() => {
    dispatch(fetchSpec(filters))
  }, [dispatch, filters])

  const handleClickDelete = (value:number) => {
    setIdToDelete(value);
    setOpenDelete(true)
  };

  const handleCloseDelete = (value:number| null) => {
    setOpenDelete(false);
    if(value){
      deleteSpecialization(value)
    }
  }

  const [sortBy, setSortBy] = useState(filters.sortBy)
  const [sortOrder,setSortOrder] = useState(filters.sortOrder)

  const requestSort = (pSortBy: string) => {
    if (pSortBy === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
    } else {
      setSortBy(pSortBy)
      setSortOrder("ASC")
    }
  }

  useEffect(() => {
    dispatch(setSpecializationFilters({...filters, sortBy: sortBy, sortOrder: sortOrder}))
    // setFilters(setFilter(setFilter(filters, 'sortBy', sortBy), 'sortOrder', sortOrder),)
  }, [sortBy, sortOrder])
  useEffect(() => {
    dispatch(fetchSpec(filters))
  }, [filters])

  const onSearch = (search?: string) => dispatch(setSpecializationFilters({...filters, search}))

  const onPageChange = (page: number) => dispatch(setSpecializationFilters({...filters, page}))

  const onLimitChange = (limit: number) => dispatch(setSpecializationFilters({...filters, limit}))

  useEffect(() => {
    if (restricted === 'OUkjasd1299') {
      localStorage.setItem('lastRestricted', moment().add(15, 'minutes').format());
    }
  }, [restricted])

  const access = restricted === 'OUkjasd1299'
  || (!moment().isAfter(moment(localStorage.getItem('lastRestricted'))) && localStorage.getItem('lastRestricted'));

  return (
    <Content>
      {!access ? (
        <RestrictedContainer>
          <FormattedMessage id="global.restricted.acess" />
&nbsp;&nbsp;&nbsp;
          <TextField 
            id="outlined-basic" 
            onChange={(e) => setRestricted(e.target.value)}
            label="Password"
            type="password"
            variant="outlined"
          />
        </RestrictedContainer>
      ) 
        : (
          <>
            <HeaderStructures>
              <Search
                  onSearch={onSearch}
                  label='specialization.search'
              />
              <Link to="/specializations/new">
                <StyledButton color="primary" type="submit">
                  <FormattedMessage id="specialization.new" />
                </StyledButton>
              </Link>
            </HeaderStructures>
            <SpecializationTable
              headers={SPECIALIZATIONS_TABLE_HEADERS}
              data={specializations}
              sortBy={sortBy}
              sortOrder={sortOrder}
              requestSort={requestSort}
              pagination={pagination}
              onPageChange={onPageChange}
              onPerPageChange={onLimitChange}
              handleClickDelete={handleClickDelete}
            />
            <div>
              <DeleteSpecializationDialog
                  open={openDelete}
                  onClose={handleCloseDelete}
                  data={idToDelete}
              />
            </div>
          </>
        )}
    </Content>
  )
}
