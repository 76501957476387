/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from 'components/common/Button'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from '@emotion/styled'
import axios from 'axios';
import get from 'lodash/get';
import {useHistory, useParams} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'
import {TextField} from '@material-ui/core'
import orderBy from 'lodash/orderBy';
import {setActiveMenu, setIsPrincipalPage} from "../../../../ducks/modules/menu";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../constants/colors";
import {MultipleInfinityAsyncSelect} from 'components/common/AsyncSelect/MultipleInfinity';
import {MultilineTextField} from 'components/common/MultilineTextField';
import {Specialization} from "../../../../interfaces/doctors";
import {TagMultipleInfinityAsyncSelect} from 'components/common/AsyncSelect/TagMultipleInfinity';
import {appendSkill} from "../../../../helpers/skills";
import {Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import {Search} from "../../../common/AutoSearch";
import {StructureMembers, StructureMembersAddFilters, StructureMembersPayload} from "../../../../interfaces/structuresmembers";
import {getListMembers, normalizeStructureMembers} from "../../../../helpers/structureMembers";
import {
    SKILLS_TEAM_ADD,
    STRUCTURES_MEMBERS_ADD_STRUCTURE,
    STRUCTURES_MEMBERS_ADD_TABLE_HEADERS,
    STRUCTURES_MEMBERS_TABLE_HEADERS, STRUCTURES_TABLE_HEADERS
} from "../../../../constants/tables";
import {getListStructures, normalizeStructure} from 'helpers/structures';

import {
    createTeamDiscussion,
    deleteMember, getTeamDiscussion,
    sendInviteAdmin
} from "../../../../ducks/modules/teams";
import {Table} from "../../../common/Table";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddHomeIcon from '@mui/icons-material/AddHome';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {normalizeDoctor, normalizeDoctorTeam} from "../../../../helpers/doctors";
import {StructureFilters} from "../../../../interfaces/structures";
import CancelIcon from "@mui/icons-material/Cancel";
import {stringify} from "querystring";
import pickBy from "lodash/pickBy";

interface Structure {
    id?: number;
    type?: number;
    name?: string;
    description?: string;
    email?: string;
    phone?: string;
    city?: string;
    website?: string;
    address?: string;
    zipcode?: string;
    isPhysical?: string;
    isActive?: boolean;
    status?: boolean;
    finessGeographic?: string;
    finessJuridic?: string;
    siren?: string;
    currentType?: any,
    skill_ids: any,
    currentSpeciality: any
}

interface Skill {
    attributes?: {
        id?: number,
        name?: string
    }
}

const typesOptions = [
    {value: 'ESS', label: 'ESS'},
    {value: 'ESSP', label: 'ESSP'},
]


const MultilineField = styled(MultilineTextField)`
   & .root {
    height: 88px;
    & textarea {
     max-height: 71px;
     overflow: auto;
    }    
  }
   & .helperRoot {
       border-top: 2px solid red;
       margin-top: -4px;
     }
 
`
const StyledTextField = styled(TextField)`
    width: 100%;
    border-bottom: none !important;
    border-width: thin !important;
    border-radius: 4px !important;
    border-color: #9FA0A2 !important;
`

const TagItem = styled.div`
  background-color: #63f;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  margin: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0;
  word-break: inherit;
  display: flex;
  align-items: center;
`

const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`

const StyledSelect = styled(Select)`
    width: 100%;
`
const StyledInputLabel = styled(InputLabel)` 
    background-color: #f1f3f7;
`

const StyledButton = styled(Button)` 
  background-color: ${Colors.green};
  color: ${Colors.white};
  border-radius: 50px;
  
  &:hover {
    background-color: ${Colors.green};
  }
`

const DeleteSkillIcon = styled(CancelIcon)`
  margin-left: 5px;
  cursor: pointer;
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`
const DivCard = styled.div`
    display: flex !important;
    flex-direction: row !important ;
     flex-wrap: wrap ;
      justify-content: flex-start !important;
      margin-bottom: 2%;
      row-gap:10px;
      column-gap: 20px;
`

const CardStructure = styled(Card)`
  width: 225px !important
`

const CardDoctor = styled(Card)`
    width:225px !important
`
const DivButton = styled.div`
  display: flex;
  justify-content: right;
`

const StyledButtonConfirm = styled(Button)`
  background-color: white !important;
  box-shadow:none !important;
`

const DivMarginTop = styled.div`
margin-top: 1%;
margin-bottom: 1%;
`
const Label = styled.div`
    color: black;
    font-size: 2em;
    font-weight: bold;
    opacity: 0.6;
    padding-bottom: 5px;
    padding-top: 30px;
`

const InfoMember = styled.div`
    color: #959fb8;
    font-size: 12px;
    font-weight: 600;
`

const StyledTable = styled(Table)`
    box-shadow : 0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0);
`

const skillsDefaultFormatter = (skills = []) => skills.map(el => ({
    value: el.attributes.id,
    label: el.attributes.name,
}))


export const NewTeam = ({match}: { match: any }) => {

    const {formatMessage} = useIntl()
    const [team, setTeam] = useState<Structure>({});
    const [requertError, setRequestError] = useState(false);
    const [nameAlreadyExist, setNameAlreadyExist] = useState(false);
    const id = get(match, 'params.id', false);
    const [nbMembersError, setNbMembersError] = useState(false);
    const [teamMembers, setTeamMembers] = useState<any>({});
    const [deleteCreator, setDeleteCreator] = useState(false);
    const [noMembers, setNoMembers] = useState(false);
    const[duplicateMember, setDuplicateMember] = useState(false);
    const[duplicateStructure, setDuplicateStructure] = useState(false);
    const [types, setTypes] = useState([]);
    const [specialization, setSpecialization] = useState(null);
    const [specializationSelect, setSpecializationSelect] = useState(0);
    const [typeSelect, setTypeSelect] = useState();
    const [type, setType] = useState();
    const [name, setName] = useState<string>('')
    const [specializations, setSpecializations] = useState<Specialization[]>([]);
    const [description, setDescription] = useState<string>('')
    const [loadingReady, setloadingReady] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory();
    const idUser = useSelector(store => store.connected.idUser);

    const onSubmit = (values: any, setRequestError: (a: boolean) => void, cb: () => void) => {
        const allMembers: string | any[] = [];
        const nbMembers = (teamMembers.length || 0) + addedMembers.length - removedMembers.length;

        if (nbMembers < 2) {
            setNbMembersError(true);
            return;
        } else {
            setNbMembersError(false);
        }

        // Check if there is new members to add
        if (addedMembers) {
            addedMembers.forEach(value => {
                //If we are creating the team and not editing it we need to define a team creator
                if (value != "") {
                    if (id) {
                        const index = teamMembers.indexOf((member: any) => member.relationships.doctor.data.id == value.id)
                        if (index == -1) {
                            sendInviteAdmin({team_id: id, member_id: parseInt(value.id), id_doctor: idCreator}).then(
                            )
                            allMembers.push(parseInt(value.id));
                        }

                    } else {
                        allMembers.push(parseInt(value.id));
                    }
                }
            })
        } else {
            if (!id) {
                setNoMembers(true)
                return;
            }
        }
        //members that are already in the team are just pushed in the array
        if (teamMembers.length > 0) {
            teamMembers.forEach((value: { attributes: { doctor: any; }; }) => {
                allMembers.push(value.attributes.doctor)
            })
        }

        if (id) {
            //removing elements from the db table doctor_team_member if needed
            if (removedMembers.length > 0) {
                teamMembers.forEach((member: any) => {
                    removedMembers.forEach((id: any) => {
                        if (member.relationships.doctor.data.id == id) {
                            deleteMember({memberId: member.id}).then().catch(err => console.warn(err));
                        }
                    });
                });
            }

            const formValues = {
                description: description,
                geo_lat: "",
                geo_lon: "",
                members: allMembers,
                name: name,
                scope: "international",
                skill_ids: skillsToShow.map(skill => skill.value),
                structure_ids: structuresToShow.map(structure => structure.id)
            }
            const result = sendRequest(`/admin/doctor/team/edit/${id}`, formValues, setRequestError, cb);// eslint-disable-line
            return result;
        } else {
            const formValues = {
                description: description,
                geo_lat: "",
                geo_lon: "",
                members: allMembers,
                name: name,
                scope: "international",
                skills_id: skillsToShow.map(skill => skill.value),
                structure_ids: structuresToShow.map(structure => structure.id)
            }
            if (type == 'ESS') {
                const result = sendRequest('/admin/doctor/team/ess/create', formValues, setRequestError, cb);
                return result
            } else {
                const result = sendRequest('/admin/doctor/team/essp/create', formValues, setRequestError, cb);
                return result
            }
        }
    }

    const sendRequest = async (urlCreate: string, values: any, setRequestError: (a: boolean) => void, cb: () => void) => {
        try {
            const result = await axios.post(urlCreate, values);
            cb();
            return result.data.data;
        } catch (e) {
            if (e.response.data.data.trace[0].message == "team.field.name.exist") {
                setNameAlreadyExist(true)
            } else {
                setRequestError(true);
            }
            return [];
        }
    }

    const updateSpecialization = (value: any) => {
        setSpecialization(value)
        setSpecializationSelect(value)
    }

    const updateType = (value: any) => {
        setType(value)
        setSearchMember("");
        setStructureMembersAdd([]);
    }

    const updateName = (value: any) => {
        setName(value)
    }

    const [idCreator, setIdCreator] = useState("");
    const [searchMember, setSearchMember] = useState("");
    const [addedMembers, setAddedMembers] = useState([]);
    const [removedMembers, setRemovedMembers] = useState([]);
    const [membersToShow, setMembersToShow] = useState([]);
    const [filtersESSP, setFilterESSP] = useState<StructureMembersAddFilters>({name: '', limit: 5, page: 1});
    const [filtersESS, setFilterESS] = useState<StructureMembersAddFilters>({name: '', limit: 5, page: 1});
    const [structureMembersAdd, setStructureMembersAdd] = useState<StructureMembers[]>([]);

    const [searchStructure, setSearchStructure] =  useState("");
    const [filterStructure, setFilterStructure] = useState<StructureFilters>({ search: '', limit: 5, page: 1 });
    const [addedStructures, setAddedStructure] = useState([]);
    const [structuresToShow, setStructuresToShow] = useState([]);
    const [structuresAdd, setStructuresAdd] = useState<Structure[]>([]);

    const [searchSkill, setSearchSkill] = useState("");
    const [filterSkill, setFilterSkill] = useState("");
    const [listSkill, setListSkill] = useState<any>([]);
    const [skillsToShow, setSkillsToShow] = useState([]);
    const [addedSkill, setAddedSkill] = useState([]);
    const [maxSkills, setMaxSkills] = useState(false);
    const [duplicateSkill, setDuplicateSkill] = useState(false)
    const [noDiscussion, setNoDiscussion] = useState(false)
    const [creatingDiscussion, setCreatingDiscussion] = useState(false)

    const addTeamMember = (newMember: StructureMembers) => {
        //Si la personne est déjà dans l'équipe on affiche un message d'erreur
        const duplicate = membersToShow.find((member) => member.id === newMember.id)
        if (duplicate) {
            setDuplicateMember(true);
        } else {
            setMembersToShow([...membersToShow, newMember]);

            // Si le membre est dans la liste des éléments à retirer on l'enlève
            setRemovedMembers(removedMembers => removedMembers.filter(id => id !== Number(newMember.id)))
            // On met à jour la liste des membres à ajouter de l'équipe
            addedMembers.indexOf(newMember.id) ===  -1 && setAddedMembers([...addedMembers, newMember]);
            setDuplicateMember(false);
        }

        setNbMembersError(false);
        setSearchMember("");
        setStructureMembersAdd([]);
    }

    const addStructure = (newStructure: Structure) => {
        const duplicate = structuresToShow.find((strucuture) => strucuture.id === newStructure.id)
        if (duplicate) {
            setDuplicateStructure(true)
        } else {
            setStructuresToShow([...structuresToShow, newStructure]);

            //On met à jour la liste des membres à ajouter de l'équipe
            setAddedStructure([...addedStructures, newStructure]);
        }
        setSearchStructure("");
        setStructuresAdd([]);
    }

    const deleteStructureClick = (id: number) => {
        const newArray = addedStructures.filter((structure) => structure.id !== id)
        setAddedStructure(newArray);
        setStructuresToShow(structuresToShow.filter((structure: any) => {
            return parseInt(structure.id) !== parseInt(id);
        }))
    }

    const addSkill = (newSkill: any) => {
        const duplicate = skillsToShow.find((skill) => skill.value === newSkill.value);

        if (duplicate) {
            setDuplicateSkill(true);
        } else if (skillsToShow.length >= 5) {
            setMaxSkills(true);
        } else if (newSkill.value === "") {
            appendSkill(searchSkill).then((resp) => {
                newSkill.value = resp.data.data.id;
                newSkill.label = resp.data.data.attributes.name
                setSkillsToShow( [...skillsToShow, newSkill]);
                setAddedSkill( [...addedSkill, newSkill]);
                setDuplicateSkill(false);
                setMaxSkills(false);
            }).catch(err => console.warn(err));
        } else {
            setSkillsToShow( [...skillsToShow, newSkill]);
            setAddedSkill( [...addedSkill, newSkill]);
            setDuplicateSkill(false);
            setMaxSkills(false);
        }

        setSearchSkill("");
        setListSkill([]);
    }

    const deleteSkillClick = (id: number) => {
        const newArray = addedSkill.filter((skill) => skill.value !== id);
        setAddedSkill(newArray);
        setSkillsToShow(skillsToShow.filter((skill: any) => {
            return skill.value !== id;
        }))
    }

    const displayMembers = (name: string) => {
        setSearchMember(name);
        dispatch(setFilterESSP({...filtersESSP, name}));
    }

    const displayMembersSpecialization = (name: string) => {
        setSearchMember(name);
        dispatch(setFilterESS(name));
    }

    const displayStructures = (search: string) => {
        setSearchStructure(search);
        dispatch(setFilterStructure({...filterStructure, search}));
    }

    const displaySkills = (search: String) => {
        setSearchSkill(search);
        dispatch(setFilterSkill(search));
    }

    const onCreateDiscussion = () => {
        if (team && !!team.id) {
            setCreatingDiscussion(true)
            createTeamDiscussion(team.id)
              .then(() => setNoDiscussion(false))
              .catch(err => console.error(err))
              .finally(() => setCreatingDiscussion(false))
        }
    }

    useEffect(() => {
        if (team && !!team.id) {
            getTeamDiscussion(team.id).then(() => setNoDiscussion(false)).catch(() => setNoDiscussion(true))
        }
    }, [team]);

    useEffect( () => {
        if(filtersESSP.name != '') {
            getListMembers(filtersESSP).then (resp => {
                setStructureMembersAdd((resp.data.data.map((member: any) => normalizeStructureMembers(member))))
            }).catch(err => console.warn(err))
        }
    }, [filtersESSP])

    useEffect( () => {
        if (searchMember != "") {
            axios.get(`/general/doctor?include=workPlace,structures&specialization=${specialization}&name=${searchMember}`).then (resp => {
                setStructureMembersAdd((resp.data.data.map((member: any) => normalizeStructureMembers(member))))
            }).catch(err => console.warn(err))
        }
    }, [filtersESS])

    useEffect( () => {
        if (searchSkill != "" && searchSkill.length > 2) {
            axios.get(`/api/doctor/team/skill?search=${searchSkill}`).then (resp => {
                const formattedSkills = skillsDefaultFormatter(resp.data.data);
                formattedSkills.push({value: '', label: `Créer "${searchSkill}"`})
                setListSkill(formattedSkills);
            }).catch(err => console.warn(err))
        }
    }, [filterSkill])

    useEffect( () => {
        if (filterStructure.search != "") {
            getListStructures(filterStructure)
                .then(resp => {
                    setStructuresAdd((resp.data.data.map((structure: any) => normalizeStructure(structure))))
                })
                .catch(err => console.warn(err))
        }
    }, [filterStructure])

    useEffect(() => {
        dispatch(setIsPrincipalPage(false))
        dispatch(setActiveMenu('global.menu.teams'))
    })

    useEffect(() => {
        axios.get('/admin/doctor/structure/type/list')
            .then(result => {
                const list = get(result, 'data.data', []);
                const formattedList = list.map((el: any) => {
                    const id = get(el, 'id', 0);
                    const type = get(el, 'attributes.type', 'none');
                    return ({id, label: type})
                })
                const sortedList: any = orderBy(formattedList, [el => el.label.toLowerCase()], ['asc']);
                setTypes(sortedList);
            })
    }, [])

    const deleteMemberClick = (memberId: number) => {
        //Si on est en création on peut supprimer les memebres de la liste sans restriction,
        // en édition on ne peut pas supprimer le créateur de l'équipe
        if (id) {
            if (parseInt(idCreator) === memberId) {
                setDeleteCreator(true)
            } else {
                //On met à jour la liste des memmbres à retirer de l'équipe
                removedMembers.indexOf(memberId) ===  -1 && setRemovedMembers([...removedMembers, memberId]);
                // On met à jour la liste des membres à ajouter de l'équipe
                const newArray = addedMembers.filter(member => Number(member.id) !== memberId);
                setAddedMembers(newArray);
                setMembersToShow(membersToShow.filter((member: any) => {
                    return parseInt(member.id) !== memberId;
                }))
                setDeleteCreator(false)
            }
        } else {
            removedMembers.indexOf(memberId) ===  -1 && setRemovedMembers([...removedMembers, memberId]);
            const newArray = addedMembers.filter(member => Number(member.id) !== memberId);
            setAddedMembers(newArray);
            setMembersToShow(membersToShow.filter((member: any) => {
                return parseInt(member.id) !== memberId;
            }))
        }
    }

    useEffect(() => {
        if (id) {
            axios.get(`/api/doctor/team/members?team_id=${id}`)
                .then(result => {
                    setTeamMembers(result.data.data)
                    setMembersToShow(result.data.data.map((member: any) => normalizeDoctorTeam(member)));
                })
        }
    }, [])

    useEffect(() => {
        axios('/general/specialization')
            .then(result => {
                const dataFormatted: Specialization[] = []
                result.data.data.forEach((value: any) => {
                    const newValue = {
                        key: value.attributes.key,
                        name: value.attributes.name,
                        id: value.id
                    }
                    dataFormatted.push(newValue)
                })
                setSpecializations(dataFormatted)
                if (id) {
                    axios.get(`/admin/doctor/team/${id}`)
                        .then(resp => {
                            const respData = resp.data.data;
                            const structured = {...respData.attributes}
                            const relationship = {...respData.relationships}
                            const creator = relationship.members.data.find((el: any) => el.attributes.role == "creator")
                            if(creator){
                                setIdCreator(creator.attributes.doctor);
                            }
                            structured.currentType = typesOptions.find((el: any) => el.value == structured.type)
                            if (structured.currentType.value == "ESS") {
                                structured.currentSpeciality = dataFormatted.find((el: any) => el.key == relationship.specializations.data[0].attributes.key)
                            }
                            setTeam(structured);
                            setType(structured.currentType.value)
                            if (structured.currentSpeciality) {
                                setSpecializationSelect(parseInt(structured.currentSpeciality.id))
                                updateSpecialization(parseInt(structured.currentSpeciality.id))
                            }

                            if (relationship.skills) {
                                const formattedSkills = skillsDefaultFormatter(relationship.skills.data);
                                setSkillsToShow(formattedSkills);
                            }
                            if (relationship.structures) {
                                setStructuresToShow(relationship.structures.data.map((structure: any) => normalizeStructure(structure)));
                            }

                            setTypeSelect(structured.currentType.value)
                            setName(structured.name)
                            setDescription(structured.description)
                            setloadingReady(true)
                        })
                        .catch(err => {
                            console.warn(err)
                        })
                }
            })
            .catch(err => console.warn(err))
    }, [])

    return (
        <Wrapper>
            {loadingReady || !id ? (
                <Form
                    onSubmit={(values) => onSubmit(values, setRequestError, () => history.push('/roso/'))}
                    initialValues={{
                        ...(team.id ? { id: team.id } : {}),
                    }}

                    render={({
                                 handleSubmit, form, submitting, values,
                             }) => (
                        <form onSubmit={handleSubmit}>
                            {id ? (
                                    <DivMarginTop>
                                        <StyledTextField label={formatMessage({id: 'roso.field.type'})} type="text"
                                                         value={type} disabled={true}/>
                                    </DivMarginTop>

                                ) :
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <StyledInputLabel
                                        id="demo-simple-select-outlined-label">{formatMessage({id: 'roso.field.type'})}</StyledInputLabel>
                                    <StyledSelect
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        disabled={false}
                                        value={typeSelect}
                                        onChange={(e) => updateType(e.target.value)}
                                        required
                                    >
                                        {typesOptions.map(el => (
                                            <MenuItem id={el.label} value={String(el.value)}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </FormControl>
                            }
                            {type == "ESS" ? (
                                <FormControl fullWidth margin="normal" variant="outlined" style={{marginTop: "0"}}>
                                    <StyledInputLabel
                                        id="demo-simple-select-outlined-label">{formatMessage({id: 'roso.field.speciality'})}</StyledInputLabel>
                                    <StyledSelect
                                        labelId="team-specialization"
                                        id="demo-simple-select-outlined"
                                        disabled={false}
                                        //{...input}
                                        value={specializationSelect}
                                        onChange={(e) => updateSpecialization(e.target.value)}
                                        required
                                    >
                                        {specializations.map(el => (
                                            <MenuItem id={el.id + ""} value={String(el.id)}>
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </FormControl>
                            ) : ''}
                            <StyledTextField variant="outlined" inputProps={{maxLength: 50}} label={formatMessage({id: 'roso.field.name'})}
                                             type="text" value={name} onChange={(e) => updateName(e.target.value)} required/>
                            <DivMarginTop>
                                <StyledTextField variant="outlined" multiline minRows={8} maxRows={12} inputProps={{ maxLength: 5000}}
                                                 label={formatMessage({id: 'roso.field.description'})} type="text"
                                                 value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </DivMarginTop>

                            {type == "ESS" || type == "ESSP" ? (
                                <div>
                                <Label>
                                    <FormattedMessage id="roso.members"></FormattedMessage>
                                </Label>
                                <InfoMember>
                                    <FormattedMessage id="roso.member.info"></FormattedMessage>
                                </InfoMember>
                                </div>
                            ) : ''}

                            <DivCard>
                                {membersToShow?.length > 0 ? (
                                    membersToShow.map(member => (
                                        <CardDoctor>
                                            <CardHeader
                                                avatar={
                                                    <Avatar sx={{bgcolor: '#eb6c6c'}} aria-label="recipe">
                                                        {member.last_name.charAt(0) + member.first_name.charAt(0)}
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton aria-label="settings">
                                                        <DeleteIcon onClick={() => deleteMemberClick(parseInt(member.id))}/>
                                                    </IconButton>
                                                }
                                                title={member.last_name + ' ' + member.first_name}
                                                subheader={member.specialization}
                                            />
                                        </CardDoctor>
                                    ))
                                ) : ''}
                            </DivCard>
                            {deleteCreator ? <Error><FormattedMessage id='roso.error.creator'/></Error> : ''}

                            {type == "ESSP" ? (
                                <div>
                                    <Search
                                        val={values.members}
                                        onSearch={value => displayMembers(value)}
                                        label='structuremembers.add.search.placeholder'
                                        placeholder='structuremembers.add.search.placeholder'
                                        value={searchMember}
                                    />

                                </div>
                            ) : ''}
                            {type == "ESS" && specialization ? (
                                <div>
                                    <Search
                                        val={values.members}
                                        onSearch={value => displayMembersSpecialization(value)}
                                        label='structuremembers.add.search.placeholder'
                                        placeholder='structuremembers.add.search.placeholder'
                                        value={searchMember}
                                    />

                                </div>
                            ) : ''}
                            {
                                structureMembersAdd.length > 0  && searchMember != "" ?
                                    <StyledTable
                                        headers={STRUCTURES_MEMBERS_ADD_TABLE_HEADERS}
                                        name='structuremembers'
                                        data={structureMembersAdd
                                            .map(member => ({
                                                ...member,
                                                add: (
                                                    <div>
                                                        <StyledButtonConfirm onClick={() => (addTeamMember(member))}>
                                                            <PersonAddIcon style={{color:"rgb(102, 51, 255)",backgroundColor:"white"}}></PersonAddIcon>
                                                        </StyledButtonConfirm>
                                                    </div>
                                                ),
                                            }))}
                                    /> : ''
                            }
                            {noMembers ? <Error><FormattedMessage id='roso.error.nomember'/></Error>
                                : ''}
                            {duplicateMember ? <Error><FormattedMessage id='roso.error.duplicate.member'/></Error> : ''}
                            {nbMembersError ? <Error><FormattedMessage id='roso.error.not.enough.members'/></Error> : ''}
                            <Label>
                                <FormattedMessage id="roso.field.structures"></FormattedMessage>
                            </Label>

                            <DivCard>
                                {structuresToShow.length > 0 ?
                                    structuresToShow.map(structure => (
                                        <CardStructure>
                                            <CardHeader
                                                action={
                                                    <IconButton aria-label="settings">
                                                        <DeleteIcon onClick={() => deleteStructureClick(structure.id)}/>
                                                    </IconButton>
                                                }
                                                title={structure.name}
                                                subheader={structure.type}
                                            />
                                        </CardStructure>
                                    ))
                                : ''
                                }
                            </DivCard>
                            <Search
                                val={values.structures}
                                onSearch={value => displayStructures(value)}
                                label='structuremembers.search_structure_placeholder'
                                placeholder='roso.search.structure'
                                value={searchStructure}
                            />
                            {
                                structuresAdd.length > 0 && searchStructure != "" ?
                                    <StyledTable
                                        headers={STRUCTURES_MEMBERS_ADD_STRUCTURE}
                                        name='structures'
                                        data={structuresAdd
                                            .map(structure => ({
                                                ...structure,
                                                add: (
                                                    <div>
                                                        <StyledButtonConfirm onClick={() => addStructure(structure)}>
                                                            <AddHomeIcon style={{color:"rgb(102, 51, 255)",backgroundColor:"white"}}></AddHomeIcon>
                                                        </StyledButtonConfirm>
                                                    </div>
                                                ),
                                            }))}
                                    /> : ''

                            }
                            {duplicateStructure ? <Error><FormattedMessage id='roso.error.duplicate.structure'/></Error> : ''}

                            <Label>
                                <FormattedMessage id="roso.field.skills"></FormattedMessage>
                            </Label>
                            <Search
                                val={values.skills}
                                onSearch={value => displaySkills(value)}
                                label='roso.field.search.skills'
                                placeholder='roso.field.search.skills'
                                value={searchSkill}
                            />
                            {
                                listSkill.length > 0 && searchSkill != "" ?
                                    <StyledTable
                                        headers={SKILLS_TEAM_ADD}
                                        name='skills'
                                        data={listSkill
                                            .map(skill => ({
                                                ...skill,
                                                add: (
                                                    <div>
                                                        <StyledButtonConfirm onClick={() => addSkill(skill)}>
                                                            <HowToRegIcon style={{color:"rgb(102, 51, 255)",backgroundColor:"white"}}></HowToRegIcon>
                                                        </StyledButtonConfirm>
                                                    </div>
                                                ),
                                            }))}
                                    /> : ''

                            }
                            <DivCard>
                                { skillsToShow.length > 0 ?
                                    skillsToShow.map((skill) => (
                                        <TagItem>
                                            {skill.label} <DeleteSkillIcon onClick={() => deleteSkillClick(skill.value)} />
                                        </TagItem>
                                    ))
                                 : ''}

                            </DivCard>
                            {duplicateSkill ? <Error><FormattedMessage id='roso.error.duplicate.skill'/></Error> : ''}
                            {maxSkills ? <Error><FormattedMessage id='roso.error.max.skill'/></Error> : ''}
                            {requertError ? <Error><FormattedMessage id='roso.error'/></Error> : ''}
                            <DivButton className="buttons">
                                {
                                  noDiscussion && (
                                    <StyledButton
                                      type='button'
                                      style={{ marginRight: '50px' }}
                                      disabled={creatingDiscussion || submitting}
                                      onClick={() => onCreateDiscussion()}
                                    >
                                        Créer la discussion d'équipe
                                    </StyledButton>
                                  )
                                }
                                <StyledButton type="submit" disabled={submitting || creatingDiscussion}>
                                    {formatMessage({id: 'global.save'})}
                                </StyledButton>
                            </DivButton>
                        </form>
                    )}
                />
            ) : ''}
        </Wrapper>
    )
}
