/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { css } from '@emotion/react'

import LogoIcon from 'assets/images/logo.png'
import { Theme } from 'utils/styled'
import { theme } from "../../App";
import styled from 'styled-components'

const titleStyles = (theme: Theme, small?: boolean) => css`
  color: ${theme.palette.common.white};
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: ${small ? '24px' : '2rem'};
  animation-delay: .2s;
`

const logoStyles = css`
  height: 36px;
  margin-right: 16px;
`

const LogoConex = styled.div`
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-image:  ${`url(${LogoIcon})`};
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: center center;
`

interface LogoProps {
  small?: boolean,
}

export const Logo: React.FC<LogoProps> = ({ small }) => (
    <LogoConex/>
)
