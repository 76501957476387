/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {useState} from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { Pagination } from 'interfaces'
import get from 'lodash/get';
//#region Styles
const wrapperStyles = css`
  && {
    margin-top: 24px;
  }
`

const rowStyles = css`
  && {
    cursor: pointer;
  }
`
//#endregion

export const DoctorsTable = ({
    headers,
    data,
    pagination,
    onPageChange,
    onPerPageChange,
    onRowClick,
    sortBy,
    sortOrder,
    requestSort,
    activeTab,
}) => {
    const handlePageChange = (event: unknown, newPage: number) => (
        onPageChange && onPageChange(newPage + 1)
    )
    const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onPerPageChange) return
        const newPerPage = parseInt(event.target.value, 10)
        onPerPageChange(newPerPage)
    }
    const formatDate = (date:any) => {
        return date.getFullYear() + "-" +  ("0"+(date.getMonth()+1)).slice(-2)  + "-" + ("0" + date.getDate()).slice(-2)+" "+
            ("0" + date.getHours()).slice(-2)+ ":"+("0" + date.getMinutes()).slice(-2)+":"+("0" + date.getSeconds()).slice(-2);
    }

    data.forEach(function(value:any) {
        if(value.register_date && (!value.register_date_utc)){
            value.date_register_format  =new Date (value.register_date)
            value.register_date = formatDate(new Date(Date.UTC(value.date_register_format.getFullYear(), value.date_register_format.getMonth(),
                value.date_register_format.getDate(), value.date_register_format.getHours(),
                value.date_register_format.getMinutes(), value.date_register_format.getSeconds())));
            value.register_date_utc = true;
        }
        if(value.approve_date && (!value.approve_date_utc)){
            value.date_approve_format = new Date(value.approve_date)
            value.approve_date = formatDate(new Date(Date.UTC(value.date_approve_format.getFullYear(), value.date_approve_format.getMonth(),
                value.date_approve_format.getDate(), value.date_approve_format.getHours(),
                value.date_approve_format.getMinutes(), value.date_approve_format.getSeconds())));
            value.approve_date_utc = true;
        }
    })


    return (
        <Paper css={wrapperStyles}>
            <TableContainer>
                <MuiTable stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key={'last_name'}>
                                <TableSortLabel active={sortBy === "last_name"} onClick={() => requestSort("last_name")} direction={sortOrder.toLowerCase()} hideSortIcon>
                                    <FormattedMessage id={`tables.doctors.last_name`} />
                                </TableSortLabel>
                            </TableCell>

                            <TableCell key={'first_name'}>
                                <FormattedMessage id={`tables.doctors.first_name`} />
                            </TableCell>

                            <TableCell key={'specialization'}>
                                <FormattedMessage id={`tables.doctors.specialization`} />
                            </TableCell>

                            <TableCell key={'email'}>
                                <FormattedMessage id={`tables.doctors.email`} />
                            </TableCell>

                            <TableCell key={'phone_number'}>
                                <FormattedMessage id={`tables.doctors.phone_number`} />
                            </TableCell>

                            <TableCell key={'postcode'}>
                                <FormattedMessage id={`tables.doctors.postcode`} />
                            </TableCell>

                            <TableCell key={'city'}>
                                <FormattedMessage id={`tables.doctors.city`} />
                            </TableCell>

                            <TableCell key={'register_date'}>
                                <TableSortLabel active={sortBy === "register_date"} onClick={() => requestSort("register_date")} direction={sortOrder.toLowerCase()} hideSortIcon>
                                    <FormattedMessage id={`tables.doctors.register_date`} />
                                </TableSortLabel>
                            </TableCell>
                            {activeTab === 1 &&
                                <TableCell key={'status_formatted'}>
                                    <TableSortLabel active={sortBy === "status"} onClick={() => requestSort("status")} direction={sortOrder.toLowerCase()} hideSortIcon>
                                        <FormattedMessage id={`tables.doctors.status_formatted`} />
                                    </TableSortLabel>
                                </TableCell>
                            }
                            {activeTab === 0 &&
                              <TableCell key={'approve_date'}>
                                <TableSortLabel active={sortBy === "approve_date"} onClick={() => requestSort("approve_date")} direction={sortOrder.toLowerCase()} hideSortIcon>
                                    <FormattedMessage id={`tables.doctors.approve_date`} />
                                </TableSortLabel>
                              </TableCell>
                            }


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(row => (
                                <TableRow
                                    key={row.id}
                                    css={rowStyles}
                                    hover={!!onRowClick}
                                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                                >
                                    {
                                        headers.map(header => {
                                            let value = get(row, header, '-')
                                            if(!(activeTab == 0 && header == 'status_formatted') && !(activeTab == 1 && header == 'approve_date')){
                                                return (
                                                    <TableCell key={header + row.id}>
                                                        {Array.isArray(value) ? value.join(', ') : value}
                                                    </TableCell>
                                                )
                                            }
                                        })
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
            {!!(pagination && pagination.total) && (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component='div'
                    count={pagination.total}
                    rowsPerPage={pagination.limit}
                    page={pagination.currentPage - 1}
                    onPageChange={handlePageChange}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageChange}
                />
            )}
        </Paper>
    )
}
