import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { Pagination, ThunkResult } from 'interfaces'
import { AnyAction, combineReducers } from 'redux'
import {StructureMembersFilters, StructureMembersPayload} from "interfaces/structuresmembers";
import {normalizeStructureMembers} from "helpers/structureMembers";

//#region Actions
export enum StructuresMembersActions {
    fetchStructuresMembersRequest = 'FETCH_STRUCTURES_MEMBERS_REQUEST',
    fetchStructuresMembersSuccess = 'FETCH_STRUCTURES_MEMBERS_SUCCESS',
    fetchStructuresMembersFailure = 'FETCH_STRUCTURES_MEMBERS_FAILURE',

    setStructuresMembersFilters = 'SET_STRUCTURE_MEMBERS_FILTER',
    setStructuresMembersAddFilters = 'SET_STRUCTURE_MEMBERS_ADD_FILTER',

}

export const fetchStructuresMembersRequest = createAction(StructuresMembersActions.fetchStructuresMembersRequest)
export const fetchStructuresMembersSuccess = createAction(StructuresMembersActions.fetchStructuresMembersSuccess)
export const fetchStructuresMembersFailure = createAction(StructuresMembersActions.fetchStructuresMembersFailure)


export const setStructuresMembersFilters = createAction(StructuresMembersActions.setStructuresMembersFilters)
//#endregion

//#region Reducer
const list = handleActions({
    [StructuresMembersActions.fetchStructuresMembersSuccess]: (state, action: AnyAction) => action.payload.structuresMembers,
}, [])

const filters = handleActions({
    [StructuresMembersActions.setStructuresMembersFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "name", sortOrder: "DESC",structure:''})

const filtersAdd = handleActions({
    [StructuresMembersActions.setStructuresMembersFilters]: (state, action) => action.payload,
}, { page: 1, limit: 10, status: 1, sortBy: "name", sortOrder: "DESC",name:''})


export const structuresMembersReducer = combineReducers({
    list,
    filters,
    filtersAdd
})
//#endregion

//#region Thunks

export const fetchStructuresMembers = (
    params: StructureMembersFilters,
    id: number | string,
): ThunkResult<Promise<void | Pagination>> => (
    dispatch,
) => {
    dispatch(fetchStructuresMembersRequest())

    type Response = { paginator: Pagination, data: StructureMembersPayload[] }
    return axios.request<Response>({
        url: `/api/doctor/structure/${id}/members/v2`,
        params: {
            ...params,
            language: ['en', 'fr', 'ru', 'de'],
        },
    })
        .then(response => {
            const structuresMembers: StructureMembersPayload[] = response.data.data
            dispatch(fetchStructuresMembersSuccess({
                members:structuresMembers.map(member => normalizeStructureMembers(member)),
                pagination: response.data.paginator,
            }))
        })
        .catch((err) => {
            dispatch(fetchStructuresMembersFailure(err))
        })
}



//#endregion
