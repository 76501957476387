import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import styled from '@emotion/styled'
import { fetchSkills } from 'ducks/modules/skills';
import { useSelector, useDispatch } from 'react-redux';
import { Search } from 'components/common/Search'
import { Content } from 'components/common/Content'
import { State, Pagination } from 'interfaces';
import { SkillItem } from 'interfaces/skills';
import { SKILLS_TABLE_HEADERS } from 'constants/tables'
import { Table } from 'components/common/Table'
import { validateSkill, unValidateSkill, deleteSkillRequest } from 'helpers/skills';
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import EditIcon from "@mui/icons-material/Edit";
import {Colors} from "../../../constants/colors";
import { Button } from 'components/common/Button'
import { DeleteSkillDialog} from './DeleteSkillDialog';

//#region Styles
const StyledName = styled.div`
  word-break: break-all;
`
const StyledButtonModify = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
   &:hover:not(.active) {
    background-color: ${Colors.menuHover};
  }
`

const StyledButtonValidate = styled(Button)`
  border-radius: 50px !important;
`

const StyledButtonDelete = styled(Button)`
  background-color: ${Colors.redDelete};
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.watermelon};
  }
`
const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
`
const HeaderStructures = styled.div`
  display: flex;
  justify-content: space-between;
`
//#endregion

export const SkillsList = () => {
  const [filters, setFilter] = useState({ name: '', limit: 10, page: 1 });
  const dispatch = useDispatch();
  const skills = useSelector<State, SkillItem[]>(state => state.skills.list);
  const pagination = useSelector<State, Pagination>(state => state.pagination.skills)
  const history = useHistory();
  const [deleteSkillDialog, setDeleteSkillDialog] = useState(null)
  const [openDelete, setOpenDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState('')

    const handleClickDelete = (value:string) => {
        setIdToDelete(value);
        setOpenDelete(true)
    };

    const handleCloseDelete = (value:number| null) => {
        setOpenDelete(false);
        if(value){
            deleteSkill(value.toString())
        }
    }


  useEffect(() => {
    dispatch(setActiveMenu('global.menu.skills'))
    dispatch(setIsPrincipalPage(true))
  }, )

  useEffect(() => {
    dispatch(fetchSkills(filters));
  }, [dispatch, filters])

  const onSearch = (name: string) => setFilter({ ...filters, name });

  const onPageChange = (page: number) => {
    setFilter({ ...filters, page })
  }

  const onPerPageChange = (limit: number) => setFilter({ ...filters, limit, page: 1 })
  const approveSkill = (skillId: string) => validateSkill(skillId)
    .then(() => dispatch(fetchSkills(filters)))
    .catch(err => console.warn(err))
  const unApproveSkill = (skillId: string) => {
    unValidateSkill(skillId)
      .then(() => dispatch(fetchSkills(filters)))
      .catch(err => console.warn(err))
  }

  const deleteSkill = (skillId: string) => {
    deleteSkillRequest(skillId)
      .then(() => dispatch(fetchSkills(filters)))
      .catch(err => console.warn(err))
  }

  return (
    <Content>
        <HeaderStructures>
      <Search
        onSearch={onSearch}
        label='global.skills'
      />
        <Link to="/skills/new">
            <StyledButton color="primary" type="submit">
                <FormattedMessage id="skill.new" />
            </StyledButton>
        </Link>
        </HeaderStructures>
      <Table
        headers={SKILLS_TABLE_HEADERS}
        name='skills'
        data={skills
          .map(el => ({
            ...el,
            name: (
              <StyledName>
                {el.attributes.name}
              </StyledName>
            ),
              modify: (
                  <StyledButtonModify onClick={() =>  history.push(`/skills/${el.id}`)} variant='contained' startIcon={<EditIcon />}>
                      <FormattedMessage id="skill.modify" />
                  </StyledButtonModify>
              ),
            validate: (
              <StyledButtonValidate
                onClick={() => (
                  !el.attributes.validated ? approveSkill(el.id) : unApproveSkill(el.id)
                )} 
                color={!el.attributes.validated ? 'success' : 'inherit'}
                variant='contained'
              >
                <FormattedMessage id={!el.attributes.validated ? 'skills.validate' : 'skills.unValidate'} />
              </StyledButtonValidate>
            ), 
            delete: (
                <div>
                    <StyledButtonDelete onClick={() => (handleClickDelete(el.id))} color='inherit' variant='contained'>
                        <FormattedMessage id="global.delete" />
                    </StyledButtonDelete>
                </div>
            ),
          }))}
        pagination={pagination}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
        <div>
            <DeleteSkillDialog
                open={openDelete}
                onClose={handleCloseDelete}
                data={idToDelete}
            />
        </div>
    </Content>
  )
}
