/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {useState} from 'react'
import { css } from '@emotion/react'
import { useEffect,  } from 'react'

import { useDispatch,  } from 'react-redux'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
/** @jsxImportSource @emotion/react */
import {FormattedMessage} from "react-intl";
import { Button } from 'components/common/Button'
import {Alert, Snackbar} from "@mui/material";
import {sendNotificationConsultation} from "../../../helpers/notification";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import {Colors} from "../../../constants/colors";

const container = css`
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top : 10%;
    margin-left:10%;
    margin-right:10%;
`;

const title = css`
    text-align:center;
    margin-top:5%;
    font-size:2em;
    font-weight:bold;
`
const StyledButton = styled(Button)`
    margin-left:20px;
`


export const Notifications: React.FC = () => {
    const dispatch = useDispatch()
    const [numberSend, numberSendSet] = useState(null);
    const [openSnackBar, openSnackBarSet] = useState(false);
    const [openSnackBarZero, openSnackBarZeroSet] = useState(false);
    const [openSnackBarError, openSnackBarErrorSet] = useState(false);
    const [fetching, setFetching] = useState(false)

    const resendFollowup = () =>{
        setFetching(true)
        sendNotificationConsultation()
            .then((result) =>
                {
                    setFetching(false);
                    numberSendSet(result.data.length)
                    if(result.data.length > 0){
                        openSnackBarSet(true)
                    }
                    else {
                        openSnackBarZeroSet(true)
                    }
                }
            )
            .catch(err => {
                setFetching(false);
                openSnackBarErrorSet(true)
            })
    }

    useEffect(() => {
        dispatch(setActiveMenu('global.menu.notifications'))
        dispatch(setIsPrincipalPage(true))
    }, [])
    return (
        <div>
            <Snackbar open={openSnackBar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarSet(false)}>
                <Alert  severity="success" sx={{ width: '100%' }} onClose={() => openSnackBarSet(false)}>
                    {numberSend} <FormattedMessage id="notifications.snackbar"/>
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarZero} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarZeroSet(false)}>
                <Alert  severity="info" sx={{ width: '100%' }} onClose={() => openSnackBarZeroSet(false)}>
                    <FormattedMessage id="notifications.snackbar.zero"/>
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarErrorSet(false)}>
                <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarErrorSet(false)}>
                    <FormattedMessage id="notifications.snackbar.error"/>
                </Alert>
            </Snackbar>
            <div>
                <h1 css={title}>
                    <FormattedMessage id="notifications.title"/>
                </h1>
            </div>
            <div css={container}>
                { !fetching  ?
                    <span >
                        <span >
                           <FormattedMessage id="notifications.followup"/>
                        </span>
                        <StyledButton  color='primary' variant='contained' onClick={resendFollowup}>
                            <FormattedMessage id="notifications.followup.button" />
                        </StyledButton>
                    </span>:
                    <CircularProgress />
                }
            </div>
        </div>

    )
}