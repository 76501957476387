import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DescriptionIcon from '@material-ui/icons/Description'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'

import { FormattedMessage } from 'react-intl';
import { TextField } from 'components/common/UiKit/TextField'
import { Colors } from '../../../../constants/colors.ts'

const PreviewWrapper = styled.div`
  width: auto;
  max-height: 158px;
  min-height: 80px;
  max-width: 280px;
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  
  > svg {
    color: ${Colors.blueGrey};
    width: 70%;
    height: 70%;
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: inherit;
`

const ButtonStyled = styled(Button)`
  && {
    font-weight: 500;
    font-size: 0.875rem;
  }
`

const Content = styled(DialogContent)`
  && {
    padding: 0 32px !important;
  }
`

const Actions = styled(DialogActions)`
  && {
    margin: 8px 16px 16px;
  }
`

const Title = styled(DialogTitle)`
  && {
    text-align: center;
    
    h6 {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
`

export const FilenameForm = ({ file, onSubmit, onClose }) => {
  const validate = values => {
    const errors = {}
    
    if (!values.custom_name) {
      errors.custom_name = 'This field is required'
    }
    
    return errors
  }
  
  return (
    <Dialog open={!!file} onClose={onClose}>
      <Title>
        <FormattedMessage id='hr.upload' />
      </Title>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          custom_name: file.custom_name || '',
        }}
        validate={validate}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Content>
              <PreviewWrapper>
                {
                  (!file.extension && file.type)
                    ? (
                      <>
                        {
                          file.type.includes('image')
                            ? <Image src={URL.createObjectURL(file)} alt='New file' />
                            : <DescriptionIcon />
                        }
                      </>
                    )
                    : (
                      <>
                        {
                          ['jpg', 'png'].includes(file.extension)
                            ? <Image alt='img' src={file.image} />
                            : <DescriptionIcon />
                        }
                      </>
                    )
                }
              </PreviewWrapper>
              <Field
                name='custom_name'
                component={TextField}
                label={<FormattedMessage id='hr.filename' />}
                helperText={<FormattedMessage id='hr.filename.description' />}
                fullWidth
                autoFocus
              />
            </Content>
            <Actions>
              <ButtonStyled onClick={onClose} color='secondary' size='small'>
                <FormattedMessage id='global.cancel' />
              </ButtonStyled>
              <ButtonStyled
                color='secondary'
                size='small'
                variant='contained'
                type='submit'
                disabled={hasValidationErrors || submitting}
              >
                <FormattedMessage id='hr.upload.button' />
              </ButtonStyled>
            </Actions>
          </form>
        )}
      />
    </Dialog>
  )
}
