import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Create from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import {FormattedMessage, useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { Paper } from '../Paper'
import Select from "@material-ui/core/Select";

const PaperStyled = styled(Paper)`
  padding: 10px 24px;
`

const Line = styled.div`
  padding: 8.5px 0;
  display: flex;
  opacity: 0.87;
`

const Name = styled.span`
  font-weight: bold;
  // width: 172px;
  margin-right: 8px;
`

const StyledSelect = styled(Select)`
  width: 200px;
`


export const RIB = ({   iban,
                        bic,
                          }) => {
    return (
        <PaperStyled
            heading={'profile.rib'}
        >
            <Line>
                <Name>
                    <FormattedMessage id='profile.iban' /> {":"}
                </Name>
                {iban}
            </Line>
            <Line>
                <Name>
                    <FormattedMessage id='profile.bic' /> {":"}
                </Name>
                {bic}
            </Line>
        </PaperStyled>
    )
}

RIB.defaultProps = {
    bic : "",
    iban : "",
}

RIB.propTypes = {
    bic : PropTypes.string,
    iban : PropTypes.string,
}
