import React from 'react'
import { Input } from 'components/common/UiKit/Input'

export const MultilineTextField = ({ input, meta, ...rest }) => (
  <Input
    {...input}
    error={meta.touched && meta.error}
    helperText={meta.touched && meta.error}
    {...rest}
  />
)
