/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { DashboardGeneral } from 'interfaces/dashboard'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { Colors } from 'constants/colors'
import Divider from '@material-ui/core/Divider'

//#region Styles
const wrapperStyles = css`
  display: flex;
  margin-bottom: 40px;
`

const dividerStyles = css`
  && {
    align-self: stretch;
    height: auto;
    margin-left: 32px;
    margin-right: 32px;
  }
`

const titleStyles = css`
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.blue};
  margin-bottom: 16px;
  letter-spacing: -0.5px;
`

const totalConsultationsStyles = css`
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.grey};
`

const percentStyles = css`
  font-size: 14px;
  color: ${Colors.grey};
  margin-top: 6px;
  margin-bottom: 24px;
`

const countWrapperStyles = (color: string) => css`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${Colors.grey};
  padding-left: 16px;
  position: relative;
  font-weight: 500;
  margin-bottom: 8px;
  
  &:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${color};
    left: 0;
  }
  
  & > div:first-of-type {
    width: 140px;
  }
`
//#endregion

interface ChartTotalProps {
  data: DashboardGeneral,
}

export const getPercent = (value: any, total: any) => Math.round((value / total) * 100) || 0

export const ChartTotal: React.FC<ChartTotalProps> = ({ data }) => {
  const sheduledPercent = getPercent(data.scheduled, data.total_consultation)
  const urgentPercent = getPercent(data.urgent, data.total_consultation)
  const tePercent = getPercent(data.telexpertise, data.total_consultation)
  const urgentTePercent = getPercent(data.urgent_telexpertise, data.total_consultation)

  const scheduledCostPercent = getPercent(data.scheduled_cost, data.total_cost)
  const urgentCostPercent = getPercent(data.urgent_cost, data.total_cost)
  const teCostPercent = getPercent(data.telexpertise_cost, data.total_cost)
  const urgentTECostPercent = getPercent(data.urgent_telexpertise_cost, data.total_cost)

  return (
    <div css={wrapperStyles}>
      <div>
        <div css={titleStyles}>
          <FormattedMessage id='statistics.total.consultations' />
        </div>
        <div css={totalConsultationsStyles}>
          {data.total_consultation}
        </div>
        <div css={percentStyles}>100%</div>
        <div css={() => countWrapperStyles(Colors.blue)}>
          <div>
            <FormattedMessage id='statistics.by.appointment' />
          </div>
          <div>
            {data.scheduled}
            {' ('}
            {sheduledPercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.menuActive)}>
          <div>
            <FormattedMessage id='statistics.urgent' />
          </div>
          <div>
            {data.urgent}
            {' ('}
            {urgentPercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.green)}>
          <div>
            <FormattedMessage id='statistics.telexpertise' />
          </div>
          <div>
            {data.telexpertise}
            {' ('}
            {tePercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.red)}>
          <div>
            <FormattedMessage id='statistics.urgent_telexpertise' />
          </div>
          <div>
            {data.urgent_telexpertise}
            {' ('}
            {urgentTePercent}
            %)
          </div>
        </div>
      </div>
      <Divider orientation='vertical' css={dividerStyles} />
      <div>
        <div css={titleStyles}>
          <FormattedMessage id='statistics.total.cost' />
        </div>
        <div css={totalConsultationsStyles}>
          {data.total_cost}
          €
        </div>
        <div css={percentStyles}>100%</div>
        <div css={() => countWrapperStyles(Colors.blue)}>
          <div>
            <FormattedMessage id='statistics.by.appointment' />
          </div>
          <div>
            {data.scheduled_cost}
            € (
            {scheduledCostPercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.menuActive)}>
          <div>
            <FormattedMessage id='statistics.urgent' />
          </div>
          <div>
            {data.urgent_cost}
            € (
            {urgentCostPercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.green)}>
          <div>
            <FormattedMessage id='statistics.telexpertise' />
          </div>
          <div>
            {data.telexpertise_cost}
            € (
            {teCostPercent}
            %)
          </div>
        </div>
        <div css={() => countWrapperStyles(Colors.red)}>
          <div>
            <FormattedMessage id='statistics.urgent_telexpertise' />
          </div>
          <div>
            {data.urgent_telexpertise_cost}
            € (
            {urgentTECostPercent}
            %)
          </div>
        </div>
      </div>
    </div>
  )
}
