/** @jsxImportSource @emotion/react */
import {jsx} from '@emotion/react'
import React, {useEffect, useState} from 'react';
import {Form, Field} from 'react-final-form'
import {mapProps} from 'recompose'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {fetchDoctorProfile} from 'ducks/modules/doctors'
import {profileSelector} from 'selectors/doctor'
import {State} from 'interfaces'
import {FormattedMessage, useIntl} from 'react-intl'

import {Button} from '@material-ui/core'
import {Preloader} from 'components/common/Preloader'
import {makeStyles} from '@material-ui/styles';
import Input from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {css} from '@emotion/react'
import get from 'lodash/get'
import axios from 'axios'
import {Paper} from '../../../common/UiKit/Paper'

import {Theme} from '@material-ui/core';
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";
import {HandleProfile} from "./HandleProfile";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

declare module '@material-ui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {
    }
}

interface ManageProfesionalDataProps {
    id: number | string,
}

const useStyles = makeStyles((theme) => ({
    margin: {
        // margin: theme.spacing(1),
        width: '400px',
    },
}));

const PaperStyle = css`
  margin-top: 15px;
  padding: 15px;
  box-shadow: rgba(6, 173, 255, 0.1) 0px 4px 8px 0px;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 28px auto;
  padding: 30px;
`
const changeButton = css`
height: 40px;
width: 250px;
`

const TextField = css`
  height: 40px;
  width: 500px;
  background-color: ${Colors.background};
`

const Wrapper = styled.div`
  margin-top: 15px;
  width: 70%;
  justify-content: space-around;
  align-items: center;
  margin: 28px auto;
`
const Content = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction:column;
  align-items: center;
`

const StyledSelect = styled(Select)`
  width: 200px;
`

const Cssbutton = styled.div`
  display: flex;
  height: 70px;
  padding: 0;
  margin: 0;
  justify-content: end;
  align-items: end;
`

const Line = styled.div`
  width: 650px;
  padding: 8.5px 0;
  display: flex;
  justify-content: space-between;
  align-items : center;
`

const Error = styled.div`
    padding-top: 3px;
    color: red;
    font-size : 12px;
`

const LineSpe = styled(Line)`
  width: 350px;
`

const Label = styled.span`
  font-weight: bold;
  // width: 172px;
  margin-right: 8px;
`

const PaperStyled = styled(Paper)`
  ${props => (props.small ? 'padding: 18px 16px' : 'padding-top: 24px')}
`

export const ManageProfesionalData: React.FC<ManageProfesionalDataProps> = ({
        id,
        setUserWantingToModifyAboutDoctorData
    }) => {
    const profileRemote = useSelector<State, any>(profileSelector(id))
    const defaultProfile = get(profileRemote, 'attributes', '');
    const {formatMessage} = useIntl()
    const dispatch = useDispatch()

    const workplace = profileRemote.relationships.workPlace.data.length
        ? profileRemote.relationships.workPlace.data[0].attributes
        : {
            name: '',
            postcode: '',
            street: '',
            city: '',
            country: ''
        }
    const isDROrPRGender = (defaultProfile.specialization_key !== 'Midwife' &&
            defaultProfile.specialization_type === 'others') ||
        defaultProfile.specialization_type === 'generalist' ||
        defaultProfile.specialization_type === 'pharmaceutical' ||
        defaultProfile.specialization_type === 'specialist'

    const defaultGender = isDROrPRGender ? (defaultProfile.gender === 'doctor' ? 'Dr' : 'Pr') : (defaultProfile.gender === 'male' ? 'Mr' : 'Mme')
    const tabGender = isDROrPRGender ? ['Dr', 'Pr'] : ['Mr', 'Mme'];
    const [specializations, setSpecializations] = useState([]);
    const [doctorSpecializationId, setDoctorSpecializationId] = useState(0);
    const [myArrayProfileProData, updateMyArrayProfileProData] = useState(HandleProfile(defaultProfile, workplace, isDROrPRGender));
    const [myArrayProfileProDataError, setMyArrayProfileProDataError] = useState({
        rpps: '',
        orderNumber: '',
        adeli: '',
        worplaceName: '',
        postcode: '',
        street: '',
        city: '',
        country: '',
        gender: ''
    });
    const defaultProfileFiltered = HandleProfile(defaultProfile, workplace, isDROrPRGender)

    useEffect(() => {
        dispatch(fetchDoctorProfile(id))
    }, [id, doctorSpecializationId, myArrayProfileProDataError])

    if (!profileRemote) {
        return <Preloader/>
    }

    const validate = (values) => {
        const error = {first_name: '', last_name: '', rpps: '', orderNumber: '', adeli: '', worplaceName: '', postcode: '',street: '', city: '', country: '', gender: ''}
        let isError = false

        if(values.first_name === "") {
            error.first_name = 'Un prénom doit être renseigné'
            isError = true
        }
        if(values.last_name === "") {
            error.last_name = 'Un nom doit être renseigné'
            isError = true
        }
        if (!!values.rpps && values.rpps.length != 11 ) {
            error.rpps = 'Le RPPS doit être composé de 11 chiffres'
            isError = true;
        }
        if ( !!values.adeli && ((values.adeli.length > 0 && values.adeli.length < 9) || values.adeli.length > 9)) {
            error.adeli = 'Le numéro ADELI doit être composé de 9 chiffres';
            isError = true;
        }
        if (!!values.orderNumber && ((values.orderNumber.length > 0 && values.orderNumber.length < 9) || values.orderNumber.length > 9)) {
            error.orderNumber = 'Le numéro FINESS doit être composé de 9 caractères';
            isError = true;
        }
        if ( (values.orderNumber === "" || values.orderNumber === null) && (values.adeli === "" || values.adeli === null) && (values.rpps === "" || values.rpps === null) ) {
            error.orderNumber = 'Au moins un numéro professionel doit être choisi';
            error.adeli = 'Au moins un numéro professionel doit être choisi';
            error.rpps = 'Au moins un numéro professionel doit être choisi';
            isError = true;
        }

        if (values.postcode.length > 5 || values.postcode.length < 2) {
            error.postcode = 'Veuillez choisir une valeur correcte de code postal';
            isError = true;
        }
        if (values.gender == '') {
            error.gender = 'Veuillez choisir une valeur correcte de civilité';
            isError = true;
        }
        if ((values.gender == 'Dr' || values.gender == 'Pr') && !isDROrPRGender) {
            error.gender = 'Veuillez choisir une valeur correcte de civilité';
            isError = true;
        }
        if ((values.gender == 'Mr' || values.gender == 'Mme') && isDROrPRGender) {
            error.gender = 'Veuillez choisir une valeur correcte de civilité';
            isError = true;
        }

        if (values.worplaceName === '') {
            error.worplaceName = 'Veuillez choisir une valeur correcte de l\'adresse';
            isError = true;
        }
        if (values.street === '') {
            error.street = 'Veuillez choisir une valeur correcte de la rue';
            isError = true;
        }
        if (values.country === '') {
            error.country = 'Veuillez choisir une valeur correcte de pays';
            isError = true;
        }
        if (values.city === '') {
            error.city = 'Veuillez choisir une valeur correcte de ville';
            isError = true;
        }
        if (values.country === '') {
            error.country = 'Veuillez choisir une valeur correcte de pays';
            isError = true;
        }
        setMyArrayProfileProDataError(error)
        return isError;
    }

    useEffect(() => {
        setDoctorSpecializationId(defaultProfile.specialization_id)
        axios('/general/specialization')
            .then(result => {
                const specializationsRaw = get(result, 'data.data', []);
                const specializationsOptions = specializationsRaw
                    .map((el: { id: any; }) => ({
                        name: get(el, 'attributes.name', 'unknown'),
                        id: el.id,
                    }))
                setSpecializations(specializationsOptions)
            })
            .catch(err => console.warn(err))
    }, [])

    const changeSpecializationSelect = (specializationId: React.SetStateAction<number>) => {
        setDoctorSpecializationId(specializationId);
        axios.post(`/api/admin/doctor/profile/edit/specialization/${id}/${specializationId}`)
    }


    const changeProfileProfesionalData = () => {
        if(myArrayProfileProData.rpps == ''){
            myArrayProfileProData.rpps = null
        }
        axios.post(`/admin/doctor/${id}/profile/profesionaldata`, myArrayProfileProData)
            .then((res) => {
                res &&
                setMyArrayProfileProDataError( { ...myArrayProfileProDataError , 'rpps' : res.data.message})
                setUserWantingToModifyAboutDoctorData(false)
                }
            )
            .catch(err => {
                err.response.data.message ? setMyArrayProfileProDataError( { ...myArrayProfileProDataError , rpps : 'RPPS déjà utilisé par un autre utilisateur'}) : console.warn(err)
                }
            )
    }


    return (
        <>
            <Wrapper>
                <PaperStyled
                    small={true}
                    heading={"profile.change_name"}
                    style={{marginBottom: '28px'}}
                >
                    <Content>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.first.name'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='first_name'
                                    defaultValue={defaultProfileFiltered.first_name}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        first_name: e.target.value
                                    })}
                                    id="first_name"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.first_name}</Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.last.name'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='last_name'
                                    defaultValue={defaultProfileFiltered.last_name}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        last_name: e.target.value
                                    })}
                                    id="last_name"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.last_name}</Error>
                            </FormControl>
                        </Line>
                    </Content>
                </PaperStyled>

                <PaperStyled
                    small={true}
                    heading={"profile.change_specialization"}
                    style={{marginBottom: '28px'}}
                >
                    <Content>
                        <LineSpe>
                            <Label>
                                <FormattedMessage id='profile.specialization'/> {":"}
                            </Label>
                            <FormControl>
                                <StyledSelect
                                    placeholder={formatMessage({id: 'profile.specialization'})}
                                    onChange={(e) => changeSpecializationSelect(e.target.value)}
                                    options={specializations}
                                    value={doctorSpecializationId}
                                >
                                    {specializations.map(el => <MenuItem key={el.id}
                                                                         value={el.id}>{el.name}</MenuItem>)}
                                </StyledSelect>
                            </FormControl>
                        </LineSpe>
                        <LineSpe>
                            <Label>
                                <FormattedMessage id='profile.title'/> {":"}
                            </Label>
                            <FormControl>
                                <StyledSelect
                                    placeholder={defaultGender}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        gender: e.target.value
                                    })}
                                    options={isDROrPRGender ? ['Dr', 'Pr'] : ['Mr', 'Mme']}
                                    value={myArrayProfileProData.gender}
                                >
                                    {tabGender.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
                                </StyledSelect>
                            </FormControl>
                        </LineSpe>
                        <Error>{myArrayProfileProDataError.gender}</Error>
                    </Content>
                </PaperStyled>

                <PaperStyled
                    small={true}
                    heading={"profile.change_credentials"}
                    style={{marginBottom: '28px'}}
                >
                    <Content>
                        <InputLabel htmlFor="Numéros professionnels"></InputLabel>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.rpps'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='RPPS'
                                    defaultValue={defaultProfileFiltered.rpps}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        rpps: e.target.value
                                    })}
                                    id="RPPS"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.rpps}</Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.adeli'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='AM/ADELI'
                                    defaultValue={defaultProfileFiltered.adeli}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        adeli: e.target.value
                                    })}
                                    id="Change AM/ADELI"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.adeli}</Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.finess'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='FINESS'
                                    defaultValue={defaultProfileFiltered.orderNumber}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        orderNumber: e.target.value
                                    })}
                                    id="Change FINESS"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.orderNumber}</Error>
                            </FormControl>
                        </Line>
                    </Content>
                </PaperStyled>

                <PaperStyled small={true} heading={"profile.change_workplace"}>
                    <Content>
                        <InputLabel htmlFor="Lieu d'exercice"></InputLabel>
                        <Line>
                            <Label>
                                <FormattedMessage id='structures.field.address-simple'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='Nom'
                                    defaultValue={defaultProfileFiltered.worplaceName??null}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        worplaceName: e.target.value
                                    })}
                                    id="Change name"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.worplaceName} </Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.street'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='Adresse'
                                    defaultValue={defaultProfileFiltered.street??null}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        street: e.target.value
                                    })}
                                    id="Change adress"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.street} </Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.postcode'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='Code postal'
                                    defaultValue={defaultProfileFiltered.postcode??null}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        postcode: e.target.value
                                    })}
                                    id="Change CP"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.postcode}</Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.city'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='Ville'
                                    defaultValue={defaultProfileFiltered.city??null}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        city: e.target.value
                                    })}
                                    id="Change city"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.city}</Error>
                            </FormControl>
                        </Line>
                        <Line>
                            <Label>
                                <FormattedMessage id='profile.country'/> {":"}
                            </Label>
                            <FormControl>
                                <Input
                                    name='Pays'
                                    defaultValue={defaultProfileFiltered.country || 'France'}
                                    onChange={e => updateMyArrayProfileProData({
                                        ...myArrayProfileProData,
                                        country: e.target.value
                                    })}
                                    id="Change country"
                                    type='text'
                                    css={TextField}
                                />
                                <Error>{myArrayProfileProDataError.country}</Error>
                            </FormControl>
                        </Line>


                    </Content>
                </PaperStyled>
                <Cssbutton>
                    <Button
                        css={changeButton}
                        onClick={() => {
                            if (!validate(myArrayProfileProData)) { // s'il n'y a pas d'erreur, on change les infos
                                changeProfileProfesionalData()
                            }
                        }
                        }
                        color='primary'
                        variant='contained'
                    >
                        <FormattedMessage id="global.save"/>
                    </Button>
                </Cssbutton>
            </Wrapper>
        </>
    )
};



