import { createAction, handleActions } from 'redux-actions'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'
import { AuthActions } from 'ducks/modules/auth'

//#region Actions
export enum ProfileActions {
  fetchProfileRequest = 'PROFILE > LOGIN_REQUEST',
  fetchProfileSuccess = 'PROFILE > LOGIN_SUCCESS',
  fetchProfileFailure = 'PROFILE > LOGIN_FAILURE',
}

export const fetchProfileRequest = createAction(ProfileActions.fetchProfileRequest)
export const fetchProfileSuccess = createAction(ProfileActions.fetchProfileSuccess)
export const fetchProfileFailure = createAction(ProfileActions.fetchProfileFailure)
//#endregion

//#region Reducer
export const profileReducer = handleActions({
  [ProfileActions.fetchProfileSuccess]: (state, action) => action.payload,
  [AuthActions.logout]: () => ({}),
}, {})
//#endregion

//#region Thunks
export const fetchProfile = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchProfileRequest())
  try {
    const { data } = await axios('/admin/moderator/profile')
    dispatch(fetchProfileSuccess(data.data))
  } catch (err) {
    dispatch(fetchProfileFailure(err))
  }

  return undefined
}
//#endregion
