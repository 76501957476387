import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import {FormattedMessage, useIntl} from 'react-intl';
import {Button} from "../../../common/Button";
import {Colors} from "../../../../constants/colors";
import {Search} from "../../../common/Search";
import {
    addStructureMember,
    deleteStructureMember,
    getListMembers,
    getListStructureMembers,
    normalizeStructureMembers
} from "../../../../helpers/structureMembers";
import {Structure, StructureFilters} from "../../../../interfaces/structures";
import {StructureMembers, StructureMembersAddFilters, StructureMembersPayload} from "../../../../interfaces/structuresmembers";
import {STRUCTURES_MEMBERS_ADD_TABLE_HEADERS, STRUCTURES_MEMBERS_TABLE_HEADERS} from "../../../../constants/tables";
import {Table} from "../../../common/Table";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #209dff;
  margin-right: 20px;
`
const StyledButtonConfirm = styled(Button)`
  background-color: white !important;
  box-shadow:none !important;
`

const StyledDivCenter = styled.div`
  text-align:center;
  margin-top:1%;
`

const StyledTable = styled(Table)`
    box-shadow : 0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0);
`

export const AddStructureMemberDialog = ({
                                             open,
                                             onClose,
                                             data
                                         }) => {
    const dispatch = useDispatch()
    const [filters, setFilter] = useState<StructureMembersAddFilters>({name: '', limit: 10, page: 1,structure:data})
    const [structureMembersAdd, setStructureMembersAdd] = useState<StructureMembers[]>([]);
    const onSearchAdd = (name: string) => {
        dispatch(setFilter({...filters, name}))
    }
    useEffect(() => {
    }, [structureMembersAdd])


    useEffect(() => {
        if(filters.name != ''){
            getListMembers(filters)
                .then(resp => {
                    setStructureMembersAdd(resp.data.data.map((member: any) => normalizeStructureMembers(member)))
                })
                .catch(err => console.warn(err))
        }
  }, [filters])

    const addMember = (doctorId: string) => {
        setFilter({name: '', limit: 10, page: 1,structure:data});
        setStructureMembersAdd(structureMembersAdd.filter(element => element.id != doctorId))
        onClose(doctorId)
    }


  const handleClose = () => {
    onClose();
  };

  return (
      <Dialog open={open}
              maxWidth='sm'
              fullWidth
              onClose={handleClose}>
        <CloseDialog onClick={() => handleClose()} />
        <DialogTitle
            style={{textAlign:"center"}}>
          <Title>
            <FormattedMessage id='structuremembers.add.title' />
            <br/>
          </Title>
        </DialogTitle>
        <DialogContent
            style={{minHeight:'300px'}}
        >
            <StyledDivCenter>
                <Search
                    onSearch={onSearchAdd}
                    label='structuremembers.add.search.placeholder'
                    input={filters.name}
                />
            </StyledDivCenter>

            {
                structureMembersAdd.length > 0 ?
                    <StyledTable
                        headers={STRUCTURES_MEMBERS_ADD_TABLE_HEADERS}
                        name='structuremembersadd'
                        data={structureMembersAdd
                            .map(el => ({
                                ...el,
                                add: (
                                    <div>
                                        <StyledButtonConfirm onClick={() => (addMember(el.id))}>
                                            <PersonAddIcon style={{color:"rgb(102, 51, 255)",backgroundColor:"white"}}></PersonAddIcon>
                                        </StyledButtonConfirm>
                                    </div>
                                ),
                            }))}
                    /> : ''
            }

        </DialogContent>
      </Dialog>
  )
}
