/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {
  useEffect,
  useState,
} from 'react'
import { Button } from 'components/common/Button'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { Content } from 'components/common/Content'
import { Search } from 'components/common/Search'
import { Table } from 'components/common/Table'
import { CONSULTATIONS_TABLE_HEADERS } from 'constants/tables'
import { Pagination, State } from 'interfaces'
import {AllConsultationFilters, Consultation} from 'interfaces/doctors'
import { fetchAllConsultations } from 'ducks/modules/AllConsultations'
import { ExportConsultationsModal } from 'components/pages/Consultations/ExportModal'
import { ConsultationItem } from 'interfaces/consultations';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from '@emotion/styled';
import DateFrom from 'components/pages/Consultations/DateFrom';
import {getAllConsultationRow, getConsultationRow} from "../../../helpers/doctors";
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {Colors} from "../../../constants/colors";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

interface AllConsultationsProps {
    params: number | string,
}

const styles = css`
  width: 300px;
  margin-left: 20%;
`
//#region Styles

const headerStyles = css`
  display: flex;
`
//#endregion

const StyledSelect = styled(Select)`
    width: 180px;
    margin-right: 15px;
`

const typeSelectOptions = [
  { value: null, label: 'Select type' },
  { value: 'TEP', label: 'TEP' },
  { value: 'TEI', label: 'TEI' },
  { value: 'TCP', label: 'TCP' },
  { value: 'TCI', label: 'TCI' },
];

const typeSelectStatus = [
  { value: null, label: 'Select status' },
  { value: 'created', label: 'created' },
  { value: 'cancelled', label: 'cancelled' },
  { value: 'confirmed', label: 'confirmed' },
  { value: 'completed', label: 'completed' },
];

const paymentSelectStatus = [
  { value: null, label: 'Select payment status' },
  { value: 'paid', label: 'paid' },
  { value: 'not_paid', label: 'not_paid' },
];

export const AllConsultations: React.FC < AllConsultationsProps > = ({ params }) => {
  const [exportModal, toggleExportModal] = useState(false)
  const [filters, setFilters] = useState < AllConsultationFilters >({ page: 1, limit: 10 })
  const pagination = useSelector < State,
        Pagination >(state => state.pagination.consultationsAll)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setActiveMenu('global.menu.consultations'))
    dispatch(setIsPrincipalPage(true))
  }, )

  useEffect(() => {
    dispatch(fetchAllConsultations(filters))
  }, [dispatch, filters, Object.keys(filters).length])

  const list = useSelector < State,
        Consultation[] >(state => state.AllConsultations.list)

  const onSearch = (search: string) => setFilters({ ...filters, search });

  const urlGrafana = 'https://grafana.conex.cloud/d/h3VcnCS4k/extracts-utilisateurs?orgId=1';

  return (
    <Content>
      <div css={headerStyles}>
        <Search
          onSearch={onSearch}
          label='doctors.consultations.search'
        />
        {/*<div css={styles}>
          <Button onClick={() => toggleExportModal(true)}>
            <FormattedMessage id='export' />
          </Button>
          <ExportConsultationsModal open={exportModal} onClose={() => toggleExportModal(false)} />
        </div>*/}
      </div>
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <FormControl>
          <InputLabel id="type-select">Type</InputLabel>
          <StyledSelect
            labelId="type-select"
            id="demo-simple-select-outlined"
            value={filters.consultation_type}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              if (e && e.target && e.target.value) {
                setFilters({
                  ...filters,
                  consultation_type: e.target.value,
                })
              }
            }}
            label="Type"
          >
            {typeSelectOptions.map(el => (
              <MenuItem id={el.label} value={String(el.value)}>
                {el.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

{/*        <FormControlLabel
          style={{ marginTop: '18px' }}
          control={(
            <Checkbox
              checked={filters.exclude_test === 1}
              onChange={() => {
                setFilters({
                  ...filters,
                  exclude_test: filters.exclude_test ? 0 : 1,
                })
              }}
              name="exclude_test"
              color="primary"
            />
          )}
          label="Exclude test"
        />*/}

        <FormControl>
          <InputLabel id="status-select">Status</InputLabel>
          <StyledSelect
            labelId="status-select"
            id="demo-simple-select-outlined"
            value={filters.status}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              if (e && e.target && e.target.value) {
                setFilters({
                  ...filters,
                  status: e.target.value,
                })
              }
            }}
            label="Status"
          >
            {typeSelectStatus.map(el => (
              <MenuItem id={el.label} value={String(el.value)}>
                {el.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        {/*<FormControl>
          <InputLabel id="payment-status-select">Payment status</InputLabel>
          <StyledSelect
            labelId="payment-status-select"
            id="demo-simple-select-outlined"
            value={filters.payment_status}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              if (e && e.target && e.target.value) {
                setFilters({
                  ...filters,
                  payment_status: e.target.value,
                })
              }
            }}
            label="Status"
          >
            {paymentSelectStatus.map(el => (
              <MenuItem id={el.label} value={String(el.value)}>
                {el.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>*/}
       {/* <DateFrom name='From' onChange={(e) => {
          setFilters({...filters,
            date_from: e.target.value
          });
        }} />

        <DateFrom name='To' onChange={(e) => {
          setFilters({...filters,
            date_to: e.target.value
          });
        }} />*/}
        <Button style={{backgroundColor: Colors.white, color: Colors.clearBlue, border: '2px solid', borderColor: Colors.clearBlue, height: '38px', width: '150px', marginLeft: 'auto'}} onClick={() => { window.open(urlGrafana, "_blank") }}>
          <QueryStatsIcon style={{marginRight: '10px'}}/>
          <FormattedMessage id="global.dashboard.button" />
        </Button>
      </div>
      <Table
        headers={CONSULTATIONS_TABLE_HEADERS}
        name='consultations'
        data={list.map(item => getAllConsultationRow(item))}
        pagination={pagination}
        onPageChange={page => setFilters({ ...filters, page })}
        onPerPageChange={limit => setFilters({ ...filters, limit })}
      />
    </Content>
  )
}
