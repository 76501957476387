import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { Pagination, ThunkResult } from 'interfaces'
import { combineReducers } from 'redux'
import {VersionPayload} from "../../../interfaces/version";

//#region Actions
export enum VersionsActions {
    fetchMobileVersionsRequest = 'FETCH_MOBILE_VERSIONS_REQUEST',
    fetchMobileVersionsSuccess = 'FETCH_MOBILE_VERSIONS_SUCCESS',
    fetchMobileVersionsFailure = 'FETCH_MOBILE_VERSIONS_FAILURE',
}

export const fetchMobileVersionsRequest = createAction(VersionsActions.fetchMobileVersionsRequest)
export const fetchMobileVersionsSuccess = createAction(VersionsActions.fetchMobileVersionsSuccess)
export const fetchMobileVersionsFailure = createAction(VersionsActions.fetchMobileVersionsFailure)

//#endregion

//#region Reducer
const mobileVersions = handleActions({
    [VersionsActions.fetchMobileVersionsSuccess]:
        (state, action) => action.payload,
}, [])


export const versionsReducer = combineReducers({
    mobileVersions,
})
//#endregion

//#region Thunks

export const fetchMobileVersions = (
): ThunkResult<Promise<void | Pagination>> => (
    dispatch,
) => {
    dispatch(fetchMobileVersionsRequest())

    type Response = {data: VersionPayload[] }

    return axios.request<Response>({
        url: '/versionapp/list',
        params: {
            mobile : 1
        }
    })
        .then(response => {
            const versions : VersionPayload[] = response.data.data
            dispatch(fetchMobileVersionsSuccess(versions.map(version => ({software: version.attributes.software, min_version: version.attributes.min_version}))))
        })
        .catch((err) => {
            dispatch(fetchMobileVersionsFailure(err))
        })
}

//#endregion
