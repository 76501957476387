// eslint-disable-next-line import/no-mutable-exports
let socket: any = {}

try {
  socket = new WebSocket(window.env.WEBSOCKET_URL || '')
} catch (err) {
  // eslint-disable-next-line no-console
  console.error(err)
}

const chatRoutes = {
  auth: 'User\\Auth',
  chat: 'Chat\\Message',
}

const reconnect = (token: string) => {
  try {
    socket = new WebSocket(window.env.WEBSOCKET_URL || '')
    socket.reconnect = reconnect
    socket.authorize(token)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

socket.reconnect = reconnect

socket.sendMessage = (route: string, action: string, data: any) => {
  if (socket.send && socket.readyState === 1) {
    socket.send(JSON.stringify(({ route, action, data })))
  }
}

socket.authorize = (token: string) => {
  socket.sendMessage(chatRoutes.auth, 'authorization', { token })
  socket.authToken = token
}

socket.connectChat = (id: any) => {
  socket.sendMessage(chatRoutes.chat, 'chatConnect', {
    type: 'support',
    id,
  })
}

socket.sendTextMessage = (chatId: any, message: any) => {
  socket.sendMessage(chatRoutes.chat, 'send', {
    chat_id: chatId,
    type: 'Text',
    message,
  })
}

socket.sendFileMessage = (chatId: any, fileId: any) => {
  socket.sendMessage(chatRoutes.chat, 'send', {
    chat_id: chatId,
    type: 'File',
    message: {
      attachment_id: fileId,
    },
  })
}

socket.loadHistory = (chatId: any, last: any) => {
  socket.sendMessage(chatRoutes.chat, 'loadMessage', {
    chat_id: chatId,
    last,
  })
}

socket.notify = (chatId: any) => {
  socket.sendMessage(chatRoutes.chat, 'newMessage', {
    chat_id: chatId,
  })
}

socket.markAsRead = (chatId: number, messages: number[] = []) => {
  socket.sendMessage(chatRoutes.chat, 'read', {
    chat_id: chatId,
    messages,
  })
}

//
// socket.onerror = () => {
//   socket.reconnect()
// }
//
// socket.onclose = () => {
//   socket.reconnect()
// }

export {
  socket,
  chatRoutes,
}
