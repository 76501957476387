import React, { useEffect, useState } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import styled from '@emotion/styled';
import Paper from '@material-ui/core/Paper';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
//import { getSmsStats } from 'components/pages/SmsLogs/SmsLogsList';
import { Search } from 'components/common/Search'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import chunk from 'lodash/chunk';
import { FormattedMessage, useIntl } from 'react-intl'
import {setIsPrincipalPage} from "../../../ducks/modules/menu";
import {useDispatch} from "react-redux";

const Wrapper = styled.div`
    max-width: 975px;
    min-width: 975px;
    margin: 24px auto;
`
const StyledContainer = styled(Paper)`
    align-items: center;
    padding: 27px 23px;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.2) !important;
    margin-bottom: 8px;
    padding-right: 44px;
    margin-bottom: 33px;
`

const StyledContainerTable = styled(Paper)`
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.2) !important;
`

const MobileScreenShareIconStyled = styled(MobileScreenShareIcon)`
    && {
        font-size: 121px;
        color: #21c5a9;
        display: flex;
    }
`

const FirstScreenContainer = styled.div`
    display: flex;
`

const SmsStatistic = styled.div`
    display: flex;
    padding-top: 5px;
    margin-bottom: 11px;
`

const SmsStatisticColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const SmsStatisticCount = styled.div`
    font-weight: bold;
`

const SmsStatisticTitle = styled.div`
    color: #959fb8;
    font-size: 14px;
    margin-left: 8px;
    margin-right: 40px;
    white-space: nowrap;
`

const SmsMessageBlock = styled.div`
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #21293e;
    margin-bottom: 30px;
`

const FirstTableBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const TableBlockRow = styled.div`
    display: flex;
    border-bottom: solid 1px rgba(149, 159, 184, 0.2);
    align-items: center;
    height: 37px;
`

const TableBlockTitle = styled.div`
    color: #959fb8;
    font-size: 11px;
    min-width: 200px;
`

const TableBlockNormal = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: #21293e;
`

const Status = styled.div`
    padding: 5px 19px;
    border-radius: 4px;
    background-color: ${({ color = '' }) => (color.toLowerCase() === 'sent' 
    ? 'rgba(33, 197, 147, 0.2);'
    : '#f6c7cd;')}
    text-align: center;
`

const StyledSearch = styled.div`
    margin-bottom: 16px;
`

const TableCellHead = styled(TableCell)`
  padding-bottom: 30px !important;
`

export const DetailedSmsLogs = ({ match }: { match: { params: { id: string } } }) => {
  const { formatMessage } = useIntl();
  const [smsData, setSmsData] = useState({});
  const [tablePage, setTablePage] = useState(0);
  const [maxResultsPerPage, setMaxResultsPerPafe] = useState(100);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsPrincipalPage(false))
  }, [])

  useEffect(() => {
    axios(`/api/sms/${match.params.id}`)
      .then(data => {
        setSmsData(get(data, 'data.data', {}));
      })
      .catch(err => console.warn(err))
  }, [])

  const id = get(smsData, 'id', '');
  const recipients = get(smsData, 'attributes.recipients', {});
  //const smsStats = getSmsStats(recipients);
  const text = get(smsData, 'attributes.text', '');
  const campaign = get(smsData, 'attributes.campaign', '');
  const createdDate = get(smsData, 'attributes.created.date', '');
  const status = get(smsData, 'attributes.status', '');
  const recipientsArray = Object.keys(recipients)
    .map(el => ({ phone: recipients[el], status }))
  const filteredRecipientsArray = recipientsArray
    .filter(el => el.phone.indexOf(search) !== -1);
  const paged = chunk(filteredRecipientsArray, maxResultsPerPage);
  const currentPage = paged[tablePage] || [];

  return (
    <Wrapper>
      <StyledContainer>
        <SmsMessageBlock>
          {text}
        </SmsMessageBlock>
        <FirstScreenContainer>
          <MobileScreenShareIconStyled />
          <SmsStatisticColumn>
            <SmsStatistic>
              <SmsStatisticCount>
                {status === 'sent' ? recipientsArray.length : 0}
              </SmsStatisticCount>
              <SmsStatisticTitle>
                <FormattedMessage id="sms.smssent" />
              </SmsStatisticTitle>
            </SmsStatistic>
            <SmsStatistic>
              <SmsStatisticCount>
                {status === 'sent' ? recipientsArray.length : 0}
              </SmsStatisticCount>
              <SmsStatisticTitle>
                <FormattedMessage id="sms.delivered" />
              </SmsStatisticTitle>
            </SmsStatistic>
            <SmsStatistic>
              <SmsStatisticCount>
                {status !== 'sent' ? recipientsArray.length : 0}
              </SmsStatisticCount>
              <SmsStatisticTitle>
                <FormattedMessage id="sms.notdelivered" />
              </SmsStatisticTitle>
            </SmsStatistic>
          </SmsStatisticColumn>
          <FirstTableBlock>
            <TableBlockRow>
              <TableBlockTitle>
                <FormattedMessage id="sms.status" />
              </TableBlockTitle>
              <Status color={status}>
                {status}
              </Status>
            </TableBlockRow>
            <TableBlockRow>
              <TableBlockTitle>
                <FormattedMessage id="sms.campaign" />
              </TableBlockTitle>
              <TableBlockNormal>
                {campaign}
              </TableBlockNormal>
            </TableBlockRow>
            <TableBlockRow>
              <TableBlockTitle>
                <FormattedMessage id="sms.dateofcreating" />
              </TableBlockTitle>
              <TableBlockNormal>
                {moment(createdDate).format('MMMM Do YYYY, h:mm:ss a')}
              </TableBlockNormal>
            </TableBlockRow>
          </FirstTableBlock>
        </FirstScreenContainer>
      </StyledContainer>
      <StyledSearch>
        <Search label={formatMessage({ id: 'sms.phone' })} onSearch={(val) => setSearch(val)} />
      </StyledSearch>
      <StyledContainerTable>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCellHead>
                  <FormattedMessage id="sms.telnumber" />
                </TableCellHead>
                <TableCellHead>
                  <FormattedMessage id="sms.status" />
                </TableCellHead>
                <TableCellHead>
                  <FormattedMessage id="sms.dateofcreating" />
                </TableCellHead>
                <TableCellHead>ID</TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage.map((el) => (
                <TableRow key={el.phone}>
                  <TableCell component="th" scope="row">
                    {el.phone}
                  </TableCell>
                  <TableCell>
                    <Status color={status}>
                      {status}
                    </Status>
                  </TableCell>
                  <TableCell>
                    {moment(createdDate).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                  <TableCell>
                    {id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={recipientsArray.length}
            rowsPerPage={maxResultsPerPage}
            page={tablePage}
            onPageChange={(e: unknown, page: number) => setTablePage(page)}
            onChangePage={(e: unknown, page: number) => setTablePage(page)}
            onChangeRowsPerPage={(e) => setMaxResultsPerPafe(parseInt(e.target.value, 10))}
          />
        </TableContainer>
      </StyledContainerTable>
    </Wrapper>
  ) 
}
