/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import { DashboardParams, StatisticSteps } from 'interfaces/dashboard'
import { DateFilter } from 'components/pages/Dashboard/Chart/Filters/DateFilter'
import { useDispatch, useSelector } from 'react-redux'
import { changeParams } from 'ducks/modules/statistics'
import { css } from '@emotion/react'
import { Filter } from 'components/common/Filter'
import { State } from 'interfaces'
import { Specialization } from 'interfaces/doctors'
import { setFilter } from 'helpers/filters'

//#region Styles
const wrapperStyles = css`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`
//#endregion

interface ChartFiltersProps {
  data: DashboardParams,
}

export const ChartFilters: React.FC<ChartFiltersProps> = ({ data }) => {
  const dispatch = useDispatch()
  const specializations = useSelector<State, Specialization[]>(state => state.specializations)

  const onDateChange = (dateFrom: string, dateTo: string, step?: StatisticSteps) => dispatch(
    changeParams({
      ...data,
      dateFrom,
      dateTo,
      step: step || data.step,
    }),
  )

  const onSpecializationSelect = (specialization?: string | number) => dispatch(changeParams(
    setFilter(data, 'specialization_id', specialization),
  ))

  return (
    <div css={wrapperStyles}>
      <DateFilter dateFrom={data.dateFrom} dateTo={data.dateTo} onChange={onDateChange} />
      {
        !data.doctor_id && (
          <Filter
            options={specializations}
            onSelect={onSpecializationSelect}
            label='doctors.specialization'
            value={data.specialization_id || null}
          />
        )
      }
    </div>
  )
}
