import {
    AllConsultationRow,
    Consultation,
    ConsultationPayload,
    ConsultationRow,
    Doctor,
    DoctorPayload,
    Review,
    ReviewPayload,
} from 'interfaces/doctors'
import {format} from 'date-fns'

export const normalizeDoctor: (doctor: DoctorPayload) => Doctor = doctor => ({
  id: doctor.id,
  user_id: doctor.attributes.user_id,
  email: doctor.attributes.email,
  first_name: doctor.attributes.first_name,
  language: doctor.attributes.language,
  last_name: doctor.attributes.last_name,
  gender: doctor.attributes.gender,
  date_of_birth: doctor.attributes.date_of_birth,
  is_confirmation: doctor.attributes.is_confirmation,
  photo: doctor.attributes.photo,
  additional_info: doctor.attributes.additional_info,
  experience: doctor.attributes.experience,
  specialization: doctor.attributes.specialization,
  specialization_id: doctor.attributes.specialization_id,
  rating: doctor.attributes.rating,
  status: doctor.attributes.status,
  phone_number: doctor.attributes.phone_number,
  city: doctor.relationships && doctor.relationships.workPlace.data && doctor.relationships.workPlace.data.length ? doctor.relationships.workPlace.data[0].attributes.city : "",
  postcode: doctor.relationships && doctor.relationships.workPlace.data && doctor.relationships.workPlace.data.length ? doctor.relationships.workPlace.data[0].attributes.postcode : "",
  register_date: doctor.attributes.register_date,
  approve_date: doctor.attributes.approve_date ? doctor.attributes.approve_date : "",
  place: doctor.relationships && doctor.relationships.workPlace.data && doctor.relationships.workPlace.data.length ? doctor.relationships.workPlace.data[0].attributes.name + " "  + doctor.relationships.workPlace.data[0].attributes.street : "",
})

export const normalizeDoctorTeam: (doctor: DoctorPayload) => Doctor = doctor => ({
    id: doctor.relationships.doctor.data.id,
    user_id: doctor.relationships.doctor.data.attributes.user_id,
    email: doctor.relationships.doctor.data.attributes.email,
    first_name: doctor.relationships.doctor.data.attributes.first_name,
    language: doctor.relationships.doctor.data.attributes.language,
    last_name: doctor.relationships.doctor.data.attributes.last_name,
    specialization: doctor.relationships.doctor.data.attributes.specialization,
    phone_number: doctor.relationships.doctor.data.attributes.phone_number,
    register_date: doctor.relationships.doctor.data.attributes.register_date,
});

export const normalizeReview: (review: ReviewPayload) => Review = review => ({
  id: review.id,
  rating: review.attributes.rating,
  text: review.attributes.text,
  date_create: review.attributes.date_create,
  adminAnswer: review.attributes.adminAnswer,
  patient: {
    name: `${review.relationships.patient.data.attributes.first_name} ${review.relationships.patient.data.attributes.last_name}`,
    photo: review.relationships.patient.data.attributes.photo,
  },
})

export const normalizeConsultation: (data: ConsultationPayload) => Consultation = data => {
  const patient = data.relationships && data.relationships.patient ? data.relationships.patient.data : null
  const doctor = data.relationships && data.relationships.doctor ? data.relationships.doctor.data : null
  const additionalMember = data.relationships.additionalMember.data[0] ? data.relationships.additionalMember.data : null
  return {
    id: data.id,
    type: data.attributes.type,
    status: data.attributes.status,
    symptom_explanation: data.attributes.symptom_explanation,
    access_health_record: data.attributes.access_health_record,
    creation_date: data.attributes.creation_time,
    proposed_date: data.attributes.proposed_date,
    completion_time: data.attributes.completion_time,
    useful: data.attributes.useful,
    patient: patient ? {
      id: data.relationships.patient.data.id,
      first_name: data.relationships.patient.data.attributes.first_name,
      last_name: data.relationships.patient.data.attributes.last_name,
      photo: data.relationships.patient.data.attributes.photo,
    } : {
      id: 0,
      first_name: '',
      last_name: '',
      photo: '',
    },
    additionalMember: additionalMember ? {
      id: data.relationships.additionalMember.data[0].attributes.doctorID,
      first_name: data.relationships.additionalMember.data[0].attributes.doctor.first_name,
      last_name: data.relationships.additionalMember.data[0].attributes.doctor.last_name,
      specialization: data.relationships.additionalMember.data[0].attributes.doctor.specialization,
    } : {
      id: 0,
      first_name: '',
      last_name: '',
      specialization: '',
    },
    doctor: doctor ? {
          id: data.relationships.doctor.data.id,
          first_name: data.relationships.doctor.data.attributes.first_name,
          last_name: data.relationships.doctor.data.attributes.last_name,
          specialization: data.relationships.doctor.data.attributes.specialization,
    } : {
          id: 0,
          first_name: '',
          last_name: '',
          specialization: '',
    },
  }
}

export const getConsultationRow: (doctor: number|string, data: Consultation) => ConsultationRow = (
    auth_doctor_id,
    {
      id,
      type,
      status,
      proposed_date: proposedDate,
      creation_date,
      patient,
      additionalMember,
    doctor}
) => {

    return {
        id,
        doctor : doctor,
        type,
        status,
        proposed_date: format(new Date(proposedDate), 'Y-MM-dd HH:mm'),
        creation_date: format(new Date(creation_date), 'Y-MM-dd HH:mm'),
        patient: `${patient.first_name} ${patient.last_name}`,
        correspondant: additionalMember.id == auth_doctor_id ? `${doctor.first_name} ${doctor.last_name}` : `${additionalMember.first_name} ${additionalMember.last_name}`,
        correspondant_specialization: additionalMember.id == auth_doctor_id ? doctor.specialization : additionalMember.specialization,
        role: additionalMember.id == auth_doctor_id ? "doctors.consultations.creator" : "doctors.consultations.invited",
    }
}

export function isCompleteAdresse(firsWorkPlace: any): boolean{
    return !!(firsWorkPlace?.city && firsWorkPlace?.country && firsWorkPlace?.street && firsWorkPlace?.postcode && firsWorkPlace?.name)
}

export const getAllConsultationRow: (data: Consultation) => AllConsultationRow = (
    {
        id,
        type,
        status,
        proposed_date: proposedDate,
        creation_date,
        patient,
        additionalMember,
        doctor, }
) => {
    return {
        id,
        type,
        status,
        proposed_date: format(new Date(proposedDate), 'Y-MM-dd HH:mm'),
        creation_date: format(new Date(creation_date), 'Y-MM-dd HH:mm'),
        patient: `${patient.first_name} ${patient.last_name}`,
        creator: `${additionalMember.first_name} ${additionalMember.last_name}`,
        creator_specialization: additionalMember.specialization,
        invited: `${doctor.first_name} ${doctor.last_name}`,
        invited_specialization: doctor.specialization,
    }

}
