import { State } from 'interfaces'
import { getConsultationRow } from 'helpers/doctors'

export const doctorSelector = (id?: number | string) => (state: State) => {
  if (id === undefined) {
    return undefined
  }
  const byId = state.doctors.byId[+id]

  if (byId) {
    return byId
  }

  return state.doctors.list.find(doctor => doctor.id === id)
}

export const reviewsSelector = (id?: number | string) => (state: State) => {
  if (id === undefined) {
    return undefined
  }

  return state.reviews[+id]
}

export const consultationsSelector = (id: number | string) => (state: State) => {
  const list = state.AllConsultations.list
  if (!list) {
    return []
  }
  return list.map(item => getConsultationRow(id,item))
}
// export const AllConsultationsSelector = (id: number | string) => (state: State) => {
//   const list = state.consultations.byDoctor[+id]
//   if (!list) {
//     return []
//   }
//   return list.map(item => getConsultationRow(item))
// }

export const profileSelector = (id: number | string) => (state: State) => state
  .doctors.profiles[+id]
