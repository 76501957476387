/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, TextField } from '@material-ui/core'
import {approveTeam, getAllDoctorTeams, getStatus, rejectTeam, Team, TeamFilter} from '../../../ducks/modules/teams'
import { Pagination, State } from 'interfaces'
import { Search } from 'components/common/Search'
import { Content } from 'components/common/Content'
import { Table } from 'components/common/Table'
import {Link, useHistory} from "react-router-dom";
import styled from "@emotion/styled";
import {TEAMS_TABLE_HEADERS} from "../../../constants/tables";
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../../../helpers/filters";
import {setDoctorsFilters} from "../../../ducks/modules/doctors";
import {DoctorsFilters} from "../../../interfaces/doctors";
import {TeamsTable} from "./TeamsTable";
import {Colors} from "../../../constants/colors";
import EditIcon from "@mui/icons-material/Edit";
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {unValidateSkill, validateSkill} from "../../../helpers/skills";
import {fetchSkills} from "../../../ducks/modules/skills";
import {Structure, StructureFilters} from "../../../interfaces/structures";
import {TeamFilters} from "../../../interfaces/team";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';



//#region Styled components

const HeaderStructures = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue} !important;
  color: ${Colors.white} !important;
  border-radius: 50px !important;
`

const StyledButtonValidate = styled(Button)`
  border-radius: 50px !important;
`
const StyledName = styled.div`
  word-break: break-all;
`
const theme = createTheme({
    palette: {
        primary: {
            main: Colors.darkGreen
},
        secondary: {
            main: '#d70808'
        }
    }
});

//#endregion

export const ROSOPageList = () => {
  const dispatch = useDispatch()
  const teams = useSelector<State, Team[]>(state => state.teams.list)
    const [filters, setFilter] = useState<TeamFilter>({ search: '', limit: 10, page: 1 })
    const pagination = useSelector<State, Pagination>(state => state.pagination.teams)
    const history = useHistory();

    useEffect(() => {
        dispatch(setActiveMenu('global.menu.teams'))
        dispatch(setIsPrincipalPage(true))
    }, )

    const onSearch = (search?: string) => dispatch(setFilter({...filters, search}))
    const onPageChange = (page: number) => {
        setFilter({ ...filters, page })
    }

    const onPerPageChange = (limit: number) => setFilter({ ...filters, limit, page: 1 })


    const approveTeamClick = (teamId: number) =>
    {
        approveTeam({teamId: teamId})
            .then(() => dispatch(getAllDoctorTeams(filters)))
            .catch(err => console.warn(err))
    }
    const rejectTeamClick = (teamId: number) => {
        rejectTeam({teamId: teamId})
            .then(() => dispatch(getAllDoctorTeams(filters)))
                .catch(err => console.warn(err))
    }

  useEffect(() => {
      dispatch(getAllDoctorTeams(filters))
  }, [dispatch, filters])


  const { formatMessage } = useIntl()
  const f = (id: string) => formatMessage({ id })

  return (
      <Content>
        <HeaderStructures>
            <Search
                onSearch={onSearch}
                label='roso.teamname'
            />
            <Link to="/team/new">
                <StyledButton color="primary" type="submit">
                    <FormattedMessage id="roso.addTeamHeading" />
                </StyledButton>
            </Link>
        </HeaderStructures>

        <Table
            headers={TEAMS_TABLE_HEADERS}
            name='team'
            data={teams.map(el => ({
                ...el,
                name: (
                    <StyledName>
                        {el.name}
                    </StyledName>
                ),
               validate : (
                   <MuiThemeProvider theme={theme}>
                       <StyledButtonValidate variant='contained'
                                             color={el.status == 1 ? 'secondary' : 'primary'}
                                             onClick={() => (
                                                 el.status  == 1 ?  rejectTeamClick(el.id) : approveTeamClick(el.id)
                                             )}
                       >
                           <FormattedMessage id={el.status == 1 ? 'roso.unvalidate' : 'roso.validate'} />
                       </StyledButtonValidate>
                   </MuiThemeProvider>


                ),
                modify : (
                    <StyledButton onClick={() =>  history.push(`/team/${el.id}`)} variant='contained' startIcon={<EditIcon />}>
                        <FormattedMessage id="roso.modify" />
                    </StyledButton>

                )
            }))}
            pagination={pagination}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
        />
      </Content>
  )
}