import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { CalendarParams } from 'interfaces/calendar'
import { startOfMonth, endOfMonth, format } from 'date-fns'
import { groupBy } from 'lodash'

//#region Actions
export enum CalendarActions {
  fetchCalendarRequest = 'FETCH_CALENDAR_REQUEST',
  fetchCalendarSuccess = 'FETCH_CALENDAR_SUCCESS',
  fetchCalendarFailure = 'FETCH_CALENDAR_FAILURE',

  setFilters = 'SET_CALENDAR_FILTERS',
}

export const fetchCalendarRequest = createAction(CalendarActions.fetchCalendarRequest)
export const fetchCalendarSuccess = createAction(CalendarActions.fetchCalendarSuccess)
export const fetchCalendarFailure = createAction(CalendarActions.fetchCalendarFailure)

export const setFilters = createAction(CalendarActions.setFilters)
//#endregion

//#region Reducer
export const list = handleActions({
  [CalendarActions.fetchCalendarSuccess]: (state, action) => action.payload,
}, {})

export const filters = handleActions<CalendarParams>({
  [CalendarActions.setFilters]: (state, action) => action.payload,
}, {
  dateFrom: format(startOfMonth(Date.now()), 'yyyy-MM-dd'),
  dateTo: format(endOfMonth(Date.now()), 'yyyy-MM-dd'),
})

export const calendarReducer = combineReducers({
  list,
  filters,
})
//#endregion

//#region Thunks

export const fetchCalendar = (
  values: CalendarParams,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchCalendarRequest())

  const params = values.specialization ? { ...values } : {
    dateTo: values.dateTo,
    dateFrom: values.dateFrom,
  }

  return axios('/admin/doctor/calendar/urgent', {
    params,
  })
    .then(response => {
      const { data } = response.data
      const transformedData = groupBy(data, item => format(new Date(item.attributes.date_time.date), 'yyyy-MM-dd'))
      dispatch(fetchCalendarSuccess(transformedData))
    })
    .catch((err) => {
      dispatch(fetchCalendarFailure(err))
    })
}
//#endregion
