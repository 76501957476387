/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react';
import get from 'lodash/get';
import { css } from '@emotion/react'
import { deleteMember } from 'ducks/modules/teams'
import manageTeamsStatic from 'assets/images/delete-icon.svg';

const MemberRow = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 302px;
    margin: 0px auto;
    line-height: 69px;
`

const MemberName = css`
    font-size: 16px;
    font-weight: bold;
    color: #21293e;
    margin-left: 14px;
`

const MemberNameImage = css`
    display: flex;
    align-items: center;
`

const deleteIcon = css`
  cursor: pointer
`

interface MembersListInterface {
  members: Array<any>;
  refreshTeam: Function;
}

export const MembersList = ({ members = [], refreshTeam }: MembersListInterface) => {
  const membersList = members.map((el) => {
    const firstName = get(el, 'relationships.doctor.data.attributes.first_name', '');
    const lastName = get(el, 'relationships.doctor.data.attributes.last_name', '');
    const photo = get(el, 'relationships.doctor.data.attributes.photo', '');
    const role: any = get(el, 'attributes.role', '');
    const id = get(el, 'id', false);
    const fullName = `${firstName} ${lastName}`;
    const deleteMemberFunction = () => deleteMember({ memberId: id })
      .then(() => refreshTeam())
      .catch(e => console.warn(e));

    return (
      <div key={id + fullName} css={MemberRow}>
        <div css={MemberNameImage}>
          <div css={css`
            width: 36px;
            height: 36px;
            border-radius: 50%;
            overflow: hidden;
            background-size: cover;
            box-shadow: 0 2px 4px 0 rgba(0, 158, 255, 0.12);
            backgound-position: center;
            background: url(${photo})  center no-repeat;
          `}
          />
          <div css={MemberName}>{fullName}</div>
        </div>
        {role !== 'creator' ? (
          <div onClick={id ? deleteMemberFunction : () => {}}>
            <img css={deleteIcon} src={manageTeamsStatic} alt='manageTeamsStatic' />
          </div>
        ) : ''}
      </div>
    )
  })

  return (<>{membersList}</>);
}
