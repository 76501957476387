import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { State } from 'interfaces';
import { SkillItem } from 'interfaces/skills';
import styled from '@emotion/styled'
import { TextField } from 'components/common/TextField'
import { stringify } from 'querystring';
import pickBy from 'lodash/pickBy';
import { Button } from 'components/common/Button'
import { FormattedMessage } from 'react-intl'
import {setIsPrincipalPage} from "../../../ducks/modules/menu";
import {Colors} from "../../../constants/colors";

const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`

const ButtonCreate = styled(Button)`
  background-color: ${Colors.green};
  margin-top: 5%;
  margin-right:0%
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.green};
  }
`
const DivButton = styled.div`
  display: flex;
  justify-content: right;
`

const required = (value: any) => (value ? undefined : 'Required')

const onSubmit = async (values: any, setRequestError: (a: boolean) => void, cb: () => void) => {
  try {
    const result = await axios.post(`/admin/doctor/team/skill/${values.id}`, stringify(pickBy(values)));
    cb();
    return result.data.data;
  } catch (e) {
    console.warn(e);
    setRequestError(true);
    return [];
  }
}

interface SkillError {
  name?: string | undefined
}

interface FormValues {
  name?: string
}

const validate = (values: FormValues) => {
  const errors: SkillError = {};
  if (!values.name || values.name.length >= 50) {
    errors.name = 'Must be < 50 characters';
  }
  return errors;
}

export const EditSkill = ({ match }: { match: { params: { id: string } } }) => {
  const history = useHistory();
  const [requestError, setRequestError] = useState(false);
  const skills = useSelector<State, SkillItem[]>(state => state.skills.list);
  const currentSkill = skills.find(el => el.id === match.params.id) || false;
  const dispatch = useDispatch()

  if (currentSkill === false) {
    history.push('/skills');
    return null;
  }

  useEffect(() => {
    dispatch(setIsPrincipalPage(false))
  }, )

  return (
    <Wrapper>
      <Form
        onSubmit={(values) => onSubmit(values, setRequestError, () => history.push('/skills/'))}
        validate={validate}
        initialValues={{
          id: currentSkill.id,
          name: currentSkill.attributes.name,
        }}
        render={({
          handleSubmit, form, submitting, values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="id" validate={required}>
              {({ input, meta }) => (
                <TextField disabled label="Id" multiline input={input} meta={meta} type="text" />
              )}
            </Field>
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <TextField label="Nom" input={input} meta={meta} type="text" />
              )}
            </Field>
            {requestError ? <Error>Something went wrong, try later!</Error> : ''}
              <DivButton className="buttons">
                  <ButtonCreate color="primary" type="submit" disabled={submitting}>
                    <FormattedMessage id="global.save" />
                  </ButtonCreate>
              </DivButton>
          </form>
        )}
      />
    </Wrapper>
  ) 
}