import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { State } from 'interfaces';
import { SkillItem } from 'interfaces/skills';
import styled from '@emotion/styled'
import { TextField } from 'components/common/TextField'
import { stringify } from 'querystring';
import pickBy from 'lodash/pickBy';
import { Button } from 'components/common/Button'
import { FormattedMessage } from 'react-intl'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {Colors} from "../../../constants/colors";

const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`
const ButtonCreate = styled(Button)`
  background-color: ${Colors.green};
  margin-top: 5%;
  margin-right:0%
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.green};
  }
`
const DivButton = styled.div`
  display: flex;
  justify-content: right;
`
const required = (value: any) => (value ? undefined : 'Required')

interface SkillError {
    name?: string | undefined
}

interface FormValues {
    name?: string
}

const validate = (values: FormValues) => {
    const errors: SkillError = {};
    if (!values.name || values.name.length >= 50) {
        errors.name = 'Must be < 50 characters';
    }
    return errors;
}

export const CreateSkill = () => {
    const history = useHistory();
    const [requestError, setRequestError] = useState(false);
    const dispatch = useDispatch()
    const idValidateur = useSelector(store => store.connected.idUser);


    const onSubmit = async (values: any, setRequestError: (a: boolean) => void, cb: () => void) => {
        try {
            values.creatorid = idValidateur;
            const result = await axios.post(`/admin/doctor/team/skill/create/${values.creatorid}/${values.name}`, stringify(pickBy(values)));
            cb();
            return result.data.data;
        } catch (e) {
            console.warn(e);
            setRequestError(true);
            return [];
        }
    }


    useEffect(() => {
        dispatch(setActiveMenu('global.menu.skills.create'))
        dispatch(setIsPrincipalPage(false))
    }, )

    return (
        <Wrapper>
            <Form
                onSubmit={(values) => onSubmit(values, setRequestError, () => history.push('/skills/'))}
                validate={validate}
                render={({
                             handleSubmit, form, submitting, values,
                         }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="id">
                            {({ input, meta }) => (
                                <TextField disabled label="Id" multiline input={input} meta={meta} type="text" />
                            )}
                        </Field>
                        <Field name="name" validate={required}>
                            {({ input, meta }) => (
                                <TextField label="Nom du domaine de compétences" input={input} meta={meta} type="text" />
                            )}
                        </Field>
                        {requestError ? <Error>Something went wrong, try later!</Error> : ''}
                        <DivButton className="buttons">
                            <ButtonCreate color="primary" type="submit" disabled={submitting}>
                                <FormattedMessage id="skill.create.create" />
                            </ButtonCreate>
                        </DivButton>
                    </form>
                )}
            />
        </Wrapper>
    )
}
