/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import { Form, Field } from 'react-final-form'
import Typography from '@material-ui/core/Typography'

import { TextField } from 'components/common/TextField'
import { LoginValues, LoginFormErrors } from 'interfaces/auth'
import { Errors } from 'constants/errors'
import { EMAIL_REGEX } from 'constants/regexps'
import { Button } from 'components/common/Button'
import { useDispatch } from 'react-redux'
import { login } from 'ducks/modules/auth'

//#region Styles
const wrapperStyles = css`
  padding: 24px;
  width: 360px;
`

const buttonStyles = css`
  && {
    margin-top: 8px;
  }
`

const titleStyles = css`
  && {
    text-align: center;
    margin-bottom: 16px;
  }
`
//#endregion

const validate = (values: LoginValues) => {
  const errors: LoginFormErrors = {}

  if (!values.email) {
    errors.email = Errors.required
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = Errors.invalidEmail
  }

  if (!values.password) {
    errors.password = Errors.required
  }

  return errors
}

export const LoginForm = () => {
  const dispatch = useDispatch()

  return (
    <Paper elevation={3}>
      <div css={wrapperStyles}>
        <Form
          onSubmit={async (values) => {
            const errors = await dispatch(login(values))
            return errors
          }}
          validate={validate}
        >
          {
            ({ handleSubmit, submitting, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant='h5' css={titleStyles}>
                  Log In
                </Typography>
                <Field
                  name='email'
                  component={TextField}
                  label='E-mail'
                  type='email'
                  autoFocus
                  required
                />
                <Field
                  name='password'
                  component={TextField}
                  label='Password'
                  type='password'
                  required
                />
                <Button
                  fullWidth
                  css={buttonStyles}
                  type='submit'
                  isFetching={submitting}
                  disabled={hasValidationErrors || submitting}
                  size='large'
                >
                  Log In
                </Button>
              </form>
            )
          }
        </Form>
      </div>
    </Paper>
  )
}
