/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from "react"
import { Routes } from 'constants/routes'
import DashboardIcon from '@material-ui/icons/Dashboard'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import Person from '@material-ui/icons/Person'
import Sms from '@material-ui/icons/Sms'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
// import CreditCardIcon from '@material-ui/icons/CreditCard'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import GroupsIcon from '@mui/icons-material/Groups';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import GroupIcon from '@material-ui/icons/Group';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EmailIcon from '@material-ui/icons/Email';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Icon from '@mdi/react';
import { mdiHospitalBuilding } from '@mdi/js';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const hospitalIcon = () => (
  <Icon
      path={mdiHospitalBuilding}
      size={1}
  />
)

export const menuItems = [
  {
    to: Routes.dashboard,
    label: 'global.dashboard',
    Icon: DashboardIcon,
  },
  {
    to: Routes.doctors,
    label: 'global.menu.doctors',
    Icon: Person,
  },
  {
    to: Routes.consultations,
    label: 'global.menu.consultations',
    Icon: HealthAndSafetyIcon,
  },
  {
    to: Routes.smslogs,
    label: 'global.menu.smslogs',
    Icon: Sms,
  },
  // {
  //   to: Routes.urgent,
  //   label: 'global.urgent',
  //   Icon: InsertInvitationIcon,
  // },
  {
    to: Routes.roso,
    label: 'global.menu.teams',
    Icon: GroupsIcon,
  },
  // {
  //   to: Routes.medicine,
  //   label: 'global.medicine',
  //   Icon: DashboardIcon,
  // },
  // {
  //   to: Routes.support,
  //   label: 'global.support',
  //   Icon: ContactSupportIcon,
  // },
  // {
  //   to: Routes.content,
  //   label: 'global.content',
  //   Icon: LibraryBooksIcon,
  // },
  {
    to: Routes.structures,
    label: 'global.menu.structures',
    Icon: hospitalIcon,
  },
  {
    to: Routes.specializations,
    label: 'global.menu.specializations',
    Icon: MedicalServicesIcon,
  },
  {
    to: Routes.skills,
    label: 'global.menu.skills',
    Icon: HowToRegIcon,
  },
  {
    to: Routes.notification,
    label:'global.menu.notifications',
    Icon: NotificationImportantIcon
  },
  // {
  //   to: Routes.settings,
  //   label: 'global.settings',
  //   Icon: DashboardIcon,
  // },
  // {
  //   to: Routes.templates,
  //   label: 'global.templates',
  //   Icon: DashboardIcon,
  // },
  // {
  //   to: Routes.content,
  //   label: 'global.content',
  //   Icon: DashboardIcon,
  // },
  {
    to: Routes.geolocation,
    label: 'global.menu.geolocation',
    Icon: LocationOnIcon,
  },
  {
    to: Routes.mobile_version,
    label: 'global.menu.mobile_version',
    Icon: SmartphoneIcon,
  },
]
