/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from '@emotion/react'
import { useHistory } from 'react-router-dom'

import { Tabs } from 'components/common/Tabs'
import { Search } from 'components/common/Search'
import { Content } from 'components/common/Content'
import { Pagination, State } from 'interfaces'
import {Doctor, DoctorsGeolocFilters} from 'interfaces/doctors'
import {fetchDoctors, setDoctorsFiltersGeoloc} from 'ducks/modules/doctors'
import {
    GEOLOC_STRUCTURE_TABLE_HEADERS,
    GEOLOC_TABLE_HEADERS
} from 'constants/tables'
import {TABSGEOLOC} from 'constants/doctors'
import { Routes } from 'constants/routes'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {UserTable} from "./UserTable";
import {StructureTable} from "./StructureTable";
import {fetchStructures, setStructuresFilters} from "../../../ducks/modules/structures";
import {Structure, StructureFilters} from "../../../interfaces/structures";
import styled from "styled-components";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage} from "react-intl";
import {Colors} from "../../../constants/colors";
import axios from "axios";


//#region Styles
const headerStyles = css`
  display: flex;
`
//#endregion

export const GeolocationList = () => {
    const dispatch = useDispatch()
    const doctors = useSelector<State, Doctor[]>(state => state.doctors.list)
    const filters = useSelector<State, DoctorsGeolocFilters>(state => state.doctors.filtersGeoloc)
    const pagination = useSelector<State, Pagination>(state => state.pagination.doctors)
    const history = useHistory()
    const [activeTab, setTab] = useState(filters.status === 1 ? 0 : 1)

    /*Structure tab */
    const structures = useSelector<State, Structure[]>(state => state.structures.list)
    const filtersStructure = useSelector<State, StructureFilters>(state => state.structures.filters)
    const paginationStructure = useSelector<State, Pagination>(state => state.pagination.structures)

    const [sortBy, setSortBy] = useState(filters.sortBy)
    const [sortOrder,setSortOrder] = useState(filters.sortOrder)
    const [geoloc,setGeoloc] = useState(filters.geoloc)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(setActiveMenu('global.menu.geolocation'))
        dispatch(setIsPrincipalPage(true))
    }, )

    if(activeTab === 0){
        useEffect(() => {
            dispatch(fetchDoctors(filters))
        }, [dispatch, filters])
    }
    else if (activeTab === 1 ){
        useEffect(() => {
            dispatch(fetchStructures(filtersStructure))
        }, [dispatch, filtersStructure])
    }


    const requestSort = (pSortBy: string) => {
        if (pSortBy === sortBy) {
            setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
        } else {
            setSortBy(pSortBy)
            setSortOrder("ASC")
        }
    }

    useEffect(() => {
        dispatch(setDoctorsFiltersGeoloc({...filters, sortBy: sortBy, sortOrder: sortOrder,geoloc:"user"}))
    }, [sortBy, sortOrder,geoloc])


    const onSearch = (search?: string) => {
        if(activeTab === 0){
            dispatch(setDoctorsFiltersGeoloc({...filters, search}))
        }
        if(activeTab === 1){
            dispatch(setStructuresFilters({...filtersStructure,search}))
        }
    }
    const onPageChange = (page: number) => {
        if(activeTab === 0){
            dispatch(setDoctorsFiltersGeoloc({...filters, page}))
        }
        if(activeTab === 1){
            dispatch(setStructuresFilters({...filtersStructure,page}))
        }
    }

    const onLimitChange = (limit: number) => {
        if(activeTab === 0){
            dispatch(setDoctorsFiltersGeoloc({...filters, limit}))
        }
        if(activeTab === 1){
            dispatch(setStructuresFilters({...filtersStructure,limit}))
        }
    }


    const onTabChange = (tab: number) => {
        if (tab === 0) {
            //On redonne tous les arguments, sinon le version 2 reste
            dispatch(setDoctorsFiltersGeoloc({...filters,geoloc:"user"}))
        }
        if (tab === 1) {
            dispatch(setStructuresFilters({...filtersStructure,geoloc:"struct"}))
        }
        setTab(tab)
    }

    const updateLocWorkplace = () => {
        setLoading(true);
        axios.post('api/admin/workplace/update/loc')
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    return (
        <>
            <Tabs
                tabs={TABSGEOLOC}
                active={activeTab}
                onChange={onTabChange}
            />
            <Content>
                <Line css={headerStyles}>
                    <Search
                        onSearch={onSearch}
                        label='doctors.search'
                        input={filters.search}
                    />
                    <UpdateLocButton variant='outlined' color='error' onClick={updateLocWorkplace} >
                        {loading ?
                            <CircularProgress color="inherit" /> :
                            <FormattedMessage id="geoloc.update.loc" />
                        }
                    </UpdateLocButton>
                </Line>
                { activeTab === 0 ?
                    <UserTable
                        headers={GEOLOC_TABLE_HEADERS}
                        data={doctors}
                        pagination={pagination}
                        onRowClick={doctor => history.push(`${Routes.doctors}/${doctor.id}`)}
                        onPageChange={onPageChange}
                        onPerPageChange={onLimitChange}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        requestSort={requestSort}
                        activeTab={activeTab}
                    />
                    : <StructureTable
                        headers={GEOLOC_STRUCTURE_TABLE_HEADERS}
                        data={structures}
                        pagination={paginationStructure}
                        onPageChange={onPageChange}
                        onPerPageChange={onLimitChange}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        requestSort={requestSort}
                        activeTab={activeTab}
                    />

                }
            </Content>
        </>
    )
}

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

const UpdateLocButton = styled(Button)`
  display: flex;
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
  margin-left: auto;
  justify-content: flex-end;
`