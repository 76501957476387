import {StructureMembers, StructureMembersPayload} from "../interfaces/structuresmembers";
import axios from "axios";
import {stringify} from "querystring";
import {Structure, StructureRow} from "../interfaces/structures";

export const getListStructureMembers = (filter: any,id:number) => axios.get(`/api/doctor/structure/${id}/members/v2?${stringify(filter)}`)
export const deleteStructureMember = (structureId:number,doctorId: number) => axios.delete(`/admin/doctor/structure/${structureId}/member/${doctorId}`)
export const getListMembers = (filter: any) => axios.get(`admin/doctor/structure/member/available?${stringify(filter)}`)
export const addStructureMember = (structureId:number,doctorId: string) => axios.post(`/admin/doctor/structure/${structureId}/${doctorId}/join`)
export const importStructureMember = (file:any,structureId:string) =>
{
    const formData = new FormData();

    formData.append("file",file);
    formData.append("structureid",structureId);

    return axios('/admin/user/import/create', {
        method: 'POST',
        data:formData,
    })
}
export const sendMailImport = (structureId:number) => axios.post(`admin/user/import/mail/${structureId}`)
export const sendMailImportDoctor = (structureId:number, doctorId: number) => axios.post(`admin/user/import/mail/${structureId}/${doctorId}`)

export const getStructureRow : (data: Structure) => StructureRow = (
    {
        id,
        attributes,
    }
) => {
    return {
        name : attributes.name,
        type: attributes.type.type,
        finess: attributes.finessGeographic || attributes.finessJuridic,
        members: attributes.members,
    }
}

export const normalizeStructureMembers: (structure: StructureMembersPayload) => StructureMembers = structure => ({
    id: structure.id,
    user_id: structure.attributes.user_id,
    email: structure.attributes.email,
    first_name: structure.attributes.first_name,
    last_name: structure.attributes.last_name,
    specialization: structure.attributes.specialization,
    phone_number: structure.attributes.phone_number,
    register_date: structure.attributes.register_date,
    status: structure.attributes.status == 'PRE_REGISTRATED' ? 'Importé' : (structure.attributes.status == 'VERIFIED' ? 'Vérifié' : '')
})