import { createAction, handleActions } from 'redux-actions'

//#region Actions
export enum LocalizationActions {
  setLocale = 'SET_LOCALE',
}

export const setLocale = createAction(LocalizationActions.setLocale)
//#endregion

//#region Reducer
export const localizationReducer = handleActions({
  [LocalizationActions.setLocale]: (state, action) => action.payload,
}, localStorage.getItem('language') || 'fr')
//#endregion
