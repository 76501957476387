/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {useState} from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { Pagination } from 'interfaces'
import get from 'lodash/get';
//#region Styles
const wrapperStyles = css`
  && {
    margin-top: 24px;
  }
`

const rowStyles = css`
  && {
    cursor: pointer;
  }
`
//#endregion

export const UserTable = ({
                                 headers,
                                 data,
                                 pagination,
                                 onPageChange,
                                 onPerPageChange,
                                 onRowClick,
                                 sortBy,
                                 sortOrder,
                                 requestSort,
                                 activeTab,
                             }) => {
    const handlePageChange = (event: unknown, newPage: number) => (
        onPageChange && onPageChange(newPage + 1)
    )
    const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onPerPageChange) return
        const newPerPage = parseInt(event.target.value, 10)
        onPerPageChange(newPerPage)
    }

    return (
        <Paper css={wrapperStyles}>
            <TableContainer>
                <MuiTable stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key={'last_name'}>
                                    <FormattedMessage id={`tables.doctors.last_name`} />
                            </TableCell>

                            <TableCell key={'first_name'}>
                                <FormattedMessage id={`tables.doctors.first_name`} />
                            </TableCell>

                            <TableCell key={'specialization'}>
                                <FormattedMessage id={`tables.doctors.specialization`} />
                            </TableCell>

                            <TableCell key={'place'}>
                                <FormattedMessage id={`tables.doctors.place`} />
                            </TableCell>

                            <TableCell key={'postcode'}>
                                <FormattedMessage id={`tables.doctors.postcode`} />
                            </TableCell>

                            <TableCell key={'city'}>
                                <FormattedMessage id={`tables.doctors.city`} />
                            </TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(row => (
                                <TableRow
                                    key={row.id}
                                    css={rowStyles}
                                    hover={!!onRowClick}
                                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                                >
                                    {
                                        headers.map(header => {
                                            let value = get(row, header, '-')
                                                return (
                                                    <TableCell key={header + row.id}>
                                                        {Array.isArray(value) ? value.join(', ') : value}
                                                    </TableCell>
                                                )
                                        })
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
            {!!(pagination && pagination.total) && (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component='div'
                    count={pagination.total}
                    rowsPerPage={pagination.limit}
                    page={pagination.currentPage - 1}
                    onPageChange={handlePageChange}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageChange}
                />
            )}
        </Paper>
    )
}
