/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from 'components/common/Button'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from '@emotion/styled'
import axios from 'axios';
import validator from 'validator';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { TextField } from 'components/common/TextField'
import orderBy from 'lodash/orderBy';
import {setActiveMenu, setIsPrincipalPage} from "../../../../ducks/modules/menu";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../constants/colors";
import {State} from "../../../../interfaces";
import {UploadPhoto} from "./UploadPhoto";
import {Structure, StructureType, StructurePhysical} from "../dataModel";

const optiosTypePhysicalExclude = ['CPTS', 'GHT'];
const optiosTypeExclude = ['Cabinet Médical', 'MSP', 'ESPIC', 'EHPAD', 'Clinique'];

const activeOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
]

const statusOptions = [
  { value: true, label: 'public' },
  { value: false, label: 'private' },
]


const structurePhysicalVariants: Array<StructurePhysical> = [
  { label: 'notPhysical', value: false },
  { label: 'physical', value: true },
];

const onSubmit = async (values: any, setRequestError: (a: boolean) => void, cb: () => void) => {
  try {

      const formData = new FormData()
      const data = values
      Object.keys(data).forEach((item) => {
              formData.append(item, data[item])
      })


      const result = await axios('/admin/doctor/structure', {
        method: 'POST',
        data: formData,
    })

    cb();
    return result.data.data;
  } catch (e) {
    console.warn(e);
    setRequestError(true);
    return [];
  }
}

const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`

const StyledSelect = styled(Select)`
    width: 100%;
`
const StyledInputLabel = styled(InputLabel)` 
    background-color: #f1f3f7;
`

const StyledButton = styled(Button)` 
  background-color: ${Colors.green};
  color: ${Colors.white};
  border-radius: 50px;
  
  
  &:hover {
    background-color: ${Colors.green};
  }
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`

const required = (value: any) => (value ? undefined : 'global.required')
const mustBeEmail = (value: any) => (!value || validator.isEmail(value) ? undefined : 'structures.error.email')
const mustBePhone = (value: any) => (mustBeNumber(value) && mustBeNSymbols(10)(value) ? undefined : 'structures.error.phone')
const mustBeUrl = (value: any) => (!value || validator.isURL(value) ? undefined : 'structures.error.website')
const mustBePostalCode = (value: any) => (!value || mustBeNSymbols(5)(value) ? undefined : 'structures.error.postalCode')
const mustBeNumber = (value: any) => ((!value || validator.isNumeric(value)))
const mustBeDescription = (value: any) => (!value || mustBeLessThanNSymbols(1000)(value) ? undefined : 'structures.error.description')
const mustBeName = (value: any) => (!value || mustBeLessThanNSymbols(50)(value) ? undefined : 'structures.error.name')
const mustBeFiness = (value: any) => (!value || (mustBeNSymbols(9)(value) && validator.isNumeric(value)) ? undefined : 'structures.error.finess')

const mustBeNSymbols = (n: number) => (value: any) => (
  !(value && value.length && value.length !== n)
)

const mustBeLessThanNSymbols = (n: number) => (value: any) => (
    !(value && value.length && value.length > n)
)

const mustBeAscii = (value: string) => {
  if (!!value) {
    let regexp = /([^A-Za-z0-9_-])/
    let matches = value?.match(regexp)
    if (matches != null && matches.length > 0) {
      return `Caractère '${matches[0]}' n'est pas autorisé`
    }
  }
  return undefined
}

const checkThruly = (value: any) => value === 'true' || value === true;

const composeValidators = (...validators: any) => (value: any) => validators
  .reduce((error: string, valid: (value: any) => void) => error || valid(value), undefined)

const correspondingIsPhysical = (form: any, types: Array<StructureType>) => (
  (value:any) => {
    const isPhysicalField = checkThruly(get(form.getFieldState('isPhysical'), 'value', false));
    const currentValue = types.find((el: any) => Number(value) === Number(el.id));

    if (!isPhysicalField 
      && currentValue 
      && currentValue.label 
      && !optiosTypePhysicalExclude.includes(currentValue.label)) {
      return 'You must change this field to one for corresponding "isPhysical"';
    }

    if (isPhysicalField 
      && currentValue 
      && currentValue.label 
      && optiosTypePhysicalExclude.includes(currentValue.label)) {
      return 'You must change this field to one for corresponding "isPhysical"';
    }

    return undefined;
  })

export const EditStructure = ({ match }: { match: any }) => {
  const [types, setTypes] = useState([]);
  const optiosTypePhysical = types
    .filter((el:StructureType) => !optiosTypePhysicalExclude.includes(el.label));
  const optiosType = types
    .filter((el:StructureType) => optiosTypePhysicalExclude.includes(el.label));
  const dispatch = useDispatch()
  const history = useHistory();
  const locale = useSelector<State, any>(state => state.localization)

  useEffect(() => {
    dispatch(setIsPrincipalPage(false))
      if(history.location.pathname === '/structures/new') {
          dispatch(setActiveMenu('structures.create'))
      }
  }, )

  useEffect(() => {
    axios.get('/admin/doctor/structure/type/list')
      .then(result => {
        const list = get(result, 'data.data', []);
        const formattedList = list.map((el: any) => {
          const id = get(el, 'id', 0);
          const type = get(el, 'attributes.type', 'none');
          return ({ id, label: type })
        })
        const sortedList: any = orderBy(formattedList, [el => el.label.toLowerCase()], ['asc']);
        setTypes(sortedList);
      })
  }, [])

  const { formatMessage } = useIntl()
  const [structure, setStructure] = useState<Structure>({});
  const [requertError, setRequestError] = useState(false);
  const id = get(match, 'params.id', false);
  const currentType = structure.type ? [...optiosType, ...optiosTypePhysical]
    .find((el: StructureType) => get(el, 'label', null) === get(structure, 'type.type', null)) : {};

  useEffect(() => {
    if (id) {
      axios.get(`/admin/doctor/structure/${id}`)
        .then(resp => {
          const respData = resp.data.data;
          const structured = { ...respData, ...respData.attributes }
          setStructure(structured);
        })
        .catch(err => {
          console.warn(err)
        })
    }
  }, [])

  return (
    <Wrapper>
      <Form
        onSubmit={(values) => onSubmit(values, setRequestError, () => history.push(`/structures/`))}
        initialValues={{
          ...(structure.id ? { id: structure.id } : {}),
          type: get(currentType, 'id', null),
          name: structure.name || '',
          description: structure.description || '',
          email: structure.email || '',
          phone: structure.phone || '',
          city: structure.city || '',
          website: structure.website || '',
          address: structure.address || '',
          zipcode: structure.zipcode || '',
          isPhysical: structure.isPhysical || null,
          isActive: structure.id ? structure.isActive : null,
          status: structure.id ? structure.status : null,
          finessGeographic: structure.finessGeographic || '',
          finessJuridic: structure.finessJuridic || '',
          siren: structure.siren || '',
          photo: structure.photo,
          code: structure.code || '',
        }}
        render={({
          handleSubmit, form, submitting, values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="isPhysical" validate={required}>
              {({ input, meta }) => (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <StyledInputLabel id="demo-simple-select-outlined-label">{formatMessage({ id: 'structures.field.isPhysical' })}</StyledInputLabel>
                  <StyledSelect
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...input}
                  >
                    {structurePhysicalVariants.map(el => (
                      <MenuItem id={el.label} value={String(el.value)}>
                        {formatMessage({ id: 'structures.' + el.label })}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {meta.error && meta.touched && <Error>{formatMessage({ id: meta.error })}</Error>}
                </FormControl>
              )}
            </Field>
            <Field name="status" validate={required}>
              {({ input, meta }) => (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <StyledInputLabel id="demo-simple-select-outlined-label">{formatMessage({ id: 'structures.field.status' })}</StyledInputLabel>
                  <StyledSelect
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...input}
                  >
                    {statusOptions.map(el => (
                      <MenuItem id={el.label} value={String(el.value)}>
                        {formatMessage({ id: 'structures.status.' + el.label })}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {meta.error && meta.touched && <Error>{formatMessage({ id: meta.error })}</Error>}
                </FormControl>
              )}
            </Field>
            <Field
              name="type"
              validate={
                composeValidators(
                  required,
                  correspondingIsPhysical(form, types),
                )
              }
            >
              {({ input, meta }) => (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <StyledInputLabel id="demo-simple-select-outlined-label">{formatMessage({ id: 'structures.field.type' })}</StyledInputLabel>
                  <StyledSelect
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...input}
                  >
                    {(checkThruly(values.isPhysical) ? optiosTypePhysical : optiosType)
                      .map((el:StructureType) => (
                        <MenuItem key={el.label} value={el.id}>
                          {el.label}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                  {meta.error && meta.touched && <Error>{formatMessage({ id: meta.error })}</Error>}
                </FormControl>
              )}
            </Field>
            <Field
              name="name"
              validate={
                composeValidators(
                  required,
                  mustBeName,
                )
              }
            >
              {({ input, meta }) => (
                <TextField label={formatMessage({ id: 'structures.field.name' })} input={input} meta={meta} type="text" />
              )}
            </Field>
            {checkThruly(values.isPhysical) ? (
              <>
                <Field name="finessGeographic" validate={mustBeFiness}>
                  {({ input, meta }) => (
                    <TextField label={formatMessage({ id: 'structures.field.finessGeographic' })} input={input} meta={meta} type="text" />
                  )}
                </Field>
                <Field name="finessJuridic" validate={mustBeFiness}>
                  {({ input, meta }) => (
                    <TextField label={formatMessage({ id: 'structures.field.finessJuridic' })} input={input} meta={meta} type="text" />
                  )}
                </Field>
                <Field name="siren" validate={mustBeFiness}>
                  {({ input, meta }) => (
                    <TextField label={formatMessage({ id: 'structures.field.siren' })} input={input} meta={meta} type="text" />
                  )}
                </Field>
              </>
            ) : null}
            <Field name="description" validate={mustBeDescription}>
              {({ input, meta }) => (
                <TextField label={formatMessage({ id: 'structures.field.description' })} multiline input={input} meta={meta} type="text" />
              )}
            </Field>
            <Field 
              name="email" 
              validate={composeValidators(mustBeEmail)}
            >
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'structures.field.email' })}
                  input={input} 
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
            <Field name="phone" validate={mustBePhone}>
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'structures.field.phone' })}
                  input={input} 
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
            <Field name="website">
              {({ input, meta }) => (
                <TextField 
                  label="Website" 
                  input={input} 
                  meta={meta} 
                  type="phone"
                />
              )}
            </Field>
            <Field name="address">
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'structures.field.address' })}
                  input={input}
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
            <div css={{display: "inline-block", width: "calc(50% - 5px)", marginRight: "10px"}}>
              <Field name="city">
                {({ input, meta }) => (
                    <TextField label={formatMessage({ id: 'structures.field.city' })} input={input} meta={meta} type="text" />
                )}
              </Field>
            </div>
            <div css={{display: "inline-block", width: "calc(50% - 5px)"}}>
              <Field
                name="zipcode"
                validate={mustBePostalCode}
              >
                {({ input, meta }) => (
                  <TextField label={formatMessage({ id: 'structures.field.zipcode' })} input={input} meta={meta} type="text" />
                )}
              </Field>
            </div>
            <Field name="photo">
              {({ input, meta }) => (
                  <UploadPhoto
                       label={null}
                       input={input}
                      // meta={meta}
                      // type="text"
                  />
              )}
            </Field>
            <Field name="code" validate={mustBeAscii}>
              {({ input, meta }) => (
                <TextField
                  label="Code"
                  input={input}
                  meta={meta}
                  type="text"
                />
              )}
            </Field>
            {requertError ? <Error>Something went wrong, try later!</Error> : ''}
            <div className="buttons">
              <StyledButton type="submit" disabled={submitting}>
                  {formatMessage({ id: 'global.save' })}
              </StyledButton>
            </div>
          </form>
        )}
      />
    </Wrapper>
  ) 
}
