import { createAction, handleActions } from 'redux-actions'
import { ThunkDispatch } from 'redux-thunk'
import {AnyAction, combineReducers} from 'redux'
import axios from 'axios'
import { parseJwt } from '../../../helpers/token'

import { LoginValues } from 'interfaces/auth'
import {MenuActions, setActiveMenu, setIsPrincipalPage} from "../menu";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
//#region Actions
export enum ConnectedActions {
    connectedUser = 'ID_USER_CONNECTED',
    storeRoleUser = 'ROLE_USER',
    storeIDUser = 'ID_USER'

}

export const connectedUser = createAction(ConnectedActions.connectedUser)
export const storeRoleUser = createAction(ConnectedActions.storeRoleUser)
export const storeIDUser = createAction(ConnectedActions.storeIDUser)


//#endregion

//#region Reducer

//#endregion

//#region Thunks
export const getInfoFromToken = (
    values: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    try {
        const getTokenDecoded = parseJwt(values);
        dispatch(storeIDUser(getTokenDecoded.id))
        dispatch(storeRoleUser(getTokenDecoded.roles))
    } catch (err) {
        return {
            email: 'Invalid email or password',
        }
    }

    return undefined
}
//#endregion


const idUser = handleActions({
    [ConnectedActions.storeIDUser]: (state, action) => action.payload,
},  "null")

const roleUser = handleActions({
    [ConnectedActions.storeRoleUser]: (state, action) => action.payload,
}, "null")

export const connectedReducer = combineReducers({
    idUser,
    roleUser
})