import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import {Button} from "../../common/Button";
import {Colors} from "../../../constants/colors";


const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #209dff;
  margin-right: 20px;
`
const StyledButtonConfirm = styled(Button)`
  background-color: ${Colors.greenValidate} !important;
  color: ${Colors.white} !important;
  border-radius: 50px;
  margin-right:10% !important;
  &:hover:not(.active) {
    background-color: ${Colors.greenValidate};
  }
`

const StyledButtonCancel = styled(Button)`
  background-color: ${Colors.redDelete} !important;
  color: ${Colors.white} !important;
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.watermelon};
  };
`
const FormatButton = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 5% !important;
`

export const DeleteSkillDialog = ({
                              open, onClose,data
                            }) => {

  const handleClose = () => {
    onClose();
  };

  const handleCloseConfirm = () => {
    onClose(data);
  };

  return (
      <Dialog open={open} maxWidth='xl' onClose={handleClose}>
        <CloseDialog onClick={() => handleClose()} />
        <DialogTitle>
          <Title>
            <FormattedMessage id='skill.delete.title' />
            <br/>
          </Title>
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id='skill.delete.question' />
          <FormatButton>
            <StyledButtonConfirm onClick={handleCloseConfirm} color='inherit' variant='contained'>
              <FormattedMessage id="skill.delete.confirm" />
            </StyledButtonConfirm>
            <StyledButtonCancel onClick={handleClose} color='inherit' variant='contained'>
              <FormattedMessage id="skill.delete.cancel" />
            </StyledButtonCancel>
          </FormatButton>
        </DialogContent>
      </Dialog>
  )
}
