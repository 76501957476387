import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const DialogWindow = ({
  formatError,
  sizeError,
  onDialogClose,
}) => (
  <Dialog
    open={formatError || sizeError}
    onClose={onDialogClose}
    maxWidth='xs'
    transitionDuration={0}
  >
    <DialogTitle>
      {
        formatError
          ? 'Unsupported file format'
          : 'File is to large to upload'
      }
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {
          formatError
            ? 'The file you are trying to upload has unsupported file format. Valid format: .jpg, .png,'
            : 'The file you are trying to upload is too large. Max size: 10 mb'
        }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onDialogClose} color='primary'>
        ok
      </Button>
    </DialogActions>
  </Dialog>
)

DialogWindow.propTypes = {
  formatError: PropTypes.bool.isRequired,
  sizeError: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
}
