import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const DialogTitleStyled = styled(DialogTitle)`
  && {
    border-bottom: solid 0.5px #d8d8d8;
  }
`

const DialogContentStyled = styled(DialogContent)`
  && {
    border-bottom: solid 0.5px #d8d8d8;
  }
`

export const DialogWindow = ({
  open,
  title,
  buttonText,
  closeButtonText,
  onButtonClick,
  onClose,
  children,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='xs'
    transitionDuration={0}
  >
    {title && <DialogTitleStyled>{title}</DialogTitleStyled>}
    {children && <DialogContentStyled>{children}</DialogContentStyled>}
    <DialogActions>
      <Button onClick={onClose} color='primary'>{closeButtonText}</Button>
      {buttonText && onButtonClick && <Button onClick={onButtonClick} color='primary'>{buttonText}</Button>}
    </DialogActions>
  </Dialog>
)

DialogWindow.defaultProps = {
  open: false,
  onButtonClick: () => null,
}

DialogWindow.propTypes = {
  open: PropTypes.bool,
  //title: PropTypes.string,
  //buttonText: PropTypes.string,
  closeButtonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
  //children: PropTypes.node,
}
