import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Paper from '@material-ui/core/Paper'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl'

interface GlobalNotification {
    title: string,
    message: string
}

const StyledPaper = styled(Paper)`
    flex-direction: column;
    min-width: 720px;
    margin-top: 71px;
`

const StyledContentCreateNotification = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 720px;
    padding: 24px;
`

const ButtonsBar = styled.div`
    display: flex;
    justify-content: center;
`

const CreateNotificationsHead = styled.div`
    color: #757f99;
    font-size: 16px;
    padding: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c5cbdb;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const Label = styled.div`
    color: #21293e;
    font-size: 14px;
    opacity: 0.6;
    padding-bottom: 5px;
`

const StyledTextField = styled(TextField)<typeof TextField>`
    && {
        margin-bottom: 42px;
        background-color: #f5f7fb !important;
    }

    input {
      min-height: 43px;
    }
`

const CreateButton = styled(Button)`
    && {
        margin-right: 10px;
        min-width: 235px;
    }
`

export const CreateGlobalNotification = () => {
  const history = useHistory();
  const [notification, setNotification] = useState<GlobalNotification>({ title: '', message: '' })
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { formatMessage } = useIntl()
  const f = (id: string) => formatMessage({ id })

  const changeNotification = (value: string, target: string) => {
    setSubmitted(false);
    setNotification({ ...notification, [target]: value });
  }

  const createNotificationRequest = async () => {
    try {
      setSubmitted(true);
      await axios.post('/admin/global-notification/create', { ...notification });
      history.push('/content#global-notification');
    } catch (e) {
      console.warn(e);
    }
  }

  return (
    <Container>
      <StyledPaper>
        <CreateNotificationsHead>
          <FormattedMessage id="content.notifications.create" />
        </CreateNotificationsHead>
        <StyledContentCreateNotification>
          <Label>
            <FormattedMessage id="content.notifications.title" />
          </Label>
          // @ts-ignore
          <StyledTextField
            inputProps={{ maxLength: 250 }} 
            value={notification.title} 
            onChange={(e: any) => changeNotification(e.target.value, 'title')} 
            label=""
            error={submitted && !notification.title}
            helperText={submitted && !notification.title ? f('content.notifications.required') : ''}
          />
          <Label>
            <FormattedMessage id="content.notifications.message" />
          </Label>
          // @ts-ignore
          <StyledTextField
            inputProps={{ maxLength: 6000 }} 
            value={notification.message} 
            onChange={(e: any) => changeNotification(e.target.value, 'message')} 
            label=""
            multiline
            rows={6}
            error={submitted && !notification.message}
            helperText={submitted && !notification.message ? f('content.notifications.required') : ''}
          />
          <ButtonsBar>
            <CreateButton onClick={createNotificationRequest} variant="contained" color="primary">
              <FormattedMessage id="content.notifications.create" />
            </CreateButton>
            {' '}
            <Button onClick={() => history.push('/content#global-notification')} variant="outlined">
              <FormattedMessage id="content.notifications.cancel" />
            </Button>
          </ButtonsBar>
        </StyledContentCreateNotification>
      </StyledPaper>
    </Container>
  ) 
}
