import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Create from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Paper } from '../Paper'

const PaperStyled = styled(Paper)`
  padding: ${props => (props.blank ? 0 : '24px 24px 1px 24px')};
`

const Wrapper = styled.div`
  line-height: 1.38;
  margin-bottom: 26px;

  & > p {
    opacity: 0.8;
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    opacity: 0.87;
  }
`

const Years = styled.span`
  font-weight: bold;
`

const ChangeButton = styled(IconButton)`
  && {
    padding: 2px;
    margin-right: -4px;
  }

  && svg {
    font-size: 18px;
  }
`

export const Education = ({
  education,
  addButtonPath,
}) => {
  const { formatMessage } = useIntl()
  return (
    <PaperStyled
      blank={!education.length}
      heading={formatMessage({ id: 'profile.education' })}
      addButtonPath={addButtonPath}
    >
      {education.map(({ attributes, id }, index) => (
        <Wrapper key={id}>
          <Line>
            <Years>{`${attributes.from} - ${attributes.to}`}</Years>
            {addButtonPath
            && (
              <Link to={`${addButtonPath}#${index}`}>
                <ChangeButton color='primary'>
                  <Create fontSize='inherit' />
                </ChangeButton>
              </Link>
            )}
          </Line>
          <p>
            {`${attributes.institution}, ${attributes.degree || ''}, ${attributes.fieldOfStudy || ''}`}
          </p>
        </Wrapper>
      ))}
    </PaperStyled>
  )
}

Education.defaultProps = {
  addButtonPath: '',
  education: [],
}

Education.propTypes = {
  education: PropTypes.arrayOf(PropTypes.object),
  addButtonPath: PropTypes.string,
}
