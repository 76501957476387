/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import { css } from '@emotion/react'
import { Pagination } from 'interfaces'
import get from 'lodash/get';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';

//#region Styles
const wrapperStyles = css`
  && {
    margin-top: 24px;
  }
`

const rowStyles = css`
  && {
    cursor: pointer;
  }
`
//#endregion

interface TableProps {
  headers: string[],
  name: string,
  data: any[],
  pagination?: Pagination,
  onPageChange?: (page: number) => void,
  onPerPageChange?: (perPage: number) => void,
  onRowClick?: (data: any) => void,
}

export const Table: React.FC<TableProps> = ({
  headers,
  name,
  data,
  pagination,
  onPageChange,
  onPerPageChange,
  onRowClick,
}) => {
  const handlePageChange = (event: unknown, newPage: number) => (
    onPageChange && onPageChange(newPage + 1)
  )
  const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onPerPageChange) return
    const newPerPage = parseInt(event.target.value, 10)
    onPerPageChange(newPerPage)
  }

  return (
    <Paper css={wrapperStyles}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {
                headers.map(header => (
                  <TableCell key={header}>
                    <FormattedMessage id={`tables.${name}.${header}`} />
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map(row => (
                <TableRow
                  key={row.id}
                  css={rowStyles}
                  hover={!!onRowClick}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                >
                  {
                    headers.map(header => {
                      let value = get(row, header, '-')
                      const creator = get(row, 'attributes.doctor_creator', '')
                      const fullValueCreator = (Object.values(creator))
                      const required = get(row, 'attributes.doctor_required', '')
                      const fullValueRequired = (Object.values(required))
                      const patient = get(row, 'attributes.patient', '')
                      const { formatMessage } = useIntl()
                      if (patient !== null) {
                        const fullValuePatient = (Object.values(patient))
                        if (header === 'patient') {
                          if (get(row, header) === undefined) value = fullValuePatient
                        } 
                      }
                      if (header === 'useful') {
                        if (get(row, header) === null) value = '-'
                        if (get(row, header) === false) value = <FormattedMessage id='tables.medicines.negative' />
                        if (get(row, header) === true) value = <FormattedMessage id='tables.medicines.positive' />
                      }
                      if (header === 'attributes.medical_report'
                          || header === 'attributes.prescription_report'
                          || header === 'attributes.is_virtual_patient'
                          || header === 'attributes.payment_status'
                          || header === 'attributes.with_test_member'
                      ) {
                        if (get(row, header) === null) value = '-'
                        if (get(row, header) === false) value = <CheckBoxOutlineBlankOutlinedIcon />
                        if (get(row, header) === true) value = <CheckBoxOutlinedIcon />
                      }
                      if (header === 'attributes.doctor') {
                        if (get(row, header) === undefined) value = fullValueCreator
                        if (get(row, header) === null) value = '-'
                      }
                      if (header === 'doctor.required') {
                        if (get(row, header) === undefined) value = fullValueRequired
                        if (fullValueRequired.length === 0) {
                          value = '-'
                        }
                      }
      
                      return (
                        <TableCell key={header + row.id}>
                          {value && (Array.isArray(value) ? value.join(', ') :  (typeof value === "string" ? formatMessage({ id: value}) : value ))}
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!!(pagination && pagination.total) && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component='div'
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.currentPage - 1}
          onPageChange={handlePageChange}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
        />
      )}
    </Paper>
  )
}
