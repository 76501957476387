import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {FormattedMessage} from "react-intl";
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled'
import Dropzone from 'react-dropzone'
import Crop from "./Crop";
import { Button } from 'components/common/Button'
import {getCroppedImg} from "./canvasUtils";

const Wrapper = styled.div`
  width: 100px;
  height: 100px;
  cursor: pointer;
  text-align: center;
`

const Content = styled.div`
   width: 800px;
   height: 800px;
   display: flex;
   flex-direction: column;
   padding-top: 20px;
   align-items: center;
`

const CloseModal = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Line = styled.div`
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

export const Upload = ({ isOpen, close, input, meta}) => {

    const [imageSrc, setImageSrc] = React.useState(null)

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [croppedImage, setCroppedImage] = useState(null)

    const onDrop = async (files) => {
            let imageDataUrl =URL.createObjectURL(files[0])
            setImageSrc(imageDataUrl)
    }

    useEffect(() => {
        (async function () {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                0
            )
            setCroppedImage(croppedImage)
        }());
    }, [croppedAreaPixels])

    return (
        <Dialog maxWidth='md' open={isOpen}>
            <CloseModal onClick={() => close()} />
            <DialogTitle>
                <FormattedMessage id='Ajouter un logo' />
            </DialogTitle>
            <DialogContent>
                <Content>
                    <Dropzone onDrop={onDrop}
                              accept='.jpg, .jpeg, .png, .svg'
                    >
                        {
                            ({ getRootProps, getInputProps }) => (
                                <Wrapper
                                    {...getRootProps()}
                                >
                                    <Button color='primary'>
                                        <FormattedMessage id='Télécharger un fichier' />
                                    </Button>
                                    <input {...getInputProps()} />
                                </Wrapper>
                            )
                        }
                    </Dropzone>
                    {!!imageSrc  &&
                      <>
                        <Crop
                          image={imageSrc}
                          croppedAreaPixels={croppedAreaPixels}
                          setCroppedAreaPixels={setCroppedAreaPixels}
                        />
                        <Line>
                            <Button color='primary'  onClick={() => {
                                input.onChange(croppedImage)
                                close()
                            }}>
                                <FormattedMessage id='Valider' />
                            </Button>
                            <Button color='primary' onClick={() => {
                                input.onChange(null)
                                close()
                            }}>
                              <FormattedMessage id='Annuler' />
                            </Button>
                        </Line>
                      </>
                    }
                </Content>
            </DialogContent>
        </Dialog>
    )
}
