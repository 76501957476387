import { createAction, handleActions } from 'redux-actions'
import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { AllConsultationFilters, AllConsultationPayload } from 'interfaces/doctors'
import { normalizeConsultation } from 'helpers/doctors'
import { Pagination } from 'interfaces'
import { stringify } from 'querystring';
import { ConsultationPayload } from 'interfaces/doctors'

//#region Actions
export enum AllConsultationsActions {
  fetchAllConsultationsRequest = 'FETCH_ALL_CONSULTATIONS_REQUEST',
  fetchAllConsultationsSuccess = 'FETCH_ALL_CONSULTATIONS_SUCCESS',
  fetchAllConsultationsFailure = 'FETCH_ALL_CONSULTATIONS_FAILURE',
}

export const fetchAllConsultationsRequest = createAction(
  AllConsultationsActions.fetchAllConsultationsRequest,
)
export const fetchAllConsultationsSuccess = createAction(
  AllConsultationsActions.fetchAllConsultationsSuccess,
)
export const fetchAllConsultationsFailure = createAction(
  AllConsultationsActions.fetchAllConsultationsFailure,
)
//#endregion

// #region Reducer
const list = handleActions({
  [AllConsultationsActions.fetchAllConsultationsSuccess]: (
    state, action: AnyAction,
  ) => action.payload.data,
}, [])

export const AllConsultationsReducer = combineReducers({
  list,
})
//#endregion

//#region Thunks

export const fetchAllConsultations = (
  filters: AllConsultationFilters,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchAllConsultationsRequest())

  type Response = { data: AllConsultationPayload[], paginator: Pagination }

  const allowedFilters = ['search', 'limit', 'page', 'patient_name', 'consultation_type', 'exclude_test', 'status', 'payment_status', 'date_from', 'date_to', 'doctor_id'];

  const urlFilters = Object.keys(filters).reduce((accum, el: keyof AllConsultationFilters) => {
    if (allowedFilters.includes(el) && filters[el] !== 'null') {
      return { ...accum, [el]: filters[el] }
    } 
    return accum;
  }, {})

  return axios.request<Response>({
    url: `/admin/consultation/search?${stringify(urlFilters)}`,
    params: {
      include: 'additionalMember,doctor,patient',
    },
  })
    .then(response => {
      dispatch(fetchAllConsultationsSuccess({
        data: response.data.data.map(item => normalizeConsultation(item)),
        pagination: response.data.paginator,
      }))
    })
    .catch((err) => {
      console.warn(err)
      dispatch(fetchAllConsultationsFailure(err))
    })
}
//#endregion
