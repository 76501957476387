import React from 'react'

import { AuthLayout } from 'components/pages/Auth/Layout'
import { LoginForm } from 'components/pages/Auth/LoginForm'

export const Auth: React.FC = () => (
  <AuthLayout>
    <LoginForm />
  </AuthLayout>
)
