import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {Link, useHistory, useParams} from 'react-router-dom'
import { Content } from 'components/common/Content'
import { Button } from 'components/common/Button'
import {setActiveMenu, setIsPrincipalPage, setSubMenu} from "../../../../ducks/modules/menu";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../constants/colors";
import { createTheme } from '@material-ui/core/styles';
import {StructureMembers, StructureMembersFilters} from "../../../../interfaces/structuresmembers";
import {Pagination, State} from "../../../../interfaces";
import {
    fetchStructuresMembers,
    setStructuresMembersFilters
} from "../../../../ducks/modules/structuresMembers";
import {Search} from "../../../common/Search";
import {FormattedMessage} from "react-intl";
import {
    SKILLS_TABLE_HEADERS,
    STRUCTURES_MEMBERS_TABLE_HEADERS,
} from "../../../../constants/tables";
import {Structure, StructureFilters} from "../../../../interfaces/structures";
import {
    addStructureMember,
    deleteStructureMember,
    getListStructureMembers, importStructureMember,
    normalizeStructureMembers, sendMailImport, sendMailImportDoctor
} from "../../../../helpers/structureMembers";
import {Table} from "../../../common/Table";

import {Alert, Snackbar} from "@mui/material";
import {DeleteStructureMemberDialog} from "../Members/DeleteStructureMemberDialog";
import { AddStructureMemberDialog } from '../Members/AddStructureMemberDialog'
import IconButton from "@material-ui/core/IconButton";
import {Email} from "@material-ui/icons";
import {ConfirmMailDialog} from "../Members/ConfirmMailDIalog";

interface Params {
    id: number
}

//#region Styles
const HeaderStructures = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledLink = styled(Link)`
    font-weight: 500;
    color: #209dff;
`
const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
`

const StyledName = styled.div`
  word-break: break-all;
`
const StyledButtonDelete = styled(Button)`
  background-color: ${Colors.redDelete};
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.watermelon};
  }
`
//#endregion

export const StructuresMembersTab = ({structure}) => {
    const dispatch = useDispatch()
    const [filters, setFilter] = useState<StructureMembersFilters>({ search: '', limit: 10, page: 1, imported:true})
    const [structureMembers, setStructureMembers] = useState<StructureMembers[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1, total: 0, limit: 10, totalPage: 0,
    });
    const [openDelete, setOpenDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const [openMailSend, setOpenMailSend] = useState(false);
    const [idToMailSend, setIdToMailSend] = useState('');
    const [openAdd, setOpenAdd] = useState(false);
    const [idToAdd, setIdToAdd] = useState('')
    // const [structureMembers, setStructureMembers] = useState<StructureMembers[]>([]);
    const history = useHistory();
    const structureName = structure.name;
    const params = useParams<Params>();
    const [openSnackBar, openSnackBarSet] = useState(false);
    const [openSnackBarError, openSnackBarErrorSet] = useState(false);
    const [openSnackBarImport, openSnackBarImportSet] = useState(false);
    const [openSnackBarImportError, openSnackBarImportErrorSet] = useState(false);
    const [openSnackBarImportWarning, openSnackBarImportWarningSet] = useState(false);
    const [fetchingImport, fetchingImportSet] = useState(false);
    const [fetchingMail, fetchingMailSet] = useState(false);
    const [errorMessage, setErrorMessage] = useState([])

    useEffect(() => {
    }, [structureMembers])

    useEffect(() => {
        getListStructureMembers(filters,structure.id)
            .then(resp => {
                setStructureMembers(resp.data.data.map(member => normalizeStructureMembers(member)))
                setPagination(resp.data.paginator)
            })
            .catch(err => console.warn(err))
    }, [filters])

    const onSearch = (search: string) => dispatch(setFilter({ ...filters, search }))
    const onPageChange = (page: number) => {
        setFilter({ ...filters, page })
        setPagination({ ...pagination, currentPage: page })
    }
    const onPerPageChange = (limit: number) => setFilter({ ...filters, limit, page: 1 })

    const changeFilter = (filterValue: StructureFilters) => {
        if (filterValue.page) {
            setFilter({ ...filters, ...filterValue });
        } else {
            setFilter({ ...filters, ...filterValue, page: 1 });
        }
    }

    const handleClickDelete = (value:string) => {
        setIdToDelete(value);
        setOpenDelete(true)
    };

    const handleClickMailMember = (value:string) => {
        setIdToMailSend(value);
        setOpenMailSend(true)
    };

    const handleCloseDelete = (value:number| null) => {
        setOpenDelete(false);
        if(value){
            deleteMember(value.toString())
        }
    }

    const handleCloseMailMember = (value:number| null) => {
        setOpenMailSend(false);
        if(value){
            sendMailToMember(value);
        }
    }

    const handleClickAdd = () => {
        setOpenAdd(true)
    };

    const handleCloseAdd = (value:string| null) => {
        if(value){
            addStructureMember(structure.id,value)
                .then(() =>
                    getListStructureMembers(filters,structure.id)
                        .then(resp => {
                            setStructureMembers(resp.data.data.map(member => normalizeStructureMembers(member)))
                            setPagination(resp.data.paginator)})
                        .catch(err => console.warn(err))
                )
        }
        else{
            setOpenAdd(false);
        }
    }

    const deleteMember = (doctorId: string) => {
        deleteStructureMember(structure.id,doctorId)
            .then(() =>
                getListStructureMembers(filters,structure.id)
                    .then(resp => {
                        setStructureMembers(resp.data.data.map(member => normalizeStructureMembers(member)))
                        setPagination(resp.data.paginator)})
                    .catch(err => console.warn(err))
            )
            .catch(err => console.warn(err))
    }

    const sendMailToMember = (doctorId: number) => {
        sendMailImportDoctor(params.id, doctorId)
            .then(resp => {
                openSnackBarSet(true)
            })
            .catch(err => {
                    openSnackBarErrorSet(true)
                }
            )
    }

    const handleChange = (event:any) => {
        const fileUploaded = event.target.files[0];
        event.target.value = "";
        fetchingImportSet(true)
        importStructureMember(fileUploaded,structure.id.toString())
            .then(resp => {
                fetchingImportSet(false)
                openSnackBarImportSet(true)
            })
            .catch(err => {
                if(err.response.status == 400){
                    setErrorMessage(err.response.data.errorMessage)
                    fetchingImportSet(false);
                    openSnackBarImportWarningSet(true)
                }
                else{
                    fetchingImportSet(false)
                    openSnackBarImportErrorSet(true)
                }

            })
            .finally(()=> {
                getListStructureMembers(filters,structure.id)
                    .then(resp => {
                        setStructureMembers(resp.data.data.map(member => normalizeStructureMembers(member)))
                        setPagination(resp.data.paginator)})
                    .catch(err => console.warn(err))
            })
    };

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const downloadFile = (event:any) => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const sendMail = () =>  {
        fetchingMailSet(true)
        sendMailImport(structure.id)
            .then(resp => {
                fetchingMailSet(false)
                openSnackBarSet(true)
            })
            .catch(err => {
                    fetchingMailSet(false)
                    openSnackBarErrorSet(true)
                }
            )
    }

    return (
        <Content>
            <Snackbar open={openSnackBar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarSet(false)}>
                <Alert  severity="success" sx={{ width: '100%' }} onClose={() => openSnackBarSet(false)}>
                    <FormattedMessage id="structuremembers.notification.mail"/>
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarErrorSet(false)}>
                <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarErrorSet(false)}>
                    <FormattedMessage id="structuremembers.notification.error"/>
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarImport} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarImportSet(false)}>
                <Alert  severity="success" sx={{ width: '100%' }} onClose={() => openSnackBarImportSet(false)}>
                    <FormattedMessage id="structuremembers.notification.import"/>
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarImportError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarImportErrorSet(false)}>
                <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarImportErrorSet(false)}>
                    <FormattedMessage id="structuremembers.notification.import.error"/>
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackBarImportWarning} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarImportWarningSet(false)}>
                <Alert  severity="warning" sx={{ width: '100%' }} onClose={() => openSnackBarImportWarningSet(false)}>
                    <FormattedMessage id="structuremembers.import.warning" />
                    {
                        errorMessage.map(el => {
                            return (
                                <div>{el}</div>)
                        })
                    }
                </Alert>
            </Snackbar>
            <HeaderStructures>
                <Search
                    onSearch={onSearch}
                    label='structuremembers.search.placeholder'
                    input={filters.search}
                />
                <StyledButton color="primary" type="submit" onClick={downloadFile} isFetching={fetchingImport}>
                    <FormattedMessage id="structuremembers.import" />
                </StyledButton>
                <StyledButton color="primary" type="submit" onClick={sendMail} isFetching={fetchingMail}>
                    <FormattedMessage id="structuremembers.mail" />
                </StyledButton>
                <StyledButton color="primary" type="submit" onClick={() => (handleClickAdd())}>
                    <FormattedMessage id="structuremembers.create" />
                </StyledButton>
            </HeaderStructures>
            <Table
                headers={STRUCTURES_MEMBERS_TABLE_HEADERS}
                name='structuremembers'
                data={structureMembers
                    .map(el => ({
                        ...el,
                        send_email: (
                            <div>
                                <IconButton color='inherit' onClick={() => handleClickMailMember(el.id)}>
                                    <Email />
                                </IconButton>
                            </div>
                        ),
                        delete: (
                            <div>
                                <StyledButtonDelete onClick={() => (handleClickDelete(el.id))} color='inherit' variant='contained'>
                                    <FormattedMessage id="global.delete" />
                                </StyledButtonDelete>
                            </div>
                        ),
                    }))}
                pagination={pagination}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            />
            <div>
                <DeleteStructureMemberDialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    data={idToDelete}
                />
            </div>
            <div>
                <AddStructureMemberDialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    data={structure.id}
                />
            </div>
            <div>
                <ConfirmMailDialog
                    open={openMailSend}
                    onClose={handleCloseMailMember}
                    data={idToMailSend}
                />
            </div>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display:'none'}}
            />
        </Content>
    )
}
