import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import { withProps } from 'recompose'
import moment from 'moment'
import Cake from '@material-ui/icons/Cake'
import LanguageIcon from '@material-ui/icons/Language'
import ContactsIcon from '@material-ui/icons/Contacts'
import Wc from '@material-ui/icons/Wc'
import StarIcon from '@material-ui/icons/Star'
import PhoneIcon from '@material-ui/icons/Phone'
import { FormattedMessage } from 'react-intl'
import { Paper } from '../Paper'
import { Colors } from '../../../../constants/colors.ts'
import avatar from '../../../../assets/images/default-avatar.svg'
import { TextButton } from '../TextButton'
import { LANGUAGES } from '../../../../constants/languages'
import { Rating } from '../DoctorCard'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import {DefaultAvatar} from "../../../../helpers/defaultAvatar";
import prosante from 'assets/images/prosante.png';
import Switch from '@mui/material/Switch';
import {useDispatch} from "react-redux";
import {EditStatusDoctorAvailability} from "../../../../ducks/modules/doctors";

const PaperStyled = styled(Paper)`
  ${props => (props.small ? 'padding: 18px 16px' : 'padding-top: 24px')}
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  ${props => props.small && 'font-size: 12px'}
`

const Avatar = styled.div`
  position: relative;
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: url(${props => props.photo || avatar}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);

  ${props => props.small && `
    min-width: 88px;
    width: 88px;
    height: 88px;
    border: 5px solid white;
  `}
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px ${props => (props.small ? '16px' : '32px')};
`

const Available = styled.div`
  color: ${Colors.greyNotChecked};
  display: flex;
  align-items: baseline;
`

const OtherInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const OtherInfoElement = styled.div`
  display: flex;
  justify-content: end;
`

const TextStyled = styled.div`
  font-size: 0.9em;
  ${({checked}) => (checked && css`
      color: ${Colors.greenAvailable};
    `)}
  ${({NotCheckedRed}) => (NotCheckedRed && css`
      color: ${Colors.redUnavailable};
    `)}
`

const SwitchStyled = styled(Switch)`

`
const Name = styled.h1`
  margin: 0;
  font-size: ${props => (props.small ? '20px' : '24px')};
  font-weight: bold;
`

const Email = styled.p`
  margin: 0;
  // opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.dark};
  display: flex;
  align-items: center;
  margin-top: 12px;

  && svg {
    font-size: 18px;
    margin-right: ${props => (props.small ? '8px' : '12px')};
  }
`

const Button = styled(TextButton)`
  && {
    height: auto;
    margin-top: 18px;
    padding: 9px 58px;
    font-size: 14px;
    font-weight: 500;
  }
`

const Language = styled.div`
  font-size: 16px;
  color: ${Colors.blueGrey};
  text-transform: capitalize;
`

const Image = styled.img`
  width: 30%;
  height: 100%;
`

const UserInfoComponent = ({
  small,
  heading,
  changeButtonPath,
  email,
  firstName,
  lastName,
  specializationType,
  photo,
  gender,
  children,
  onAppointmentButtonClick,
  language,
  rating,
  phoneNumber,
  medicalInsurance,
  validator,
  status_availability,
  doctorId,
  setUserWantingToModifyPersonalData,
  isPscRegistrated,
  lastConnection,
  importer
}) => {

  const [checked, setChecked] = useState(status_availability);
  const dispatch = useDispatch();

  const changeAvailability = () => {
    dispatch(EditStatusDoctorAvailability(!checked, doctorId))
  }

  return (
    <PaperStyled
      small={small}
      heading={heading}
      changeButtonPath={changeButtonPath}
      isPersonalData={true}
      setUserWantingToModifyPersonalData={setUserWantingToModifyPersonalData}
    >
      <Wrapper small={small}>
        { photo ?
          <Avatar
            small={small}
            photo={ photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo }
          >
            {
              !!rating && (
                <Rating>
                  {rating}
                  <StarIcon />
                </Rating>
              )
            }
          </Avatar> :
          <Avatar
            small={small}
            photo={ DefaultAvatar(firstName, lastName, specializationType) }
          >
            {
              !!rating && (
                <Rating>
                  {rating}
                  <StarIcon />
                </Rating>
              )
            }
          </Avatar>
        }
        <Info small={small}>
          <Name small={small}>
            {`${firstName} ${lastName}`}
          </Name>
          {email && !small &&
            <Over small={small}>
              <AlternateEmailIcon />
              {email}
            </Over>
          }
          {
            !!phoneNumber && (
              <Over small={small}>
                <PhoneIcon />
                {phoneNumber}
              </Over>
            )
          }
          { lastConnection &&
              <Over small={small}>
                <FormattedMessage id='profile.last.connection'/> : {moment(moment.utc(lastConnection).toDate()).format('DD/MM/YYYY HH:mm')}
              </Over>
          }
          { validator &&
            <Over small={small}>
              <FormattedMessage id='profile.validator' /> : {validator.name} {validator.surname} ({validator.id})
            </Over>
          }
          { importer &&
            <Over small={small}>
              <FormattedMessage id='profile.importer' /> : {importer.name} {importer.surname} ({importer.id})
            </Over>
          }
          {medicalInsurance && (
            <Over small={small}>
              <ContactsIcon />
              <FormattedMessage id='profile.insurance' />
              {': '}
              {medicalInsurance}
            </Over>
          )}
          {/*        <Over small={small}>
          <Wc />
          <FormattedMessage id={gender === 'unknown' ? 'hr.unknown' : `profile.${gender}`} />
        </Over>*/}
          {
            !!language && (
              <Over small={small}>
                <LanguageIcon />
                <Language>
                  {
                    Array.isArray(language)
                      ? language.sort().map(lang => (LANGUAGES.find(item => item.value === lang) || {}).label).join(', ')
                      : null
                  }
                </Language>
              </Over>
            )
          }
          {
            onAppointmentButtonClick
              ? (
                <Button
                  onClick={onAppointmentButtonClick}
                  color='primary'
                  variant='extended'
                >

                  <FormattedMessage id='consultation.make.appointment' />
                </Button>
              )
              : null
          }
        </Info>
        <OtherInfo>
          <OtherInfoElement>
            <Available>
              {
                <TextStyled NotCheckedRed={!checked}>
                  <FormattedMessage id='global.doctor.non.disponible' />
                </TextStyled>
              }
              <SwitchStyled
                name='status_availability'
                color='success'
                checked={checked}
                onClick={changeAvailability}
                onChange={event => setChecked(event.target.checked)}
              />
              {
                <TextStyled checked={checked}>
                  <FormattedMessage id='global.doctor.disponible' />
                </TextStyled>
              }
            </Available>

          </OtherInfoElement>
          <OtherInfoElement>
            {
              isPscRegistrated && (
                <Image
                  alt='img'
                  src={prosante}/>
              )
            }
          </OtherInfoElement>
        </OtherInfo>

      </Wrapper>
      {children}
    </PaperStyled>
  )
}

UserInfoComponent.defaultProps = {
  small: false,
  email: '',
  heading: '',
  changeButtonPath: '',
  onAppointmentButtonClick: false,
  photo: '',
  //language: null,
}

UserInfoComponent.propTypes = {
  small: PropTypes.bool,
  email: PropTypes.string,
  dateOfBirth: PropTypes.any.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photo: PropTypes.string,
  gender: PropTypes.string.isRequired,
  //children: PropTypes.node,
  heading: PropTypes.string,
  changeButtonPath: PropTypes.string,
  onAppointmentButtonClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  //language: PropTypes.array,
  isPscRegistrated: PropTypes.bool,
  rating: PropTypes.any,
  validator: PropTypes.any,
  status_availability: PropTypes.bool.isRequired
}
const enhance = withProps(props => ({
  firstName: props.firstName,
  lastName: props.lastName,
  gender: props.gender === 'woman' ? 'female' : props.gender,
  dateOfBirth: props.dateOfBirth
    ? (
      <>
        {moment(props.dateOfBirth).format('D MMMM YYYY')} 
        {' '}
        ({moment().diff(moment(props.dateOfBirth), 'years')}
        {' '}
        <FormattedMessage id='profile.yearsold' />)
      </>
    )
    : <FormattedMessage id='profile.birth.date' />,
}))

export const UserInfo = enhance(UserInfoComponent)
