/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStatistics } from 'ducks/modules/statistics'
import { GeneralStatistics } from 'interfaces/dashboard'
import { State } from 'interfaces'
import { css } from '@emotion/react'
import { General } from 'components/pages/Dashboard/General'
import { DashboardChart } from 'components/pages/Dashboard/Chart'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";

//#region Styles
const wrapperStyles = css`
  padding: 24px;
  max-width: 1440px;
  min-width: 960px;
  margin: 0 auto;
  overflow-x: auto;
  box-sizing: border-box;
`
//#endregion

interface DashboardProps {
  doctorId?: number | string,
}

export const Dashboard: React.FC<DashboardProps> = ({ doctorId }) => {
  const dispatch = useDispatch()
  const general = useSelector<State, GeneralStatistics>(state => state.statistics.general)

  useEffect(() => {
    dispatch(setActiveMenu('global.dashboard'))
    dispatch(setIsPrincipalPage(true))
  }, )

  useEffect(() => {
    dispatch(fetchStatistics())
  }, [dispatch])

  return (
    <div css={wrapperStyles}>
      {!doctorId && <General data={general} />}
      <DashboardChart doctorId={doctorId} />
    </div>
  )
}
