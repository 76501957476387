import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSms } from 'ducks/modules/smslogs';
import { State } from 'interfaces'
import { Smslogs } from 'interfaces/smslogs'
import styled from '@emotion/styled'
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Search } from 'components/common/Search'
import { Link } from 'react-router-dom';
import { Pagination } from 'components/common/Pagination';
import { FormattedMessage, useIntl } from 'react-intl'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";

const Wrapper = styled.div`
    max-width: 975px;
    min-width: 975px;
    margin: 24px auto;
`
const StyledContainer = styled(Paper)`
    display: flex;
    align-items: center;
    padding: 27px 23px;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.2) !important;
    margin-bottom: 8px;
`

const MessageDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 29px;
    padding-right: 53px;
    width: 200px;
`

const MobileScreenShareIconStyled = styled(MobileScreenShareIcon)`
    && {
        font-size: 29px;
        color: #21c5a9;
        display: flex;
    }
`

const PermDeviceInformationIconStyled = styled(PermDeviceInformationIcon)`
    && {
        font-size: 29px;
        color: #f5a623;
        display: flex;
    }
`

const MessageText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #21293e;
    margin-bottom: 6px
`

const DateSms = styled.div`
    color: #959fb8;
    font-size: 12px;
    font-weight: 600;
`

const BlockTable = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 32px;
`

const TitleContiner = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #959fb8;
    margin-bottom: 8px;
`

const DirectPhone = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #21293e;
    display: flex;
`

const BoldTitleContent = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #21293e;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
    && {
        color: #959fb8;
        display: flex;
    }
`

const StyledSearch = styled.div`
    margin-bottom: 16px;
`

const ViewAllPhone = styled(Link)`
    color: #209dff;
    margin-left: 10px;
    cursor: pointer;
`

export const getSmsStats = (recipients: any) => {
  const smsStatuses = Object.keys(recipients).map(el => recipients[el])

  return {
    sent: smsStatuses.length,
    delivered: smsStatuses.length,
    notDelivered: smsStatuses.length,
  }
}

export const SmsLogsList = () => {
  const { formatMessage } = useIntl()
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const smslogs = useSelector<State, Smslogs>(state => state.smslogs)
  const smslogsData = smslogs.data;
  const { paginator } = smslogs;
  const filteredSmsLogsData = smslogsData
    .filter(el => el.attributes.text.indexOf(search) !== -1);

  useEffect(() => {
    dispatch(fetchSms(1));
    dispatch(setActiveMenu('global.menu.smslogs'))
    dispatch(setIsPrincipalPage(true))
  }, [])

  return (
    <Wrapper>
      <StyledSearch>
        <Search label={formatMessage({ id: 'sms.message' })} onSearch={(val) => setSearch(val)} />
      </StyledSearch>
      {filteredSmsLogsData.map(el => {
        const smsStats = getSmsStats(el.attributes.recipients);
        const { status } = el.attributes;
        return (
          <StyledContainer key={el.id}>
            {status === 'sent' 
              ? <MobileScreenShareIconStyled />
              : <PermDeviceInformationIconStyled />}
            <MessageDateContainer>
              <MessageText>
                {el.attributes.text}
              </MessageText>
              <DateSms>
                {moment(el.attributes.created.date).format('MMMM Do YYYY, h:mm:ss a')}
              </DateSms>
            </MessageDateContainer>
            <BlockTable>
              <TitleContiner>
                <FormattedMessage id="sms.telnumber" />
              </TitleContiner>
              <DirectPhone>
                {el.attributes.recipients[0]}
                <ViewAllPhone to={`/smslogs/${el.id}`}>
                  <FormattedMessage id="sms.viewall" />
                </ViewAllPhone>
              </DirectPhone>
            </BlockTable>
            <BlockTable>
              <TitleContiner>
                <FormattedMessage id="sms.smssent" />
              </TitleContiner>
              <BoldTitleContent>
                {status === 'sent' ? smsStats.sent : 0}
              </BoldTitleContent>
            </BlockTable>
            <BlockTable>
              <TitleContiner>
                <FormattedMessage id="sms.delivered" />
              </TitleContiner>
              <BoldTitleContent>
                {status === 'sent' ? smsStats.delivered : 0}
              </BoldTitleContent>
            </BlockTable>
            <BlockTable>
              <TitleContiner>
                <FormattedMessage id="sms.notdelivered" />
              </TitleContiner>
              <BoldTitleContent>
                {status !== 'sent' ? smsStats.notDelivered : 0}
              </BoldTitleContent>
            </BlockTable>
            <Link to={`/smslogs/${el.id}`}>
              <StyledChevronRightIcon />
            </Link>
          </StyledContainer>
        ) 
      })}
      <Pagination 
        count={paginator.total} 
        onChange={(el: number) => {
          setPage(el);
          dispatch(fetchSms(el))
        }} 
        currentPage={page} 
        limit={paginator.limit} 
        pagesToView={3}
      />
    </Wrapper>
  );
}
