/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, {useState} from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import { Pagination } from 'interfaces'
import get from 'lodash/get';
import styled from "@emotion/styled";
import {Button} from "../../common/Button";
import {Colors} from "../../../constants/colors";
import {useHistory} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

//#region Styles
const wrapperStyles = css`
  && {
    margin-top: 24px;
  }
`

const rowStyles = css`
  && {
    cursor: pointer;
  }
`
const StyledButtonDelete = styled(Button)`
  background-color: ${Colors.redDelete};
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.watermelon};
  }
`
const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
  margin-right: 5%;
`
//#endregion

export const SpecializationTable = ({
                                 headers,
                                 data,
                                 pagination,
                                 onPageChange,
                                 onPerPageChange,
                                 sortBy,
                                 sortOrder,
                                 requestSort,
                                 handleClickDelete,
                             }) => {
    const history = useHistory();

    const handlePageChange = (event: unknown, newPage: number) => (
        onPageChange && onPageChange(newPage + 1)
    )
    const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onPerPageChange) return
        const newPerPage = parseInt(event.target.value, 10)
        onPerPageChange(newPerPage)
    }

    return (
        <Paper css={wrapperStyles}>
            <TableContainer>
                <MuiTable stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key={'id'}>
                                    <FormattedMessage id={`tables.specializations.id`} />
                            </TableCell>

                            <TableCell key={'name'}>
                                    <FormattedMessage id={`tables.specializations.attributes.name`} />
                            </TableCell>

                            <TableCell key={'key'}>
                                <TableSortLabel active={sortBy === "key"} onClick={() => requestSort("key")} direction={sortOrder.toLowerCase()} hideSortIcon>
                                    <FormattedMessage id={`tables.specializations.attributes.key`} />
                                </TableSortLabel>
                            </TableCell>
                            <TableCell key={'view'}>
                                <FormattedMessage id={`tables.specializations.view`} />
                            </TableCell>
                            <TableCell key={'delete'}>
                                <FormattedMessage id={`tables.specializations.delete`} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(row => (
                                <TableRow
                                    key={row.id}
                                    css={rowStyles}
                                >
                                    {
                                        headers.map(header => {
                                            let value = get(row, header,)
                                                return (
                                                    <TableCell key={header + row.id}>
                                                        {Array.isArray(value) ? value.join(', ') : value}
                                                        {typeof header === "string" && header === "view" ?
                                                                <StyledButton onClick={() =>  history.push(`/specializations/${row.id}`)} variant='contained' startIcon={<EditIcon />}>
                                                                    <FormattedMessage id="specialization.modify" />
                                                                </StyledButton> : ""}
                                                        {typeof header === "string" && header === "delete" ?
                                                            <StyledButtonDelete onClick={() => handleClickDelete(row.id)} variant='contained'>
                                                                <FormattedMessage id="specialization.delete" />
                                                            </StyledButtonDelete> : ""}
                                                    </TableCell>
                                                )
                                        })
                                    }


                                </TableRow>
                            ))
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
            {!!(pagination && pagination.total) && (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component='div'
                    count={pagination.total}
                    rowsPerPage={pagination.limit}
                    page={pagination.currentPage - 1}
                    onPageChange={handlePageChange}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageChange}
                />
            )}
        </Paper>
    )
}
