import { createAction, handleActions } from 'redux-actions'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'

//#region Actions
export enum SmsLogsActions {
  fetchSmsLogsRequest = 'SMS_LOGS_REQUEST',
  fetchSmsLogsSuccess = 'SMS_LOGS_SUCCESS',
  fetchSmsLogsFailure = 'SMS_LOGS_FAILURE',
}

export const fetchSmsLogsRequest = createAction(SmsLogsActions.fetchSmsLogsRequest)
export const fetchSmsLogsSuccess = createAction(SmsLogsActions.fetchSmsLogsSuccess)
export const fetchSmsLogsFailure = createAction(SmsLogsActions.fetchSmsLogsFailure)
//#endregion

//#region Reducer
export const smsLogsReducer = handleActions({
  [SmsLogsActions.fetchSmsLogsSuccess]: (state, action) => action.payload,
}, {
  server_time: '',
  data: [],
  paginator: {},
})
//#endregion

//#region Thunks
export const fetchSms = (page: number) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchSmsLogsRequest())
  try {
    const { data } = await axios(`/api/sms/list?page=${page}`)
    dispatch(fetchSmsLogsSuccess(data))
  } catch (err) {
    dispatch(fetchSmsLogsFailure(err))
  }

  return undefined
}
//#endregion
