/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'
import get from 'lodash/get';
import styled from "@emotion/styled";
import {Button} from "../../common/Button";
import {Colors} from "../../../constants/colors";
import {useHistory} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

//#region Styles
const wrapperStyles = css`
  && {
    margin-top: 24px;
  }
`

const rowStyles = css`
  && {
    cursor: pointer;
  }
`

const StyledButton = styled(Button)`
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  border-radius: 50px;
  margin-right: 5%;
`
//#endregion

export const VersionsTable = ({
                                        headers,
                                        data,
                                    }) => {

    const history = useHistory();

    return (
        <Paper css={wrapperStyles}>
            <TableContainer>
                <MuiTable stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key={'software'}>
                                <FormattedMessage id={`tables.versions.software`} />
                            </TableCell>
                            <TableCell key={'min_version'}>
                                    <FormattedMessage id={`tables.versions.min_version`} />
                            </TableCell>
                            <TableCell key={'view'}>
                                <FormattedMessage id={`tables.versions.modify`} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(row => (
                                <TableRow
                                    key={row.id}
                                    css={rowStyles}
                                >
                                    {
                                        headers.map(header => {
                                            let value = get(row, header,)
                                            return (
                                                <TableCell key={header + row.id}>
                                                    {header === "view" ?
                                                        <StyledButton onClick={() =>  history.push(`/mobile_version/${row.software}`)} variant='contained' startIcon={<EditIcon />}>
                                                            <FormattedMessage id="tables.versions.modify.button" />
                                                        </StyledButton> : value ?? '-'}
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </Paper>
    )
}
