import { createAction, handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { Review, ReviewPayload } from 'interfaces/doctors'
import { normalizeReview } from 'helpers/doctors'

//#region Actions
export enum ReviewsActions {
  fetchReviewsRequest = 'FETCH_REVIEWS_REQUEST',
  fetchReviewsSuccess = 'FETCH_REVIEWS_SUCCESS',
  fetchReviewsFailure = 'FETCH_REVIEWS_FAILURE',

  replyRequest = 'REPLY_REQUEST',
  replySuccess = 'REPLY_SUCCESS',
  replyFailure = 'REPLY_FAILURE',
}

export const fetchReviewsRequest = createAction(ReviewsActions.fetchReviewsRequest)
export const fetchReviewsSuccess = createAction(ReviewsActions.fetchReviewsSuccess)
export const fetchReviewsFailure = createAction(ReviewsActions.fetchReviewsFailure)

export const replyRequest = createAction(ReviewsActions.replyRequest)
export const replySuccess = createAction(ReviewsActions.replySuccess)
export const replyFailure = createAction(ReviewsActions.replyFailure)
//#endregion

//#region Reducer
export const reviewsReducer = handleActions({
  [ReviewsActions.fetchReviewsSuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.id]: action.payload.reviews,
  }),
  [ReviewsActions.replySuccess]: (state, action: AnyAction) => ({
    ...state,
    [action.payload.doctorId]: state[action.payload.doctorId].map(item => {
      if (item.id === action.payload.reviewId) {
        return { ...item, adminAnswer: action.payload.answer }
      }

      return item
    }),
  }),
}, {} as { [key: number]: Review[] })
//#endregion

//#region Thunks

export const fetchReviews = (
  id: number | string,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchReviewsRequest())

  type Response = { data: ReviewPayload[] }

  return axios.request<Response>({ url: `/general/doctor/review/${id}` })
    .then(response => {
      dispatch(fetchReviewsSuccess({
        id,
        reviews: response.data.data.map(review => normalizeReview(review)),
      }))
    })
    .catch((err) => {
      dispatch(fetchReviewsFailure(err))
    })
}

export const reply = (
  reviewId: number | string,
  answer: string,
  doctorId: number | string,
) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(replyRequest())

  type Response = { data: ReviewPayload[] }

  return axios.request<Response>({
    url: `/admin/doctor/review/answer/${reviewId}`,
    data: {
      answer,
    },
    method: 'POST',
  })
    .then(() => {
      dispatch(replySuccess({ doctorId, answer, reviewId }))
    })
    .catch((err) => {
      dispatch(replyFailure(err))
    })
}
//#endregion
