import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import styled from '@emotion/styled'
import { stringify } from 'querystring';
import pickBy from 'lodash/pickBy';
import { TextField } from 'components/common/TextField'
import { useIntl } from 'react-intl'
import { Button } from 'components/common/Button'
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import {Colors} from "../../../constants/colors";
import { FormattedMessage } from 'react-intl'
import {setActiveMenu, setIsPrincipalPage} from "../../../ducks/modules/menu";
import {useDispatch} from "react-redux";




interface Params {
    id?: string | undefined
}

interface Specialization {
    id?: string,
    name?: string,
    priority?: string,
    symptom?: string[],
}

interface SpecializationTypes {
    label: string,
    value: string
}

const StyledSelect = styled(Select)`
    width: 100%;
`

const StyledInputLabel = styled(InputLabel)` 
    background-color: #f1f3f7;
`

const Error = styled.div`
    color: red;
    padding-bottom: 15px;
`

const Wrapper = styled.div`
    max-width: 720px;
    min-width: 720px;
    margin: 24px auto;
`
const ButtonCreate = styled(Button)`
  background-color: ${Colors.green};
  margin-top: 5%;
  margin-right:0%
  color: ${Colors.white};
  border-radius: 50px;
  &:hover:not(.active) {
    background-color: ${Colors.green};
  }
`
const DivButton = styled.div`
  display: flex;
  justify-content: right;
`

const onSubmit = async (values: any, setRequestError: (a: boolean) => void,setKeyAlreadyExist: (a: boolean) => void, cb: () => void) => {
  try {
    const conditional = values;
    conditional.enName = values.key;
    if (!values.deName) {
      conditional.deName = values.enName;
    }
    if (!values.ruName) {
      conditional.ruName = values.enName;
    }
    const result = await axios.post('/admin/doctor/specialization', stringify(pickBy(conditional)));
    cb();
    return result.data.data;
  } catch (e) {
      if(e.response.data.data.trace[0].message == "specialization.key.exist"){
          setKeyAlreadyExist(true)
      }
      else{
          setRequestError(true);
      }
      return [];
  }
}

const SpecializationsTypesList: Array<SpecializationTypes> = [
  { label: 'Specialist', value: 'specialist' },
  { label: 'Generalist', value: 'generalist' },
  { label: 'Other medical professions', value: 'other' },
  { label: 'Pharmaceutical professions', value: 'pharmaceutical' },
  { label: 'Paramedical', value: 'paramedical' },
];

const composeValidators = (...validators: any) => (value: any) => validators
  .reduce((error: string, valid: (value: any) => void) => error || valid(value), undefined)

const required = (value: any) => (value ? undefined : 'Required')

export const SpecializationsDetails = () => {
  const history = useHistory();
  const { formatMessage } = useIntl()
  const params = useParams<Params>();
  const [specialization, setSpecialization] = useState<Specialization>({});
  const [requertError, setRequestError] = useState(false);
  const [keyAlreadyExist, setKeyAlreadyExist] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsPrincipalPage(false))
        if(history.location.pathname === '/specializations/new') {
            dispatch(setActiveMenu("global.menu.specializations.create"))
        }
        else{
            dispatch(setActiveMenu("global.menu.specializations.edit"))
        }
    }, )

  useEffect(() => {
    if (params && params.id) {
      axios.get(`/admin/doctor/specialization/${params.id}`)
        .then(result => setSpecialization(result.data.data))
        .catch(err => console.warn(err))
    }
  }, [])
  return (
    <Wrapper>
      <Form
        onSubmit={(values) => onSubmit(values, setRequestError,setKeyAlreadyExist, () => history.push('/specializations/'))}
        initialValues={{
          ...(specialization.id ? { id: specialization.id } : {}),
          key: get(specialization, 'attributes.key', ''),
          type: get(specialization, 'attributes.type', 'specialist'),
          name: get(specialization, 'attributes.name', ''),
          priority: get(specialization, 'attributes.priority', ''),
          symptom: get(specialization, 'attributes.symptom', []).join(', '),
          frName: get(specialization, 'attributes.names.fr', ''),
          enName: get(specialization, 'attributes.names.en', ''),
          deName: get(specialization, 'attributes.names.de', ''),
          ruName: get(specialization, 'attributes.names.ru', ''),
        }}
        render={({
          handleSubmit, form, submitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {specialization.id ? (
              <Field name="id">
                {({ input, meta }) => (
                  <TextField 
                    disabled
                    label={formatMessage({ id: 'specialization.id' })} 
                    input={input}
                    meta={meta} 
                    type="text"
                  />
                )}
              </Field>
            ) : ''}
            {params && params.id
              ? (
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField 
                      disabled
                      label={formatMessage({ id: 'specialization.name' })} 
                      input={input}
                      meta={meta} 
                      type="text"
                    />
                  )}
                </Field>
              ) : ''}
              <Field name="type" validate={composeValidators(required)}>
              {({ input, meta }) => (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <StyledInputLabel id="demo-simple-select-outlined-label">
                    {formatMessage({ id: 'specialization.type' })}
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...input}
                  >
                    {SpecializationsTypesList.map(el => (
                      <MenuItem id={el.label} value={String(el.value)}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </FormControl>
              )}
            </Field>
            <Field name="key" validate={composeValidators(required)}>
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'specialization.key' })} 
                  input={input}
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
              {keyAlreadyExist ? <Error><FormattedMessage id='specialization.error.key.exist' /></Error> : ''}
              {specialization.priority ? (
              <Field name="priority">
                {({ input, meta }) => (
                  <TextField 
                    label={formatMessage({ id: 'specialization.priority' })} 
                    input={input}
                    meta={meta} 
                    type="number"
                  />
                )}
              </Field>
            ) : ''}
            <Field validate={composeValidators(required)} name="frName">
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'specialization.frName' })} 
                  input={input}
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
            <Field name="deName">
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'specialization.deName' })} 
                  input={input}
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>
            <Field name="ruName">
              {({ input, meta }) => (
                <TextField 
                  label={formatMessage({ id: 'specialization.ruName' })} 
                  input={input}
                  meta={meta} 
                  type="text"
                />
              )}
            </Field>

              {requertError ? <Error><FormattedMessage id='specialization.error.general' /></Error> : ''}
              <DivButton className="buttons">
                  <ButtonCreate color="primary" type="submit" disabled={submitting}>
                      <FormattedMessage id="global.save" />
                  </ButtonCreate>
              </DivButton>

          </form>
        )}
      />
    </Wrapper>
  ) 
}
