import { createAction, handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import { SpecializationPayload } from 'interfaces/doctors'

//#region Actions
export enum SpecializationsActions {
  fetchSpecializationsRequest = 'FETCH_SPECIALIZATIONS_REQUEST',
  fetchSpecializationsSuccess = 'FETCH_SPECIALIZATIONS_SUCCESS',
  fetchSpecializationsFailure = 'FETCH_SPECIALIZATIONS_FAILURE',
}

export const fetchSpecializationsRequest = createAction(
  SpecializationsActions.fetchSpecializationsRequest,
)
export const fetchSpecializationsSuccess = createAction(
  SpecializationsActions.fetchSpecializationsSuccess,
)
export const fetchSpecializationsFailure = createAction(
  SpecializationsActions.fetchSpecializationsFailure,
)
//#endregion

//#region Reducer
export const specializationsReducer = handleActions({
  [SpecializationsActions.fetchSpecializationsSuccess]: (state, action) => action.payload,
}, [])
//#endregion

//#region Thunks

export const fetchSpecializations = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(fetchSpecializationsRequest())

  return axios('/general/specialization')
    .then(response => {
      const specializations: SpecializationPayload[] = response.data.data
      dispatch(fetchSpecializationsSuccess(specializations
        .map(item => ({ id: item.id, name: item.attributes.name, key: item.attributes.key }))))
    })
    .catch((err) => {
      dispatch(fetchSpecializationsFailure(err))
    })
}
//#endregion
